import React from 'react';
import { Form } from 'react-bootstrap';
/**
 * Selection screen for which categories an event will be made out to
 * @function SetCategory
 * @param {Object} props
 * @param {Object[]} props.ccKeys - list of all possible categories that can be chosen
 * @param {Object[]} props.categories - list of categories that are currently selected
 * @param {function} props.toggleCategory - selects and de-selects an object from the list
 * @returns {React.Component}
 */

export default function SetCategory(props) {
	return (
		<Form.Group className="mb-3">
			<Form.Label>Category</Form.Label>

			{props.ccKeys.map(cat => (
				<Form.Check
					key={cat.category.concat(' ', cat.gender)}
					name={cat.category.concat(' ', cat.gender)}
					type="checkbox"
					label={cat.category.concat(' ', cat.gender)}
					checked={props.categories
						.map(cat => cat.category.concat(' ', cat.gender))
						.includes(cat.category.concat(' ', cat.gender))}
					onChange={() =>
						props.toggleCategory(cat.category, cat.gender)
					}
				/>
			))}
		</Form.Group>
	);
}
