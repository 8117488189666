/* This is where the Redux store is configured */
import { configureStore } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
// import counterReducer from '../features/counter/counterSlice';
import { menuReducer } from '../services/menu';
import authReducer from '../services/authentication/authReducer';
import myCountryScoresReducer from '../services/mycountryscores/mycountryscoresReducer';
import poolsReducer from '../services/tournament/poolsReducer.js';
import eventsReducer from '../services/events/eventsReducer.js';
import masterStandingsReducer from '../services/standings/masterStandingsReducer.js';
import controlpanelReducer from '../services/controlpanel/controlpanelReducer.js';
import masterScheduleReducer from '../services/schedule/masterscheduleReducers.js';
import mysubscriptionsReducer from '../services/subscriptions/mysubscriptionsReducer.js';
import colorchartReducer from '../services/colorchart/colorchartReducer.js';
import managepeopleReducer from '../services/managepeople/managepeopleReducer.js';
import scorechangesReducer from '../services/scorechanges/scorechangesReducer.js';
import teamassignmentsReducer from '../services/teamassignments/teamassignmentsReducer.js';
import familyinfoReducer from '../services/familyinfo/familyinfoReducer';
import untieReducer from '../services/untie/untieReducer';
import broadcastReducer from '../services/broadcast/broadcastReducer';
import awardmedalsReducer from '../services/medals/awardmedalsReducer';
import faqReducer from '../services/faq/faqReducer.js';
import teamSchedulesReducer from '../services/teamschedules/teamSchedulesReducer.js';
import masterscheduleChangesReducer from '../services/masterschedulechanges/masterschedulechangesReducer';
import registrantChangesReducer from '../services/registrantchanges/registrantchangesReducer';
import logisticsReducer from '../services/logistics/logisticsReducer';
import backtobackReducer from '../services/schedule/backtobackReducer';

import {
	// defaultSchedule,
	allSchedules,
	// selectScheduleVersion,
	createGame,
	moveGameToClipboard,
	moveGameToTrash,
	addMoveChange,
	addSwapChange
} from '../services/colorchart/colorchartActions.js';
import redYellowCardReducer from '../services/redyellowcard/redyellowcardReducer';

export default configureStore({
	reducer: {
		menu: menuReducer,
		auth: authReducer,
		awardPoints: awardmedalsReducer,
		broadcast: broadcastReducer,
		mycountryscores: myCountryScoresReducer,
		pools: poolsReducer,
		events: eventsReducer,
		masterstandings: masterStandingsReducer,
		controlpanel: controlpanelReducer,
		faq: faqReducer,
		colorchart: undoable(colorchartReducer, {
			filter: includeAction([
				allSchedules.fulfilled.type,
				addMoveChange.type,
				createGame.type,
				moveGameToTrash.type,
				addSwapChange.type,
				moveGameToClipboard.type
			])
			// filter: excludeAction([
			// 	allSchedules.pending.type,
			// 	allSchedules.rejected.type,
			// 	selectScheduleVersion.type,
			// 	defaultSchedule.pending.type,
			// 	defaultSchedule.rejected.type,
			// 	defaultSchedule.fulfilled.type,
			// 	createGameSetup.type
			// ])
		}),
		masterschedule: masterScheduleReducer,
		subscriptions: mysubscriptionsReducer,
		managepeople: managepeopleReducer,
		scorechanges: scorechangesReducer,
		teamassignments: teamassignmentsReducer,
		familyinfo: familyinfoReducer,
		backtoback: backtobackReducer,
		teamschedules: teamSchedulesReducer,
		redyellow: redYellowCardReducer,
		masterschedulechanges: masterscheduleChangesReducer,
		registrantchanges: registrantChangesReducer,
		untie: untieReducer,
		logistics: logisticsReducer
	}
});
