import React from 'react';
import ScoreCard from './ScoreCard';

export default function PoolGames(props) {
	const divStyle = {
		display: 'flex',
		flexDirection: 'column',
		// maxHeight: '74vh',
		// overflowY: 'scroll',
		margin: '0 1vw',
		padding: '0 1vw'
	};

	const headingStyle = {
		textAlign: 'center',
		border: 'solid 2px #5CAEFA',
		borderRadius: '15px',
		backgroundColor: '#5CAEFA',
		color: '#fff',
		padding: '3px 0',
		width: '330px',
		alignSelf: 'center'
	};

	if (props.collapsed) {
		return (
			<div style={divStyle}>
				<p style={headingStyle}>{props.header}</p>
			</div>
		);
	} else {
		return (
			<div style={divStyle}>
				<p style={headingStyle}>{props.header}</p>
				{props.games.map(x => (
					<ScoreCard key={x.gameID} {...x} coaches="Jim and Chema" />
				))}
			</div>
		);
	}
}

// export const fakeprops = {
// 	header: 'Ch A Coed Kickball',
// 	games: [
// 		{
// 			"gameID": 890,
// 			"score": "0 - 6",
// 			"setScore": " -  |  -  |  - ",
// 			"scoreCardTop": {
// 				"sport": "Mini Soccer",
// 				"showRachmanusFlag": true,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Sunday, 12/01 | 04:20 PM",
// 					"location": "JCC Back Field 3",
// 					"tournament": "Ch D Female - Mini Soccer"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ARG 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "ISR 1"
// 				},
// 				"hasSet": false
// 			}
// 		},
// 		{
// 			"gameID": 891,
// 			"score": "2 - 0",
// 			"setScore": "21 - 9 | 21 - 11 |  - ",
// 			"scoreCardTop": {
// 				"sport": "Volleyball",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Sunday, 12/01 | 03:30 PM",
// 					"location": "JCC Tennis Court 9",
// 					"tournament": "Ch D Female - Volleyball"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ISR 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "USA 1"
// 				},
// 				"hasSet": true
// 			}
// 		},
// 		{
// 			"gameID": 888,
// 			"score": "18 - 10",
// 			"setScore": " -  |  -  |  - ",
// 			"scoreCardTop": {
// 				"sport": "Basketball",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Sunday, 12/01 | 02:40 PM",
// 					"location": "JCC Basketball Out 2",
// 					"tournament": "Ch D Female - Basketball"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ISR 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "USA 1"
// 				},
// 				"hasSet": false
// 			}
// 		},
// 		{
// 			"gameID": 297,
// 			"score": "0 - 2",
// 			"setScore": "0 - 21 | 10 - 21 |  - ",
// 			"scoreCardTop": {
// 				"sport": "Volleyball",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Sunday, 12/01 | 11:20 AM",
// 					"location": "JCC Tennis Court 9",
// 					"tournament": "Ch D Female - Volleyball"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ARG 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "ISR 1"
// 				},
// 				"hasSet": true
// 			}
// 		},
// 		{
// 			"gameID": 889,
// 			"score": "16 - 8",
// 			"setScore": " -  |  -  |  - ",
// 			"scoreCardTop": {
// 				"sport": "Kickball",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Sunday, 12/01 | 08:50 AM",
// 					"location": "Hillel - Field 1",
// 					"tournament": "Ch D Female - Kickball"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ISR 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "VEN 1"
// 				},
// 				"hasSet": false
// 			}
// 		},
// 		{
// 			"gameID": 332,
// 			"score": "10 - 0",
// 			"setScore": " -  |  -  |  - ",
// 			"scoreCardTop": {
// 				"sport": "Mini Soccer",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Friday, 11/29 | 03:30 PM",
// 					"location": "JCC Back Field 1",
// 					"tournament": "Ch D Female - Mini Soccer"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ISR 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "VEN 2"
// 				},
// 				"hasSet": false
// 			}
// 		},
// 		{
// 			"gameID": 324,
// 			"score": "2 - 0",
// 			"setScore": "21 - 3 | 21 - 6 |  - ",
// 			"scoreCardTop": {
// 				"sport": "Volleyball",
// 				"showRachmanusFlag": false,
// 				"showTimer": false,
// 				"timeRemaining": false,
// 				"gameInfo": {
// 					"datetime": "Friday, 11/29 | 12:10 PM",
// 					"location": "JCC Tennis Court 9",
// 					"tournament": "Ch D Female - Volleyball"
// 				}
// 			},
// 			"scoreCardBottom": {
// 				"team1Icon": {
// 					"imageURL": "assets/countryicons/flag-Argentina.png",
// 					"label": "ISR 1"
// 				},
// 				"team2Icon": {
// 					"imageURL": "assets/countryicons/flag-Venezuela.png",
// 					"label": "MEX 1"
// 				},
// 				"hasSet": true
// 			}
// 		},
// 	]
// };
