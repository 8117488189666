import React from 'react';
import RegistrantInfoTableHeader from '../components/logistics/RegistrantInfoTableHeader';
import RegistrantInfoRow from '../components/logistics/RegistrantInfoRow';

export function getRegistrantTableRender(
	registrantsToShow,
	currentUserCanEdit,
	editHandler
) {
	if (registrantsToShow.length !== 0) {
		return (
			<table className="table table-striped">
				<RegistrantInfoTableHeader />
				<tbody>
					{registrantsToShow.map(x => {
						return (
							<RegistrantInfoRow
								key={x.registrant_id}
								{...x}
								editHandler={
									currentUserCanEdit ? editHandler : null
								}
							/>
						);
					})}
				</tbody>
			</table>
		);
	} else {
		return (
			<h3 className="text-center">
				No registrants match the selected filter
			</h3>
		);
	}
}
