import React from 'react';
import { connect } from 'react-redux';
import {
	getsiteassignments,
	getsitedirectors,
	assignsitedirector
} from '../services/logistics/logisticsActions.js';
import AssignSiteDirectorGrid from '../components/logistics/AssignSiteDirectorGrid.js';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';

class ManageSiteDirectorView extends React.Component {
	constructor(props) {
		super(props);

		// Keeps track of the site director who's assignments
		// are currently being viewed/modified
		this.state = {
			activeSiteDirector: ''
		};

		this.selectSiteDirector = this.selectSiteDirector.bind(this);
		this.assignSiteDirector = this.assignSiteDirector.bind(this);
	}

	componentDidMount() {
		// Gets the assignment for which user is on each (date, location)
		// tuple and if there are games on that day
		this.props.getSiteAssignments();

		// Gets the list of users who are site directors
		this.props.getSiteDirectors();
	}

	// Change the active site director
	selectSiteDirector(username) {
		if (this.state.activeSiteDirector === username) {
			// Toggle off
			this.setState({
				activeSiteDirector: ''
			});
		} else {
			// Select
			this.setState({
				activeSiteDirector: username
			});
		}
	}

	assignSiteDirector(cellObj) {
		// Check if any site director is currently active
		if (this.state.activeSiteDirector === '') return;

		if (cellObj.username !== this.state.activeSiteDirector) {
			// Assign currently active site director to cell
			this.props.assignSiteDirector({
				date: cellObj.date_raw,
				location: cellObj.location_id,
				username: this.state.activeSiteDirector
			});
		} else {
			// Unassign currently active site director from cell
			this.props.assignSiteDirector({
				date: cellObj.date_raw,
				location: cellObj.location_id,
				username: null
			});
		}
	}

	render() {
		if (this.props.siteassignments && this.props.sitedirectors) {
			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Manage Site Directors
						</Card.Header>
						<Card.Body>
							<Card.Title>
								Assign Site Directors and Mark their
								Unavailability.
							</Card.Title>
							<Container>
								<Row>
									{/* Assignment Area */}
									<Col md={9} className="align-self-center">
										<Row className="row text-center">
											<Form.Label>
												Assignment Grid
											</Form.Label>
										</Row>
										<Row>
											<AssignSiteDirectorGrid
												assignments={
													this.props.siteassignments
												}
												datesList={this.props.datesList}
												locationsList={
													this.props.locationsList
												}
												activeSiteDirector={
													this.state
														.activeSiteDirector
												}
												clickHandler={
													this.assignSiteDirector
												}
											/>
										</Row>
									</Col>

									{/* Populate list of sitedirectors */}
									<Col md={3}>
										<Row className="row text-center">
											<Form.Label>
												Sitedirectors
											</Form.Label>
										</Row>
										<Row className="border-bottom">
											{this.props.sitedirectors.map(x => (
												<p
													key={x.username}
													style={{
														backgroundColor:
															x.username ===
															this.state
																.activeSiteDirector
																? '#d1e7dd'
																: null
													}}
													onClick={this.selectSiteDirector.bind(
														this,
														x.username
													)}
												>
													{x.name}
												</p>
											))}
										</Row>
									</Col>
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		} else return null;
	}
}

const mapStateToProps = state => {
	let raw = state.logistics;
	if (raw.siteassignments && raw.sitedirectors) {
		return {
			siteassignments: raw.siteassignments.map(x => {
				if (x.username) {
					return {
						...x,
						name: raw.sitedirectors.find(
							y => y.username === x.username
						).name
					};
				} else return x;
			}),
			sitedirectors: raw.sitedirectors,
			datesList: raw.datesList,
			locationsList: raw.locationsList
		};
	} else
		return {
			siteassignments: null,
			sitedirectors: null,
			datesList: null,
			locationsList: null
		};
};

const mapDispatchToProps = dispatch => {
	return {
		getSiteAssignments: () => dispatch(getsiteassignments()),
		getSiteDirectors: () => dispatch(getsitedirectors()),
		assignSiteDirector: obj => dispatch(assignsitedirector(obj))
		// removeSubscription: (id) => dispatch(deleteSubscription(id))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManageSiteDirectorView);
