import React, { useState, useEffect } from 'react';
import { Card, Alert, Table, Button, Form } from 'react-bootstrap';
import { request } from '../global/utils.js';
import Query from '../components/Query.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { cloudResources } from '../global/cloudresources.js';

function columnTypeDisplay(raw) {
	let colTypePrefix = raw.split('(')[0];
	let colType;
	switch (colTypePrefix) {
		case 'int':
			colType = 'number';
			break;
		case 'varchar':
			colType = 'string';
			break;
		case 'enum':
			colType = 'enum';
			break;
		default:
			colType = colTypePrefix;
			break;
	}
	return <p>{colType}</p>;
}

export default class DatabaseInfoView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dbData: null,
			searchText: ''
		};
	}

	async componentDidMount() {
		let rawDbData = await fetch(cloudResources.databaseSchemaEndpoint);
		rawDbData = await rawDbData.json();

		let rawQueryData = await request('dev/queries');
		rawQueryData = rawQueryData.data;

		let combinedDbData = rawDbData.map(x => {
			return {
				...x,
				queries: rawQueryData.filter(y =>
					y.query.includes(x.table_name)
				)
			};
		});
		this.setState({ dbData: combinedDbData });
	}

	scrollFunc(table) {
		return () => {
			this.setState({ searchText: '' }, () => {
				document.querySelector(`#${table}`).scrollIntoView({
					behavior: 'smooth'
				});
			});
		};
	}

	render() {
		if (this.state.dbData) {
			// Search bar filters tables
			let filteredDbData = this.state.dbData.filter(obj =>
				obj.table_name
					.toLowerCase()
					.includes(this.state.searchText.toLowerCase())
			);

			return (
				<div style={{ width: '100%' }}>
					<Form.Group className="mb-1" style={{ width: '25%' }}>
						<Form.Control
							className="me-sm-2"
							placeholder="Search Tables"
							aria-label=""
							aria-describedby="basic-addon1"
							value={this.state.searchText}
							onChange={e =>
								this.setState({ searchText: e.target.value })
							}
						/>
					</Form.Group>
					{filteredDbData.map(x => {
						return (
							<Card
								style={{ width: '100%' }}
								className="mx-2 text-center mb-4"
								id={x.table_name}
								key={x.table_name}
							>
								<Card.Body>
									<Alert
										variant="primary"
										style={{ width: '20%' }}
									>
										{x.table_name}
									</Alert>
									<Card.Text align="start">
										<span style={{ fontWeight: 'bold' }}>
											Description:{' '}
										</span>
										{x.description}
									</Card.Text>
									<Card.Text align="start">
										<p style={{ fontWeight: 'bold' }}>
											Columns:
										</p>
										<Table striped bordered>
											<thead>
												<tr>
													<th>Name</th>
													<th>Description</th>
													<th>Column Type</th>
													<th>Nullable</th>
													<th>Referenced By</th>
													<th>Reference To</th>
												</tr>
											</thead>
											<tbody>
												{x.columns.map(y => {
													return (
														<tr
															key={`${x.table_name}-${y.name}`}
														>
															<td>
																{y.col_key ===
																'PRI'
																	? [
																			<FontAwesomeIcon
																				key={`${x.table_name}-${y.name}-icon`}
																				className="me-2"
																				icon={
																					faKey
																				}
																				style={{
																					display:
																						'inline'
																				}}
																			/>,
																			<p
																				key={`${x.table_name}-${y.name}`}
																				style={{
																					display:
																						'inline'
																				}}
																			>
																				{
																					y.name
																				}
																			</p>
																	  ]
																	: y.name}
															</td>
															<td>{y.desc}</td>
															<td>
																{columnTypeDisplay(
																	y.col_type
																)}
															</td>
															<td>
																{y.nullable}
															</td>
															<td>
																{y.referenced_by.map(
																	z => {
																		return (
																			<Button
																				variant="outline-primary"
																				onClick={this.scrollFunc(
																					z.table
																				)}
																				key={`refto-${x.table_name}-${y.name}-${z.table}-${z.column}`}
																			>
																				{
																					z.table
																				}
																			</Button>
																		);
																	}
																)}
															</td>
															<td>
																{y.reference_to ? (
																	<Button
																		variant="outline-primary"
																		onClick={this.scrollFunc(
																			y
																				.reference_to
																				.table
																		)}
																	>
																		{
																			y
																				.reference_to
																				.table
																		}
																	</Button>
																) : (
																	''
																)}
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</Card.Text>
									{x.triggers.length === 0 ? null : (
										<Card.Text align="start">
											<span
												style={{ fontWeight: 'bold' }}
											>
												Triggers:{' '}
											</span>
											{x.triggers.map(
												y =>
													`${y.timing} ${y.on_action}\n${y.statement}`
											)}
										</Card.Text>
									)}
									{x.queries.length === 0 ? null : (
										<div>
											<Card.Text align="start">
												<span
													style={{
														fontWeight: 'bold'
													}}
												>
													Queries:{' '}
												</span>
											</Card.Text>
											{/* {x.queries.map(y => <Card.Text align="start">{y.title}</Card.Text>)} */}
											{x.queries.map(y => (
												<Query {...y} key={y.title} />
											))}
										</div>
									)}
								</Card.Body>
							</Card>
						);
					})}
				</div>
			);
		} else return <p>Loading...</p>;
	}
}
