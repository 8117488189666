import { createReducer } from '@reduxjs/toolkit';
import {
	controlpanelData,
	changeShabbatMode,
	dismisslocations,
	dismissteams
} from './controlpanelActions.js';

const controlpanelReducer = createReducer({}, builder => {
	builder.addCase(controlpanelData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(controlpanelData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(controlpanelData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(dismisslocations.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(dismisslocations.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(dismisslocations.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(dismissteams.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(dismissteams.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(dismissteams.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(changeShabbatMode.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending shabbatmode'
	}));

	builder.addCase(changeShabbatMode.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled shabbatmode',
			...action.payload
		};
	});

	builder.addCase(changeShabbatMode.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error shabbatmode'
	}));
});

export default controlpanelReducer;
