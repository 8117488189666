import { createReducer } from '@reduxjs/toolkit';
import {
	defaultSchedule,
	allSchedules,
	addMoveChange,
	addSwapChange,
	moveGameToClipboard,
	selectScheduleVersion,
	moveGameToTrash,
	createGame,
	cancelGameCreation,
	createGameSetup,
	colorChartSave,
	setGameFilter,
	unsetGameFilter,
	publishschedule,
	unpublishschedule,
	getaffectedteamsched,
	cancelschedchanges,
	confirmschedchanges
} from './colorchartActions.js';

const colorchartReducer = createReducer({}, builder => {
	builder.addCase(setGameFilter.type, (state, action) => {
		return {
			...state,
			initialGameFilter: action.payload.games
		};
	});

	builder.addCase(unsetGameFilter.type, (state, action) => {
		return {
			...state,
			initialGameFilter: null
		};
	});

	builder.addCase(defaultSchedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(defaultSchedule.fulfilled.type, (state, action) => {
		return {
			...state,
			defaultSchedule: action.payload.version,
			isPublished: action.payload.isPublished,
			requestStatus: 'fulfilled'
		};
	});

	builder.addCase(defaultSchedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(publishschedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(publishschedule.fulfilled.type, (state, action) => {
		return {
			...state,
			isPublished: true,
			requestStatus: 'fulfilled'
		};
	});

	builder.addCase(publishschedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(unpublishschedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(unpublishschedule.fulfilled.type, (state, action) => {
		return {
			...state,
			isPublished: false,
			requestStatus: 'fulfilled'
		};
	});

	builder.addCase(unpublishschedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(allSchedules.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(allSchedules.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			schedulesByVersion: action.payload.schedulesByVersion,
			firstRow: action.payload.firstRow,
			secondRow: action.payload.secondRow,
			scheduleVersions: action.payload.scheduleVersions,
			defaultSchedule: action.payload.defaultSchedule,
			sportsByLoc: action.payload.sportsByLoc,
			scheduleChanges: [],
			changesByGameID: {},
			actionNumber: 0,
			isPublished: action.payload.isPublished,
			tournamentOptions: action.payload.tournamentOptions,
			teamOptions: action.payload.teamOptions,
			gameNotes: action.payload.gameNotes,
			scores: action.payload.scores
		};
	});

	builder.addCase(allSchedules.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(addMoveChange.type, (state, action) => {
		let scheduleSet =
			state.schedulesByVersion[action.payload.game.schedule_key];
		let newSchedule = scheduleSet.schedules;
		let clipboardGames = scheduleSet.clipboardGames;

		let game = JSON.parse(JSON.stringify(action.payload.game));
		console.log(action.payload.game);
		if (game.date !== null) {
			let dailySchedule1 = newSchedule.find(
				x => x.date === game.date
			).schedule;
			let timeRow1 = dailySchedule1.find(x => x.time === game.time);
			if (state.firstRow.map(x => x.location).includes(game.location)) {
				timeRow1.firstRow[
					state.firstRow.map(x => x.location).indexOf(game.location)
				] = null;
			} else {
				timeRow1.secondRow[
					state.secondRow.map(x => x.location).indexOf(game.location)
				] = null;
			}
		}

		let dailySchedule2 = newSchedule.find(
			x => x.date === action.payload.slot.date
		).schedule;
		let timeRow2 = dailySchedule2.find(
			x => x.time === action.payload.slot.time
		);

		game.date = action.payload.slot.date;
		game.time = action.payload.slot.time;
		game.location = action.payload.slot.location;

		if (
			state.firstRow
				.map(x => x.location)
				.includes(action.payload.slot.location)
		) {
			timeRow2.firstRow[
				state.firstRow
					.map(x => x.location)
					.indexOf(action.payload.slot.location)
			] = game;
		} else {
			timeRow2.secondRow[
				state.secondRow
					.map(x => x.location)
					.indexOf(action.payload.slot.location)
			] = game;
		}

		if (action.payload.game.location === 'Clipboard') {
			clipboardGames = clipboardGames.filter(
				x => x.game_id !== action.payload.game.game_id
			);
		}

		state.schedulesByVersion[action.payload.game.schedule_key].schedules =
			newSchedule;
		state.schedulesByVersion[
			action.payload.game.schedule_key
		].clipboardGames = clipboardGames;
		const changeObject = {
			changeType: 'move',
			actionNumber: state.actionNumber,
			game: action.payload.game,
			slot: action.payload.slot
		};
		state.scheduleChanges.push(changeObject);
		if (state.changesByGameID.hasOwnProperty(action.payload.game.game_id)) {
			state.changesByGameID[action.payload.game.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game.game_id] = [
				state.actionNumber
			];
		}
		state.actionNumber = state.actionNumber + 1;
	});

	builder.addCase(addSwapChange.type, (state, action) => {
		let game1 = JSON.parse(JSON.stringify(action.payload.game1));
		let game2 = JSON.parse(JSON.stringify(action.payload.game2));
		let scheduleSet =
			state.schedulesByVersion[action.payload.game1.schedule_key];
		let newSchedule = scheduleSet.schedules;
		let clipboardGames = scheduleSet.clipboardGames;

		game2.location = action.payload.game1.location;
		game2.date = action.payload.game1.date;
		game2.time = action.payload.game1.time;
		game1.location = action.payload.game2.location;
		game1.date = action.payload.game2.date;
		game1.time = action.payload.game2.time;

		if (action.payload.game1.date !== null) {
			let dailySchedule1 = newSchedule.find(
				x => x.date === action.payload.game1.date
			).schedule;
			let timeRow1 = dailySchedule1.find(
				x => x.time === action.payload.game1.time
			);
			if (
				state.firstRow
					.map(x => x.location)
					.includes(action.payload.game1.location)
			) {
				timeRow1.firstRow[
					state.firstRow
						.map(x => x.location)
						.indexOf(action.payload.game1.location)
				] = game2;
			} else {
				timeRow1.secondRow[
					state.secondRow
						.map(x => x.location)
						.indexOf(action.payload.game1.location)
				] = game2;
			}
		} else {
			clipboardGames = clipboardGames.filter(
				x => x.game_id !== action.payload.game1.game_id
			);
			clipboardGames.push(game2);
		}

		if (action.payload.game2.date !== null) {
			let dailySchedule2 = newSchedule.find(
				x => x.date === action.payload.game2.date
			).schedule;
			let timeRow2 = dailySchedule2.find(
				x => x.time === action.payload.game2.time
			);

			if (
				state.firstRow
					.map(x => x.location)
					.includes(action.payload.game2.location)
			) {
				timeRow2.firstRow[
					state.firstRow
						.map(x => x.location)
						.indexOf(action.payload.game2.location)
				] = game1;
			} else {
				timeRow2.secondRow[
					state.secondRow
						.map(x => x.location)
						.indexOf(action.payload.game2.location)
				] = game1;
			}
		} else {
			clipboardGames = clipboardGames.filter(
				x => x.game_id !== action.payload.game2.game_id
			);
			clipboardGames.push(game1);
		}

		state.schedulesByVersion[action.payload.game1.schedule_key].schedules =
			newSchedule;
		state.schedulesByVersion[
			action.payload.game1.schedule_key
		].clipboardGames = clipboardGames;
		const changeObject = {
			changeType: 'swap',
			actionNumber: state.actionNumber,
			game1: action.payload.game1,
			game2: action.payload.game2
		};
		state.scheduleChanges.push(changeObject);
		if (
			state.changesByGameID.hasOwnProperty(action.payload.game1.game_id)
		) {
			state.changesByGameID[action.payload.game1.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game1.game_id] = [
				state.actionNumber
			];
		}
		if (
			state.changesByGameID.hasOwnProperty(action.payload.game2.game_id)
		) {
			state.changesByGameID[action.payload.game2.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game2.game_id] = [
				state.actionNumber
			];
		}
		state.actionNumber = state.actionNumber + 1;
	});

	builder.addCase(moveGameToClipboard.type, (state, action) => {
		let newSchedule =
			state.schedulesByVersion[action.payload.schedule_key].schedules;
		let dailySchedule = newSchedule.find(
			x => x.date === action.payload.date
		).schedule;
		let timeRow = dailySchedule.find(x => x.time === action.payload.time);
		let game = JSON.parse(JSON.stringify(action.payload));

		if (
			state.firstRow
				.map(x => x.location)
				.includes(action.payload.location)
		) {
			timeRow.firstRow[
				state.firstRow
					.map(x => x.location)
					.indexOf(action.payload.location)
			] = null;
		} else {
			timeRow.secondRow[
				state.secondRow
					.map(x => x.location)
					.indexOf(action.payload.location)
			] = null;
		}

		state.schedulesByVersion[action.payload.schedule_key].schedules =
			newSchedule;
		game.location = 'Clipboard';
		state.schedulesByVersion[
			action.payload.schedule_key
		].clipboardGames.push(game);
		const changeObject = {
			changeType: 'Clipboard',
			actionNumber: state.actionNumber,
			game: action.payload
		};
		state.scheduleChanges.push(changeObject);
		if (state.changesByGameID.hasOwnProperty(action.payload.game_id)) {
			state.changesByGameID[action.payload.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game_id] = [
				state.actionNumber
			];
		}
		state.actionNumber = state.actionNumber + 1;
	});

	builder.addCase(moveGameToTrash.type, (state, action) => {
		let scheduleSet = state.schedulesByVersion[action.payload.schedule_key];
		let newSchedule = scheduleSet.schedules;
		let clipboardGames = scheduleSet.clipboardGames;
		let dailySchedule = newSchedule.find(
			x => x.date === action.payload.date
		).schedule;
		let timeRow = dailySchedule.find(x => x.time === action.payload.time);

		if (
			state.firstRow
				.map(x => x.location)
				.includes(action.payload.location)
		) {
			timeRow.firstRow[
				state.firstRow
					.map(x => x.location)
					.indexOf(action.payload.location)
			] = null;
		} else {
			timeRow.secondRow[
				state.secondRow
					.map(x => x.location)
					.indexOf(action.payload.location)
			] = null;
		}

		if (action.payload.location === 'Clipboard') {
			clipboardGames = clipboardGames.filter(
				x => x.game_id !== action.payload.game_id
			);
		}

		state.schedulesByVersion[action.payload.schedule_key].schedules =
			newSchedule;
		state.schedulesByVersion[action.payload.schedule_key].clipboardGames =
			clipboardGames;
		const changeObject = {
			changeType: 'delete',
			actionNumber: state.actionNumber,
			game: action.payload
		};
		state.scheduleChanges.push(changeObject);
		if (state.changesByGameID.hasOwnProperty(action.payload.game_id)) {
			state.changesByGameID[action.payload.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game_id] = [
				state.actionNumber
			];
		}
		state.actionNumber = state.actionNumber + 1;
	});

	builder.addCase(createGameSetup.type, (state, action) => {
		let newSchedule =
			state.schedulesByVersion[action.payload.schedule_key].schedules;

		let slot = JSON.parse(JSON.stringify(action.payload));
		let dailySchedule1 = newSchedule.find(
			x => x.date === slot.date
		).schedule;
		let timeRow1 = dailySchedule1.find(x => x.time === slot.time);
		if (state.firstRow.map(x => x.location).includes(slot.location)) {
			timeRow1.firstRow[
				state.firstRow.map(x => x.location).indexOf(slot.location)
			] = {
				...action.payload,
				background: 'black',
				text: '-',
				textcolor: 'black'
			};
		} else {
			timeRow1.secondRow[
				state.secondRow.map(x => x.location).indexOf(slot.location)
			] = {
				...action.payload,
				background: 'black',
				text: '-',
				textcolor: 'black'
			};
		}

		state.schedulesByVersion[action.payload.schedule_key].schedules =
			newSchedule;
	});

	builder.addCase(createGame.type, (state, action) => {
		let newSchedule =
			state.schedulesByVersion[action.payload.schedule_key].schedules;

		let game = JSON.parse(JSON.stringify(action.payload));
		let dailySchedule1 = newSchedule.find(
			x => x.date === game.date
		).schedule;
		let timeRow1 = dailySchedule1.find(x => x.time === game.time);
		if (state.firstRow.map(x => x.location).includes(game.location)) {
			timeRow1.firstRow[
				state.firstRow.map(x => x.location).indexOf(game.location)
			] = action.payload;
		} else {
			timeRow1.secondRow[
				state.secondRow.map(x => x.location).indexOf(game.location)
			] = action.payload;
		}

		state.schedulesByVersion[action.payload.schedule_key].schedules =
			newSchedule;
		const changeObject = {
			changeType: 'create',
			actionNumber: state.actionNumber,
			game: action.payload
		};
		state.scheduleChanges.push(changeObject);
		if (state.changesByGameID.hasOwnProperty(action.payload.game_id)) {
			state.changesByGameID[action.payload.game_id].push(
				state.actionNumber
			);
		} else {
			state.changesByGameID[action.payload.game_id] = [
				state.actionNumber
			];
		}
		state.actionNumber = state.actionNumber + 1;
	});

	builder.addCase(cancelGameCreation.type, (state, action) => {
		let newSchedule =
			state.schedulesByVersion[action.payload.schedule_key].schedules;

		let game = JSON.parse(JSON.stringify(action.payload));
		let dailySchedule1 = newSchedule.find(
			x => x.date === game.date
		).schedule;
		let timeRow1 = dailySchedule1.find(x => x.time === game.time);
		if (state.firstRow.map(x => x.location).includes(game.location)) {
			timeRow1.firstRow[
				state.firstRow.map(x => x.location).indexOf(game.location)
			] = null;
		} else {
			timeRow1.secondRow[
				state.secondRow.map(x => x.location).indexOf(game.location)
			] = null;
		}

		state.schedulesByVersion[action.payload.schedule_key].schedules =
			newSchedule;
	});

	builder.addCase(selectScheduleVersion.type, (state, _action) => {
		state.actionNumber = 0;
		state.scheduleChanges = [];
		state.changesByGameID = {};
	});

	builder.addCase(colorChartSave.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(colorChartSave.fulfilled.type, (state, action) => {
		state.requestStatus = 'fulfilled';

		if (action.payload === 'overwrite') state.overwrite = true;
		else state.overwrite = false;
	});

	builder.addCase(colorChartSave.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getaffectedteamsched.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getaffectedteamsched.fulfilled.type, (state, action) => {
		state.affectedTeamSched = action.payload;
	});

	builder.addCase(getaffectedteamsched.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(cancelschedchanges.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(cancelschedchanges.fulfilled.type, (state, action) => {
		state.affectedTeamSched = null;
		state.overwrite = false;
	});

	builder.addCase(cancelschedchanges.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(confirmschedchanges.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(confirmschedchanges.fulfilled.type, (state, action) => {
		state.changesByGameID = {};
		state.scheduleChanges = [];
		state.actionNumber = 0;
		state.affectedTeamSched = null;
	});

	builder.addCase(confirmschedchanges.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default colorchartReducer;
