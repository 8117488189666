import React from 'react';
import { connect } from 'react-redux';
import {
	captaincatgendercombos,
	getplayers
} from '../services/teamassignments/teamassignmentsActions.js';
import ToggleSelector from '../components/general/ToggleSelector.js';
import UnassignedPlayersArea from '../components/teamassignments/UnassignedPlayersArea.js';
import AssignedPlayersArea from '../components/teamassignments/AssignedPlayersArea.js';
import { Container, Card, ListGroup, Row } from 'react-bootstrap';
import DragDropItem from '../components/general/DragDropItem.js';

class CaptainTeamAssignmentView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCategory: ''
		};

		this.selectCategory = this.selectCategory.bind(this);
	}

	componentDidMount() {
		this.props
			.getCategoryGenderOptions()
			.then(result => {
				const category_gender = `${result.payload[0].category} ${result.payload[0].gender}`;
				this.setState({
					selectedCategory: category_gender
				});
				return category_gender;
			})
			.then(category_gender => {
				this.props.getPlayersinCatGenderCountry({
					catGender: category_gender,
					country: this.props.categories[0].country
				});
			});
	}

	selectCategory(event) {
		// console.log("Category", event.target)
		this.setState({ selectedCategory: event.target.innerHTML });
		this.props.getPlayersinCatGenderCountry({
			catGender: event.target.innerHTML,
			country: this.props.categories[0].country
		});
	}

	render() {
		if (this.props.categories) {
			let currentPlayers = [];
			if (this.state.selectedCategory !== '') {
				console.log(this.props.catGenderOptions);
				console.log(this.state.selectedCategory);
				currentPlayers = this.props.catGenderOptions.find(
					x =>
						`${x.category} ${x.gender}` ===
						this.state.selectedCategory
				).players;
			}

			const uniqueGrades = [
				...new Set(currentPlayers.map(item => item.grade))
			];
			const colorsGrades = ['yellow', '#90EE90', 'pink', 'orange'];
			let unassignedPlayers = currentPlayers.filter(
				x => x.team_id === null
			);

			unassignedPlayers = unassignedPlayers.map(x => {
				let grade = x.grade;
				let colorUser = uniqueGrades.indexOf(grade);
				let bold = x.gender === 'Male' ? true : false;
				return { ...x, colorUser: colorsGrades[colorUser], bold };
			});

			let assignedPlayers = currentPlayers.filter(
				x => x.team_id !== null
			);
			assignedPlayers = assignedPlayers.map(x => {
				let grade = x.grade;
				let colorUser = uniqueGrades.indexOf(grade);
				let bold = x.gender === 'Male' ? true : false;
				return { ...x, colorUser: colorsGrades[colorUser], bold };
			});
			let teamObjs = assignedPlayers.map(x => {
				return {
					team_id: x.team_id,
					name: x.teamname
				};
			});
			let teams = Array.from(new Set(teamObjs.map(x => x.team_id)));
			teams.sort();

			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Team Assignment
						</Card.Header>
						<Card.Body>
							<Container>
								<Row>
									<ToggleSelector
										itemsList={this.props.categories.map(
											x => {
												return {
													text: x.text,
													textStyle:
														x.unassigned === 1
															? { color: 'red' }
															: {}
												};
											}
										)}
										textStyle={true}
										selectionObj={{
											selectedItem:
												this.state.selectedCategory,
											selectItem: this.selectCategory
										}}
									/>
								</Row>
								<Row>
									<UnassignedPlayersArea>
										<ListGroup
											style={{
												minHeight: 200,
												maxHeight: 400,
												display: 'flex',
												flexDirection: 'column',
												overflowX: 'none'
											}}
										>
											{unassignedPlayers.map(x => (
												<DragDropItem
													itemStyle={{
														backgroundColor:
															x.colorUser,
														fontWeight: x.bold
															? 'bold'
															: null
													}}
													key={x.registrant_id}
													id={x.registrant_id}
													text={x.name}
												/>
											))}
										</ListGroup>
									</UnassignedPlayersArea>
								</Row>

								<Row>
									<p>Assigned</p>
									{/* AssignedPlayerArea component for each team */}
									{teams.map(x => {
										return (
											<ListGroup
												horizontal
												className="mb-3"
												style={{
													minHeight: 200,
													overflowX: 'hidden',
													maxWidth: '350px'
												}}
											>
												<AssignedPlayersArea
													teamID={x}
													key={x}
													teamName={
														teamObjs.find(
															z => z.team_id === x
														).team_id
													}
													viewOnly={true}
												>
													{assignedPlayers
														.filter(
															player =>
																player.team_id ===
																x
														)
														.map(y => (
															<DragDropItem
																itemStyle={{
																	backgroundColor:
																		y.colorUser,
																	fontWeight:
																		y.bold
																			? 'bold'
																			: null,
																	height: '3.5vh',
																	alignItems:
																		'center',
																	paddingRight: 1,
																	paddingLeft: 1,
																	display:
																		'flex',
																	justifyContent:
																		'center'
																}}
																stylePBox={{
																	width: '100%'
																}}
																key={
																	y.registrant_id
																}
																id={
																	y.registrant_id
																}
																disabled={true}
																text={y.name}
															/>
														))}
												</AssignedPlayersArea>
											</ListGroup>
										);
									})}
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		} else {
			return <p>Loading</p>;
		}
	}
}

const mapStateToProps = state => {
	let raw = state.teamassignments;
	if (raw.capcatgenderoptions) {
		let mappedCategories = raw.capcatgenderoptions.map(x => {
			return {
				text: `${x.category} ${x.gender}`,
				id: `${x.category} ${x.gender}`,
				country: x.country,
				...x
			};
		});
		return {
			categories: mappedCategories,
			catGenderOptions: raw.capcatgenderoptions
		};
	} else {
		return {
			categories: null,
			categoriesByCountry: null
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getCategoryGenderOptions: () => dispatch(captaincatgendercombos()),
		getPlayersinCatGenderCountry: obj => dispatch(getplayers(obj))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CaptainTeamAssignmentView);
