import React from 'react';

/**
 * Returns an icon with a label underneath
 * @function LabeledIconFlag
 * @param {Object} props
 * @param {string} props.imageURL - link to the icon file
 * @param {string} props.label - label for the icon
 * @returns {React.Component}
 */
export default function LabeledIconFlag(props) {
	const divStyle = {
		alignSelf: 'flex-end'
	};

	const imgStyle = {
		width: '50px'
	};

	const pStyle = {
		margin: '0',
		padding: '0'
	};

	return (
		<div style={divStyle}>
			<img style={imgStyle} alt="iconimage" src={props.imageURL} />
			<p style={pStyle}> {props.label}</p>
		</div>
	);
}

// const fakeprops = {
//     label: "ARG 2",
//     imageURL:'assets/countryicons/flag-Argentina.png'
// };
