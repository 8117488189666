import { createReducer } from '@reduxjs/toolkit';
import {
	masterschedulechangelog,
	refschedulechangelog
} from './masterschedulechangesAction';

const masterscheduleChangesReducer = createReducer({}, builder => {
	builder.addCase(masterschedulechangelog.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(masterschedulechangelog.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			masterschedulechangelog: action.payload
		};
	});

	builder.addCase(masterschedulechangelog.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(refschedulechangelog.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(refschedulechangelog.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			refschedulechangelog: action.payload
		};
	});

	builder.addCase(refschedulechangelog.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default masterscheduleChangesReducer;
