import React from 'react';
import './../index.css';

export default function RulebookView() {
	return (
		<embed
			src="https://www.dropbox.com/s/n1y784t0btf506c/Rule%20Book%20LIVE.pdf?dl=0&raw=1"
			type="application/pdf"
			style={{ height: '90vh', width: '80vw' }}
		></embed>
	);
}
