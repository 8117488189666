import { createReducer } from '@reduxjs/toolkit';
import { untieData, updateUntie } from './untieAction.js';

const eventsReducer = createReducer({}, builder => {
	builder.addCase(untieData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(untieData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			tiedTeams: action.payload
		};
	});

	builder.addCase(untieData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(updateUntie.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending untie'
	}));

	builder.addCase(updateUntie.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled untie',
			data: action.payload
		};
	});

	builder.addCase(updateUntie.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error untie'
	}));
});

export default eventsReducer;
