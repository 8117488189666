import React from 'react';
import './../../containers/style/DragDropList.css';
/**
 * A list that {@link DragDropItem} can be dropped onto
 * or dragged from.
 * @function DragDropList
 * @param {Object} props
 * @param {function} props.cardDropHandler - callback for when a {@link DragDropItem}
 * is dropped onto this list. Takes one argument, the id of the {@link DragDropItem}
 * @param {React.Component[]} props.children - items to be displayed
 * in this drag and drop list
 * @returns {React.Component}
 */
export default function DragDropList(props) {
	const dropHandler = e => {
		e.preventDefault();
		const cardID = e.dataTransfer.getData('cardID');
		props.cardDropHandler(cardID);
	};

	const dragOverHandler = e => e.preventDefault();

	const divStyle = {
		height: '65vh',
		// border: '2px solid black', somebody keeps on uncommenting this line. speak to me (Eyal) for styling...
		alignContent: 'center',
		overflow: 'scroll',
		textAlign: 'center',
		overflowX: 'hidden',
		...props.stylePBox
	};

	return (
		<div
			onDrop={dropHandler}
			onDragOver={dragOverHandler}
			style={divStyle}
			className="border"
		>
			{props.children}
		</div>
	);
}
