import { createReducer } from '@reduxjs/toolkit';
import { masterStandingsData } from './masterStandingsActions.js';

const mastersStandingsReducer = createReducer({}, builder => {
	builder.addCase(masterStandingsData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(masterStandingsData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			tournaments: action.payload.tournaments,
			standings: action.payload.standings,
			tiebreakers: action.payload.tiebreakers,
			scores: action.payload.scores
		};
	});

	builder.addCase(masterStandingsData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default mastersStandingsReducer;
