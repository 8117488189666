import { createReducer } from '@reduxjs/toolkit';
import {
	scorechangerequests,
	acceptscorechangerequest,
	rejectscorechangerequest,
	scorechangelog
} from './scorechangesActions';

const scorechangesReducer = createReducer({}, builder => {
	builder.addCase(scorechangerequests.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(scorechangerequests.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(scorechangerequests.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(rejectscorechangerequest.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(
		rejectscorechangerequest.fulfilled.type,
		(state, action) => {
			return {
				...state,
				requestStatus: 'fulfilled',
				...action.payload
			};
		}
	);

	builder.addCase(
		rejectscorechangerequest.rejected.type,
		(state, action) => ({
			...state,
			requestStatus: 'error'
		})
	);

	builder.addCase(acceptscorechangerequest.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(
		acceptscorechangerequest.fulfilled.type,
		(state, action) => {
			return {
				...state,
				requestStatus: 'fulfilled',
				...action.payload
			};
		}
	);

	builder.addCase(
		acceptscorechangerequest.rejected.type,
		(state, action) => ({
			...state,
			requestStatus: 'error'
		})
	);

	builder.addCase(scorechangelog.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(scorechangelog.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			scorechangelog: action.payload
		};
	});

	builder.addCase(scorechangelog.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default scorechangesReducer;
