import React from 'react';
import ScoreCardTop from './ScoreCardTop.js';
import ScoreCardBottom from './ScoreCardBottom.js';

/**
 * @class ScoreCard
 * @classdesc Displays a scorecard for a game
 * @param {Object} props
 * @param {int} props.gameID - unique ID for the game. Used as key for lists of scorecards
 * @param {string} props.score - score of the game
 * @param {string} [props.setScore] - scores of all sets
 * @param {Object} props.scoreCardTop - props for {@link ScoreCardTop}
 * @param {Object} props.scoreCardBottom
 * @param {Object} props.scoreCardBottom.team1Icon - props for {@link LabeledIcon}
 * @param {Object} props.scoreCardBottom.team2Icon - props for {@link LabeledIcon}
 * @param {boolean} props.scoreCardBottom.hasSet - whether the game has set scores
 * @returns {React.Component}
 */
export default class ScoreCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = { mode: 'game' };
		this.toggleScore = this.toggleScore.bind(this);
	}

	toggleScore() {
		if (this.state.mode === 'game') {
			this.setState({ mode: 'set' });
		} else {
			this.setState({ mode: 'game' });
		}
	}

	render() {
		let scoreCardBottomProps = this.props.scoreCardBottom;

		scoreCardBottomProps['mode'] = this.state.mode;
		scoreCardBottomProps['clickHandler'] = this.toggleScore;
		scoreCardBottomProps['score'] =
			this.state.mode === 'game' ? this.props.score : this.props.setScore;

		const cardStyle = {
			textAlign: 'center',
			backgroundColor: '#F9F9F9',
			height: '220px',
			width: '350px',
			margin: '3.5% 30px',
			borderRadius: '15px',
			boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
		};

		return (
			<div style={cardStyle}>
				<ScoreCardTop {...this.props.scoreCardTop} />
				<ScoreCardBottom {...this.props.scoreCardBottom} />
			</div>
		);
	}
}

// export const fakeprops = {
// 	score: '2 - 1',
// 	setScore: '15 - 2 | 13 - 15 15 - 10',
// 	scoreCardTop: {
// 		sport: 'Volleyball',
// 		gameInfo: {
// 			datetime: 'Thursday, 11/28 | 09:40 am',
// 			location: 'Hillel - Volleyball Out',
// 			tournament: 'Ch D Male - Volleyball'
// 		},
// 		showRachmanusFlag: false,
// 		showTimer: false,
// 		timeRemaining: false
// 	},
// 	scoreCardBottom: {
// 		team1Icon: {
// 			label: 'ARG 2',
// 			imageURL: 'assets/countryicons/flag-Argentina.png'
// 		},
// 		team2Icon: {
// 			label: 'VEN 2',
// 			imageURL: 'assets/countryicons/flag-Venezuela.png'
// 		},
// 		hasSet: true
// 	}
// };
