import React from 'react';

/**
 * @class SingleSelectList
 * @classdesc Displays a list of items of which one can be selected.
 * Searchbar is optionally available
 * @param {Object} props
 * @param {array} props.itemsList - list of items
 * @param {string} props.selectedItem - current item selected
 * @param {function} props.selectItem - function to call when an item is clicked on
 * @param {Object} props.selectedItemStyle - style for the selected item
 * @param {boolean} props.searchable - whether the list should be searchable or not
 * @returns {React.Component}
 */
export default class SingleSelectList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//Text that is in the searchbar
			searchText: ''
		};

		//Bind functions to this instance
		this.changeSearchText = this.changeSearchText.bind(this);
		this.filterItems = this.filterItems.bind(this);
	}

	//Event handler for user input
	changeSearchText(event) {
		this.setState({
			searchText: event.target.value
		});
	}

	//Filters itemsList based on search text
	filterItems() {
		const rawItems = this.props.itemsList;
		return rawItems.filter(x =>
			x.toLowerCase().includes(this.state.searchText.toLowerCase())
		);
	}

	render() {
		//Filter items
		const filteredItems = this.filterItems();

		let itemStyle = {
			borderRadius: '15px',
			padding: '3px 40px 3px 20px',
			margin: '5px',
			cursor: 'pointer',
			backgroundColor: '#4066D4',
			color: '#fff',
			whiteSpace: 'nowrap'
		};

		let searchStyle = {
			borderRadius: '15px',
			padding: '5px',
			marginBottom: '10px',
			outline: 'none'
		};

		let selectedItemStyle = {
			borderRadius: '15px',
			padding: '3px 20px',
			margin: '5px',
			cursor: 'pointer',
			backgroundColor: '#1857C3',
			color: '#fff'
		};

		//Render search bar if needed
		const searchRender = this.props.searchable ? (
			<input
				style={searchStyle}
				onChange={this.changeSearchText}
				value={this.state.searchText}
			></input>
		) : null;

		let divStyle = {
			display: 'flex',
			flexDirection: 'column',
			maxHeight: '74vh',
			overflowY: 'scroll',
			margin: '0 1vw',
			padding: '0 1vw',
			// backgroundColor: '#f8f8f8',
			borderRadius: '10px',
			whiteSpace: 'nowrap'
		};

		//Apply custom styles on top of defaults
		divStyle = Object.assign(divStyle, this.props.divStyle);
		searchStyle = Object.assign(searchStyle, this.props.searchStyle);
		itemStyle = Object.assign(itemStyle, this.props.itemStyle);
		selectedItemStyle = Object.assign(
			selectedItemStyle,
			this.props.selectedItemStyle
		);

		//Shows searchbar and filtered items
		return (
			<div style={divStyle}>
				{searchRender}
				{filteredItems.map(x => {
					let handler = null;
					let currentItemStyle;

					//Change item style based on if it's selected
					if (x === this.props.selectedItem) {
						currentItemStyle = selectedItemStyle;
					} else {
						currentItemStyle = itemStyle;
						handler = this.props.selectItem.bind(null, x);
					}

					return (
						<p key={x} onClick={handler} style={currentItemStyle}>
							{x}
						</p>
					);
				})}
			</div>
		);
	}
}
