import React from 'react';
import ColorChartGame from './ColorChartGame.js';
import GameSlot from './GameSlot.js';
import { cleanTime } from '../../global/utils.js';

function filteredOut(filter, game) {
	let games = filter.gameID
		.split(',')
		.filter(x => x !== '')
		.map(x => parseInt(x));
	if (games.includes(game.game_id)) return false;
	else if (filter.gameID !== '') return true;

	if (filter.tournamentID.includes(game.tournament_id)) return false;
	else if (filter.tournamentID.length !== 0) return true;

	if (filter.teamID !== null && filter.teamID === game.team1_id) return false;
	if (filter.teamID !== null && filter.teamID === game.team2_id) return false;

	// If all filters are null, show everything
	if (
		filter.gameID === '' &&
		filter.tournamentID.length !== 0 &&
		filter.teamID === null
	) {
		return false;
	}

	return true;
}

/**
 * Displays the schedule of games for a single time
 * @function ColorChartRow
 * @param {Object} props
 * @param {string} props.date - date of game
 * @param {string} props.time - starting time of game
 * @param {Object[]} props.firstRow - each element is props for {@link GameCell} except rowType
 * @param {string[]} props.firstRowLocs - each element is the location for a game
 * @param {Object[]} props.secondRow - each element is props for {@link GameCell} except rowType
 * @param {string[]} props.secondRowLocs - each element is the location for a game
 * @returns {React.Component}
 */
export default function ColorChartRow(props) {
	/* Returns two rows of games
	 */
	return [
		<tr key={`${props.version}${props.date};${props.time};firstrow`}>
			<td style={{ whiteSpace: 'nowrap' }} className="text-center">
				{cleanTime(props.time)}
			</td>
			{props.firstRow.map((x, i) => {
				//Loop over each location in the row. Show a game if there is one
				let gameRender = null;
				let filtered = false;
				if (x !== null) {
					if (props.hideScoredGames) {
						if (x.strike === 1) gameRender = null;
						else
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									filtering={true}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
					} else if (props.summonMode) {
						gameRender = (
							<ColorChartGame
								key={x.game_id}
								{...x}
								summonMode={props.summonMode}
								addToSummonList={props.addToSummonList}
								showScores={props.showScores}
								hideScores={props.hideScores}
							/>
						);
					} else {
						// Check if filter matches
						if (!props.filter.filtering) {
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
						} else if (!filteredOut(props.filter, x)) {
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									filtering={true}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
						} else filtered = true;
					}
				}

				return (
					<GameSlot
						key={`${props.version}${props.date};${props.time};firstrow${i}`}
						rowType={'top'}
						date={props.date}
						time={props.time}
						version={props.version}
						location={props.firstRowLocs[i].location}
						sportsByLoc={props.sportsByLoc}
						updateMessage={props.updateMessage}
						createGameHandler={props.createGameHandler}
						filtered={filtered}
						summonMode={props.summonMode}
					>
						{gameRender}
					</GameSlot>
				);
			})}
		</tr>,
		<tr key={`${props.version}${props.date};${props.time};secondrow`}>
			<td className="text-center">-</td>
			{props.secondRow.map((x, i) => {
				//Loop over each location in the row. Show a game if there is one
				let gameRender = null;
				let filtered = false;
				if (x !== null) {
					if (props.hideScoredGames) {
						if (x.strike === 1) gameRender = null;
						else
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									filtering={true}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
					} else if (props.summonMode) {
						gameRender = (
							<ColorChartGame
								key={x.game_id}
								{...x}
								summonMode={props.summonMode}
								addToSummonList={props.addToSummonList}
								showScores={props.showScores}
								hideScores={props.hideScores}
							/>
						);
					} else {
						// Check if filter matches
						if (!props.filter.filtering) {
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
						} else if (!filteredOut(props.filter, x)) {
							gameRender = (
								<ColorChartGame
									key={x.game_id}
									{...x}
									filtering={true}
									summonMode={props.summonMode}
									showScores={props.showScores}
									hideScores={props.hideScores}
								/>
							);
						} else filtered = true;
					}
				}
				return (
					<GameSlot
						key={`${props.version}${props.date};${props.time};secondrow${i}`}
						rowType={'bottom'}
						date={props.date}
						time={props.time}
						version={props.version}
						location={props.secondRowLocs[i].location}
						sportsByLoc={props.sportsByLoc}
						updateMessage={props.updateMessage}
						createGameHandler={props.createGameHandler}
						filtered={filtered}
						summonMode={props.summonMode}
					>
						{gameRender}
					</GameSlot>
				);
			})}
		</tr>
	];
}
