// import { createAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
	getCurrentUser,
	signUp,
	confirmSignUp,
	signOut
} from 'aws-amplify/auth';

export const authStatus = createAsyncThunk('GET_AUTH_STATUS', checkCurrentUser);
export const signUpAction = createAsyncThunk('SIGN_UP', signUpUser);
export const confirmSignUpAction = createAsyncThunk(
	'CONFIRM_SIGN_UP',
	confirmUser
);
export const loginUser = createAsyncThunk('SIGN_IN', signInUser);
export const signOutAction = createAsyncThunk('SIGN_OUT', signOutUser);
export const switchUserProfile = createAction('SWITCH_USER', switchUser);

async function checkCurrentUser() {
	try {
		let user = await getCurrentUser();
		console.log('Current User', user);
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function signUpUser(userInfo) {
	try {
		let user = await signUp(userInfo);
		console.log(user);
		return Promise.resolve();
	} catch (err) {
		return Promise.reject(err.message);
	}
}

async function confirmUser(confirmInfo) {
	try {
		let user = await confirmSignUp(confirmInfo.email, confirmInfo.code);
		console.log(user);
	} catch (err) {
		console.log(err);
		return Promise.reject(err.message);
	}
}

async function signInUser() {
	try {
		console.log('Signing in...');
		// await Auth.signIn('eyalabadi98@hotmail.com', 'password');
		return Promise.resolve();
	} catch (err) {
		return Promise.reject(err.message);
	}
}

async function signOutUser() {
	try {
		await signOut();
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err.message);
	}
}
// when user sign in / out
function switchUser(user) {
	console.debug('Switching User', user);
	if (!user) {
		return {
			payload: {
				user: null,
				token: null,
				groups: []
			}
		};
	}

	return {
		payload: {
			user: user.name,
			token: user.token,
			groups: user.groups
		}
	};
}
