import React from 'react';
import { Nav } from 'react-bootstrap';

/**
 * Shows a single item in a {@link ToggleSelector}
 * @function ToggleItem
 * @param {Object} props
 * @param {string} props.text - the text to display
 * @param {string} props.selectedItem - the item that is currently selected
 * @param {function} props.selectItem - callback function for selecting the item
 */
export default function ToggleItem(props) {
	return (
		<Nav.Item onClick={props.selectItem}>
			<Nav.Link
				eventKey={props.text}
				style={props.textStyle}
				value={props.text}
				href="#"
			>
				{props.text}
			</Nav.Link>
		</Nav.Item>
	);

	// } else {
	// 	return <p style={unselectedStyle} onClick={props.selectItem}>{props.text}</p>
	// }
}

// export const fakeprops = {
// 	selectedItem: "Ch A Col 1",
// 	text: "Ch A Col 2",
// 	selectItem: () => console.log("clicked")
// };
