import React from 'react';

// Styling
const theaderStyle = {
	textAlign: 'left',
	paddingBottom: '15px',
	fontSize: '15px'
};

export default function RegistrantInfoTableHeader(props) {
	return (
		<thead style={theaderStyle}>
			<tr>
				<th className="text-center" scope="col">
					Registrant ID
				</th>
				<th className="text-center" scope="col">
					Name
				</th>
				<th className="text-center" scope="col">
					Image Accepted
				</th>
				<th className="text-center" scope="col">
					Grade
				</th>
				<th className="text-center" scope="col">
					Gender
				</th>
				<th className="text-center" scope="col">
					Country
				</th>
				<th className="text-center" scope="col">
					Team
				</th>
				<th className="text-center" scope="col">
					Time Stamp
				</th>
			</tr>
		</thead>
	);
}
