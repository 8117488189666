import React from 'react';
import SearchableDropdown from '../general/SearchableDropdown.js';

export default class AddGameForm extends React.Component {
	constructor(props) {
		super(props);

		let initialGameType = props.gameTypes[0].text;
		if (initialGameType === 'Tournament') {
			this.state = {
				gameType: initialGameType,
				tournament: props.tournaments[0].id,
				team1: null,
				team2: null
			};
		} else if (
			initialGameType === 'Semi Final' ||
			initialGameType === 'Final'
		) {
			this.state = {
				gameType: initialGameType,
				tournament: props.tournaments[0].id,
				team1: null,
				team2: null
			};
		} else {
			// Find a tournament with teams
			const tournamentsWithTeams = Object.keys(
				this.props.teamsByTournament
			).map(x => parseInt(x));
			let defaultTournament = this.props.tournaments.filter(x => {
				return tournamentsWithTeams.includes(x.id);
			})[0].id;
			this.setState({
				gameType: initialGameType,
				tournament: parseInt(defaultTournament),
				team1: this.props.teamsByTournament[defaultTournament][0]
					.teamId,
				team2: this.props.teamsByTournament[defaultTournament][1].teamId
			});
		}

		this.selectGameType = this.selectGameType.bind(this);
		this.selectTournament = this.selectTournament.bind(this);
		this.selectTeam1 = this.selectTeam1.bind(this);
		this.selectTeam2 = this.selectTeam2.bind(this);
		this.createGame = this.createGame.bind(this);
		this.cancelGame = this.cancelGame.bind(this);
	}

	selectGameType(gameType) {
		if (gameType === 'Tournament') {
			this.setState({
				gameType: gameType,
				tournament: this.props.tournaments[0].id,
				team1: null,
				team2: null
			});
		} else if (gameType === 'Semi Final' || gameType === 'Final') {
			this.setState({
				gameType: gameType,
				tournament: this.props.tournaments[0].id,
				team1: null,
				team2: null
			});
		} else {
			const tournamentsWithTeams = Object.keys(
				this.props.teamsByTournament
			).map(x => parseInt(x));
			let defaultTournament = this.props.tournaments.filter(x => {
				return tournamentsWithTeams.includes(x.id);
			})[0].id;
			this.setState({
				gameType: gameType,
				tournament: parseInt(defaultTournament),
				team1: this.props.teamsByTournament[defaultTournament][0]
					.teamId,
				team2: this.props.teamsByTournament[defaultTournament][1].teamId
			});
		}
	}

	selectTournament(tournament) {
		if (this.state.gameType === 'Round - 1') {
			this.setState({
				tournament: parseInt(tournament),
				team1: this.props.teamsByTournament[parseInt(tournament)][0]
					.teamId,
				team2: this.props.teamsByTournament[parseInt(tournament)][1]
					.teamId
			});
		} else {
			this.setState({ tournament: parseInt(tournament) });
		}
	}
	selectTeam1(team1) {
		this.setState({ team1: parseInt(team1) });
	}
	selectTeam2(team2) {
		this.setState({ team2: parseInt(team2) });
	}

	createGame() {
		if (this.state.gameType === 'Tournament' && this.state.tournament) {
			let sport = this.props.tournaments.find(
				x => x.id === this.state.tournament
			).sport;
			this.props.createGame({
				tournament_id: this.state.tournament,
				gameType: this.state.gameType,
				team1_id: null,
				team2_id: null,
				text: `T: ${sport}`
			});
		} else if (
			(this.state.gameType === 'Semi Final' ||
				this.state.gameType === 'Final') &&
			this.state.tournament
		) {
			let sport = this.props.tournaments.find(
				x => x.id === this.state.tournament
			).sport;

			let text;
			if (this.state.team1 === null || this.state.team2 === null) {
				if (this.state.gameType === 'Semi Final') text = `S: ${sport}`;
				else text = `F: ${sport}`;
			} else {
				let team1 = this.props.teamsByTournament[
					this.state.tournament
				].find(x => x.teamId === this.state.team1);
				let team2 = this.props.teamsByTournament[
					this.state.tournament
				].find(x => x.teamId === this.state.team2);
				if (this.state.gameType === 'Semi Final') {
					text = `S: ${team1.short} vs. ${team2.short}`;
				} else if (this.state.gameType === 'Final') {
					text = `F: ${team1.short} vs. ${team2.short}`;
				} else {
					text = `${team1.short} vs. ${team2.short}`;
				}
			}
			this.props.createGame({
				tournament_id: this.state.tournament,
				gameType: this.state.gameType,
				team1_id: this.state.team1,
				team2_id: this.state.team2,
				text: text
			});
		} else if (
			this.state.gameType === 'Round - 1' &&
			this.state.tournament &&
			this.state.team1 &&
			this.state.team2
		) {
			let team1 = this.props.teamsByTournament[
				this.state.tournament
			].find(x => x.teamId === this.state.team1);
			let team2 = this.props.teamsByTournament[
				this.state.tournament
			].find(x => x.teamId === this.state.team2);
			let text = `${team1.short} vs. ${team2.short}`;

			this.props.createGame({
				tournament_id: this.state.tournament,
				gameType: this.state.gameType,
				team1_id: this.state.team1,
				team2_id: this.state.team2,
				text: text
			});
		}
	}
	cancelGame() {
		this.props.cancelGameCreation({
			date: this.props.date,
			time: this.props.time,
			location: this.props.location,
			schedule_key: this.props.scheduleKey
		});
	}

	render() {
		let team1Options = [];
		let team2Options = [];

		if (this.state.tournament !== '') {
			if (
				this.props.teamsByTournament.hasOwnProperty(
					this.state.tournament
				)
			) {
				if (this.state.team2 === '') {
					team1Options = this.props.teamsByTournament[
						this.state.tournament
					].map(x => ({ id: x.teamId, text: x.name }));
				} else {
					team1Options = this.props.teamsByTournament[
						this.state.tournament
					]
						.filter(x => x.teamId !== this.state.team2)
						.map(x => ({ id: x.teamId, text: x.name }));
				}

				if (this.state.team1 === '') {
					team2Options = this.props.teamsByTournament[
						this.state.tournament
					].map(x => ({ id: x.teamId, text: x.name }));
				} else {
					team2Options = this.props.teamsByTournament[
						this.state.tournament
					]
						.filter(x => x.teamId !== this.state.team1)
						.map(x => ({ id: x.teamId, text: x.name }));
				}
			} else {
				team1Options = [];
				team2Options = [];
			}

			if (
				this.state.gameType === 'Semi Final' ||
				this.state.gameType === 'Final'
			) {
				team1Options.unshift({ id: -1, text: 'unassigned' });
				team2Options.unshift({ id: -1, text: 'unassigned' });
			}
		}

		let teamDropdownRender = null;
		if (this.state.gameType !== 'Tournament') {
			teamDropdownRender = (
				<div>
					<SearchableDropdown
						itemsList={team1Options}
						selectedItem={this.state.team1}
						selectItem={this.selectTeam1}
						searchable={false}
					/>
					<SearchableDropdown
						itemsList={team2Options}
						selectedItem={this.state.team2}
						selectItem={this.selectTeam2}
						searchable={false}
					/>
				</div>
			);
		}

		let selectableGameTypes = this.props.gameTypes;
		if (team1Options.length === 0 && team2Options.length === 0) {
			selectableGameTypes = selectableGameTypes.filter(
				x => x.text !== 'Round - 1'
			);
		}

		let selectableTournaments = this.props.tournaments;
		if (this.state.gameType === 'Round - 1') {
			const tournamentsWithTeams = Object.keys(
				this.props.teamsByTournament
			).map(x => parseInt(x));
			console.log('Tournaments with teams');
			console.log(tournamentsWithTeams);
			console.log('Selectable tournaments');
			console.log(selectableTournaments);
			selectableTournaments = this.props.tournaments.filter(x => {
				return tournamentsWithTeams.includes(x.id);
			});
		}

		return (
			<div>
				<p>{`Schedule: ${this.props.schedule}`}</p>
				<p>{`Date: ${this.props.date}`}</p>
				<p>{`Time: ${this.props.time}`}</p>
				<p>{`Location: ${this.props.location}`}</p>
				<SearchableDropdown
					itemsList={selectableGameTypes}
					selectedItem={this.state.gameType}
					selectItem={this.selectGameType}
					searchable={false}
				/>
				<SearchableDropdown
					itemsList={selectableTournaments}
					selectedItem={this.state.tournament}
					selectItem={this.selectTournament}
					searchable={false}
				/>
				{teamDropdownRender}
				<button onClick={this.createGame}>Add</button>
				<button onClick={this.cancelGame}>Cancel</button>
			</div>
		);
	}
}
