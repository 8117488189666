import React from 'react';

/**
 * Callback function to accept/reject a score change
 * @name scoreChangeHandler
 * @function
 * @param {int} request_id - unique ID for the score change request
 */

/**
 * Displays an alternate score and the number of people
 * who requested it, allowing you to accept / reject it
 * @function ScoreChangeRequest
 * @param {Object} props
 * @param {int} props.numReports - number of people who submitting this alternate score
 * @param {string} props.alternateScore - score alternative
 * @param {int} props.scoreChangeID - a unique identifier for this alternate score
 * @param {scoreChangeHandler} props.acceptHandler - callback function for when a user
 * chooses to accept the score change request. The scoreChangeID will be the parameter
 * @param {scoreChangeHandler} props.rejectHandler - callback function for when a user
 * chooses to reject the score change request. The scoreChangeID will be the parameter
 * @returns {React.Component}
 */
export default function ScoreChangeRequest(props) {
	const divStyle = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#F9F9F9',
		margin: '3.5% 0',
		borderRadius: '15px',
		padding: '5px',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		justifyContent: 'space-around'
	};

	const numReportsStyle = {
		display: 'flex',
		borderRadius: '50%',
		height: '45px',
		width: '45px',
		backgroundColor: '#1857C3',
		color: 'white',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center'
	};

	const scoreStyle = {
		color: '#1857C3',
		fontSize: props.mode === 'set' ? '150%' : '250%',
		width: '50%',
		margin: '0'
	};

	const greenButtonStyle = {
		padding: '3% 10%',
		margin: '10% -30%',
		width: '130%',
		borderRadius: '20px',
		backgroundColor: '#018001',
		color: '#fff',
		cursor: 'pointer'
	};

	const redButtonStyle = {
		padding: '3% 10%',
		margin: '10% -30%',
		width: '130%',
		borderRadius: '20px',
		backgroundColor: '#FF0000',
		color: '#fff',
		cursor: 'pointer'
	};

	return (
		<div style={divStyle}>
			<div style={numReportsStyle}>
				<p>{props.numReports}</p>
			</div>
			<div style={scoreStyle}>
				<p>{props.scoretext}</p>
			</div>
			<div>
				<div
					style={greenButtonStyle}
					onClick={props.acceptHandler.bind(null, props)}
				>
					Accept
				</div>
				<div
					style={redButtonStyle}
					onClick={props.rejectHandler.bind(null, props)}
				>
					Reject
				</div>
			</div>
		</div>
	);
}

// export const fakeprops = {
// 	numReports: 3,
// 	alternateScore: "7 - 2",
// 	scoreChangeID: 81,
// 	acceptHandler: (id) => console.log(`acceping ${id}`),
// 	rejectHandler: (id) => console.log(`rejcting ${id}`)
// };
