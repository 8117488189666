import React from 'react';
import { connect } from 'react-redux';
import { getunapprovedreg } from '../services/familyinfo/familyinfoActions';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class UnapprovedRegView extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.getUnapprovedReg();
	}

	render() {
		return (
			<Card>
				<Card.Header
					style={{
						fontSize: 30,
						fontWeight: '500',
						color: '#4E73DF'
					}}
				>
					Unapproved Registrants
				</Card.Header>
				<Card.Body>
					<table className="table table-striped">
						<thead>
							<tr>
								<th className="text-center" scope="col">
									MG Registrant ID
								</th>
								<th className="text-center" scope="col">
									Name
								</th>
								<th className="text-center" scope="col">
									Grade
								</th>
								<th className="text-center" scope="col">
									Past Country
								</th>
								<th className="text-center" scope="col">
									Last Year Registration Timestamp
								</th>
								<th className="text-center" scope="col">
									Country
								</th>
								<th className="text-center" scope="col">
									Notes
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.unapprovedRegList.map(x => {
								return (
									<tr>
										<td className="text-center">
											{x.mg_registrant_id}
										</td>
										<td className="text-center">
											<Link
												to="/registrantinfo"
												state={{
													openUser: x.registrant_id
												}}
											>
											<p
												key={x.firstname}
												style={{ color: 'gray' }}
											>
												{x.firstname} {x.lastname}
											</p>
											</Link>
										</td>
										<td className="text-center">
											{x.grade}
										</td>
										<td className="text-center">
											{x.past_country}
										</td>
										<td className="text-center">
											{x.last_year_timestamp}
										</td>
										<td className="text-center">
											{x.country}
										</td>
										<td className="text-center">
											{x.notes}
										</td>

									</tr>
								);
							})}
						</tbody>
					</table>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.familyinfo;
	if (raw.unapprovedReg) {
		return {
			unapprovedRegList: raw.unapprovedReg
		};
	} else return { unapprovedRegList: [] };
};

const mapDispatchToProps = dispatch => {
	return {
		getUnapprovedReg: () => dispatch(getunapprovedreg())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnapprovedRegView);
