import React from 'react';
import TeamScoresRow from '../components/scores/TeamScoresRow.js';
import { connect } from 'react-redux';
import { myCountryScores } from '../services/mycountryscores/mycountryscoresActions';
import { Container, Card } from 'react-bootstrap';

/**
 * @class MyCountryScoresView
 * @classdesc Shows the scores for all teams a user is a captain of
 * @param {Object} props
 * @param {Object[]} props.myCountryScoresData - each element is props for {@link TeamScoresRow}
 * @returns {React.Component}
 */
class MyCountryScoresView extends React.Component {
	componentDidMount() {
		this.props.getScoresData();
	}

	render() {
		if (this.props.myCountryScoresData) {
			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							My Country Scores
						</Card.Header>
						<Card.Body>
							<Container>
								{!this.props.myCountryScoresData[0] && (
									<h2 className="text-center">
										No Scores available for your teams
									</h2>
								)}
								{this.props.myCountryScoresData.map(x => (
									<TeamScoresRow key={x.teamID} {...x} />
								))}
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.mycountryscores;
	if (raw.teams) {
		let scoreToOutput = game => {
			return {
				gameID: game.game_id,
				score: game.score,
				setScore: [`${game.set1} | ${game.set2}`, game.set3],
				//`${game.set1} | ${game.set2} | ${game.set3 === null ? ' - ' : game.set3}`,
				scoreCardTop: {
					sport: game.sport,
					showRachmanusFlag: false,
					showTimer: false,
					timeRemaining: false,
					gameInfo: {
						datetime: `${game.date} | ${game.time}`,
						location: game.location,
						tournament: game.tournament
					}
				},
				scoreCardBottom: {
					team1Icon: {
						imageURL: `assets/countryicons/flag-${game.flag1}.png`,
						label: game.short1
					},
					team2Icon: {
						imageURL: `assets/countryicons/flag-${game.flag2}.png`,
						label: game.short2
					},
					hasSet: game.hasSet === 1
				}
			};
		};
		let teamToOutput = myTeam => {
			return {
				teamID: myTeam.team_id,
				teamInfo: {
					category: myTeam.cc_key,
					backgroundcolor: myTeam.background,
					bordercolor: myTeam.text,
					bold: myTeam.bold === '1',
					team: myTeam.short,
					teamLogoURL: `assets/teamshirts/shirt-${myTeam.shirt.toLowerCase()}.png`
				},
				gameScores: raw.scores
					.filter(game => game.team_id === myTeam.team_id)
					.map(scoreToOutput)
			};
		};
		let groupedData = raw.teams.map(teamToOutput);
		return {
			myCountryScoresData: groupedData
		};
	} else return { myCountryScoresData: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getScoresData: () => dispatch(myCountryScores())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyCountryScoresView);
