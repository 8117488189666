import { createReducer } from '@reduxjs/toolkit';
import {
	subscriptionsData,
	addSubscription,
	deleteSubscription
} from './mysubscriptionsActions.js';

const mysubscriptionsReducer = createReducer({}, builder => {
	builder.addCase(subscriptionsData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(subscriptionsData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			subscriptions: action.payload.subscriptions,
			teamsByCountry: action.payload.teamsByCountry
		};
	});

	builder.addCase(subscriptionsData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(addSubscription.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(addSubscription.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			subscriptions: [...state.subscriptions, action.payload]
		};
	});

	builder.addCase(addSubscription.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(deleteSubscription.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(deleteSubscription.fulfilled.type, (state, action) => {
		const newSubscriptions = state.subscriptions.filter(
			x => x.id !== action.payload
		);

		return {
			...state,
			requestStatus: 'fulfilled',
			subscriptions: newSubscriptions
		};
	});

	builder.addCase(deleteSubscription.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default mysubscriptionsReducer;
