import React from 'react';
import { Row, Col } from 'react-bootstrap';
export default function UnassignedPlayersArea(props) {
	const dropHandler = e => {
		e.preventDefault();
		const cardID = e.dataTransfer.getData('cardID');

		// Check if ID is already in area
		console.log('Props in Unassigned', props);
		console.log('Props in Unassigned e:', e);
		let existingIDs = props.children.props.children.map(x => x.props.id);
		if (!existingIDs.includes(cardID)) {
			props.cardDropHandler(cardID);
		}
	};

	const dragOverHandler = e => e.preventDefault();
	const boardStyle = {
		// border: '2px solid black',
		minHeight: '200px',
		maxHeight: '400',
		overflowY: 'scroll'
	};

	if (props.viewOnly) {
		return (
			<Row>
				<p className="text-center">Unassigned</p>
				<Col>
					<div style={boardStyle}>{props.children}</div>
				</Col>
				<Col sm={1}>
					<p>{props.children.length}</p>
				</Col>
			</Row>
		);
	} else {
		return (
			<Row>
				<p className="text-center">Unassigned</p>
				<Col>
					{/* <p>Participants {props.children.length}</p> */}
					<div
						onDrop={dropHandler}
						onDragOver={dragOverHandler}
						style={boardStyle}
					>
						{props.children}
					</div>
				</Col>
				{/* <Col sm={1}> */}

				{/* </Col> */}
			</Row>
		);
	}
}
