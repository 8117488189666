import React from 'react';
import { getCurrentYear } from '../../global/utils.js';

export default function WelcomePage(props) {
	const mainStylesEmpty = {
		justifyContent: 'center',
		flex: 1,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	};

	return (
		<div style={mainStylesEmpty}>
			<h1>Welcome to the Maccabi Games {getCurrentYear()}</h1>
			<img
				alt="maccabi games logo"
				src="MG24LOGO_no_bg.png"
				style={{ height: '65vh' }}
			/>
			<img
				alt="Eifosoft Logo"
				src="assets/eifosoft-logo.png"
				height="100"
				width="100"
			></img>
			<p>Please select an item from the menu on the left</p>
		</div>
	);
}
