import React from 'react';
import TeamInfo from './TeamInfo.js';
import HorizontalDeck from '../HorizontalDeck.js';
import ScoreCard from './ScoreCard.js';
import { useState, useEffect } from 'react';

/**
 * Displays the scorecards for a whole team
 * @function TeamScoresRow
 * @param {Object} props
 * @param {int} props.teamID - unique ID for each team. Used as key for lists of team scores
 * @param {Object} props.teamInfo - props for {@link TeamInfo}
 * @param {Object[]} props.gameScores - each element in array is props for {@link ScoreCard}
 * @returns {React.Component}
 */
export default function TeamScoresRow(props) {
	const divStyle = {
		display: 'flex',
		flexDirection: 'row',
		// justifyContent: 'center',
		alignItems: 'center',
		minWidth: '680px'
	};

	const { width } = useWindowDimensions();
	console.log('width is', width);

	const doublePlaceholder = {
		// border: '2px solid red',
		display: 'flex',
		flexDirection: 'column',
		width: '805px', //card width
		height: '220px', //card height
		margin: '3.5% 30px',
		borderRadius: '15px',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		marginLeft: '60px',
		alignItems: 'center',
		justifyContent: 'center'
	};

	let numCardsToShow = 2;
	if (width < 1460) {
		numCardsToShow = 1;
	}

	let availableCards = props.gameScores.length;
	if (availableCards === 0) {
		return (
			<div style={divStyle}>
				<TeamInfo {...props.teamInfo} />
				<div style={doublePlaceholder}>
					<p>No scores to show yet.</p>
					<p>Come back soon!</p>
				</div>
			</div>
		);
	} else if (availableCards === 1) {
		return (
			<div style={divStyle}>
				<TeamInfo {...props.teamInfo} />
				<HorizontalDeck cardsToShow={numCardsToShow}>
					{props.gameScores.map(x => (
						<ScoreCard key={x.gameID} {...x} />
					))}
				</HorizontalDeck>
			</div>
		);
	} else {
		return (
			<div style={divStyle}>
				<TeamInfo {...props.teamInfo} />
				<HorizontalDeck cardsToShow={numCardsToShow}>
					{props.gameScores.map(x => (
						<ScoreCard key={x.gameID} {...x} />
					))}
				</HorizontalDeck>
			</div>
		);
	}
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

// const fakeprops = {
//     teamInfo: {
//         backgroundColor: '#66FF33',
//         borderColor: '#000000',
//         bold: false,
//         category: 'Ad Female',
//         team: 'VEN 1',
//         teamLogoURL: 'assets/teamshirts/shirt-ven1.png'
//     },
//     gameScores: [
//         {
//             score: '2 - 1',
//             setScore: '15 - 2 | 13 - 15 15 - 10',
//             scoreCardTop: {
//                 sport: 'Volleyball',
//                 gameInfo: {
//                     datetime: 'Thursday, 11/28 | 09:40 am',
//                     location: 'Hillel - Volleyball Out',
//                     tournament: 'Ch D Male - Volleyball'
//                 },
//                 showRachmanusFlag: false,
//                 showTimer: false,
//                 timeRemaining: false
//             },
//             scoreCardBottom: {
//                 team1Icon: {
//                     label: 'ARG 2',
//                     imageURL: 'assets/countryicons/flag-Argentina.png'
//                 },
//                 team2Icon: {
//                     label: 'VEN 2',
//                     imageURL: 'assets/countryicons/flag-Venezuela.png'
//                 },
//                 hasSet: true
//             }
//         },
//         {
//             score: '6 - 6',
//             setScore: '',
//             scoreCardTop: {
//                 sport: 'Basketball',
//                 gameInfo: {
//                     datetime: 'Sunday, 11/24 | 08:40 am',
//                     location: 'Sinai - Basketball',
//                     tournament: 'Ch A Coed - Basketball'
//                 },
//                 showRachmanusFlag: false,
//                 showTimer: false,
//                 timeRemaining: false
//             },
//             scoreCardBottom: {
//                 team1Icon: {
//                     label: 'BRA 1',
//                     imageURL: 'assets/countryicons/flag-Brazil.png'
//                 },
//                 team2Icon: {
//                     label: 'VEN 1',
//                     imageURL: 'assets/countryicons/flag-Venezuela.png'
//                 },
//                 hasSet: false
//             }
//         },
//         {
//             score: '33 - 17',
//             setScore: '',
//             scoreCardTop: {
//                 sport: 'Basketball',
//                 gameInfo: {
//                     datetime: 'Thursday, 11/28 | 09:40 am',
//                     location: 'Hillel - Basketball Out',
//                     tournament: 'Ch D Male - Basketball'
//                 },
//                 showRachmanusFlag: false,
//                 showTimer: false,
//                 timeRemaining: false
//             },
//             scoreCardBottom: {
//                 team1Icon: {
//                     label: 'ARG 2',
//                     imageURL: 'assets/countryicons/flag-Argentina.png'
//                 },
//                 team2Icon: {
//                     label: 'VEN 2',
//                     imageURL: 'assets/countryicons/flag-Venezuela.png'
//                 },
//                 hasSet: false
//             }
//         },
//         {
//             score: '2 - 0',
//             setScore: '15 - 4 | 15 - 7',
//             scoreCardTop: {
//                 sport: 'Dodgeball',
//                 gameInfo: {
//                     datetime: 'Sunday, 11/24 | 09:40 am',
//                     location: 'Sinai - Kickball',
//                     tournament: 'Ch A Coed - Kickball'
//                 },
//                 showRachmanusFlag: false,
//                 showTimer: false,
//                 timeRemaining: false
//             },
//             scoreCardBottom: {
//                 team1Icon: {
//                     label: 'BRA 1',
//                     imageURL: 'assets/countryicons/flag-Brazil.png'
//                 },
//                 team2Icon: {
//                     label: 'VEN 3',
//                     imageURL: 'assets/countryicons/flag-Venezuela.png'
//                 },
//                 hasSet: true
//             }
//         }
//     ]
// };
