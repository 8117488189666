import React from 'react';
import { connect } from 'react-redux';
import { masterschedulechangelog } from '../services/masterschedulechanges/masterschedulechangesAction';
import { Container, Card } from 'react-bootstrap';
import MasterScheduleInfoRow from '../components/schedule/masterschedulechanges/MasterScheduleInfoRow';

class MasterScheduleChangesView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			masterSchedule: []
		};
	}
	componentDidMount() {
		this.props.getMasterScheduleChanges().then(() => {
			this.setState({ masterSchedule: this.props.masterscheduleChanges });
		});
	}

	render() {
		let theaderStyle = {
			textAlign: 'left',
			paddingBottom: '15px',
			fontSize: '15px'
		};
		return (
			<Card>
				<Card.Header
					style={{
						fontSize: 30,
						fontWeight: '500',
						color: '#4E73DF'
					}}
				>
					Master Schedule Changes
				</Card.Header>
				<Card.Body>
					<Container>
						<table className="table table-striped">
							<thead style={theaderStyle}>
								<tr>
									<th className="text-center" scope="col">
										Change Time
									</th>
									<th className="text-center" scope="col">
										Game ID
									</th>
									<th className="text-center" scope="col">
										Tournament
									</th>
									<th className="text-center" scope="col">
										Team 1 vs. Team 2
									</th>
									<th className="text-center" scope="col">
										Old Date
									</th>
									<th className="text-center" scope="col">
										Old Time
									</th>
									<th className="text-center" scope="col">
										Old Location
									</th>
									<th className="text-center" scope="col">
										New Date
									</th>
									<th className="text-center" scope="col">
										New Time
									</th>
									<th className="text-center" scope="col">
										New Location
									</th>
									<th className="text-center" scope="col">
										Reason
									</th>
									<th className="text-center" scope="col">
										Change Type
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.masterSchedule.map(x => {
									return (
										<MasterScheduleInfoRow
											key={x.change_id}
											{...x}
										/>
									);
								})}
							</tbody>
						</table>
					</Container>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.masterschedulechanges;
	console.log(raw);
	if (raw.masterschedulechangelog) {
		return {
			masterscheduleChanges: raw.masterschedulechangelog
		};
	} else return { masterscheduleChanges: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getMasterScheduleChanges: () => dispatch(masterschedulechangelog())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MasterScheduleChangesView);
