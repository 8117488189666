import { createReducer } from '@reduxjs/toolkit';
import {
	catgendercombos,
	getplayers,
	unassignall,
	assignall,
	assignplayer,
	unassignplayer,
	captaincatgendercombos
} from './teamassignmentsActions';

const teamassignmentsReducer = createReducer({}, builder => {
	builder.addCase(captaincatgendercombos.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(captaincatgendercombos.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			capcatgenderoptions: action.payload.map(x => {
				return {
					...x,
					players: []
				};
			})
		};
	});

	builder.addCase(captaincatgendercombos.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(catgendercombos.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(catgendercombos.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			catgenderoptions: action.payload.map(x => {
				return {
					...x,
					players: []
				};
			})
		};
	});

	builder.addCase(catgendercombos.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getplayers.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getplayers.fulfilled.type, (state, action) => {
		let stateCopy = JSON.parse(JSON.stringify(state));
		let currentSlice;
		if (stateCopy.catgenderoptions) {
			currentSlice = stateCopy.catgenderoptions;
		} else {
			currentSlice = stateCopy.capcatgenderoptions;
		}
		let currentCombo = currentSlice.find(x => {
			let countryMatch = x.country === action.payload.country;
			let catGenderMatch =
				`${x.category} ${x.gender}` === action.payload.catGender;
			return countryMatch && catGenderMatch;
		});

		currentCombo.players = action.payload.players;
		return stateCopy;
	});

	builder.addCase(getplayers.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(unassignall.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(unassignall.fulfilled.type, (state, action) => {
		let stateCopy = JSON.parse(JSON.stringify(state));
		let currentCombo = stateCopy.catgenderoptions.find(x => {
			let countryMatch = x.country === action.payload.country;
			let catGenderMatch =
				`${x.category} ${x.gender}` === action.payload.catGender;
			return countryMatch && catGenderMatch;
		});

		currentCombo.players = action.payload.players;
		return stateCopy;
	});

	builder.addCase(unassignall.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(assignall.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(assignall.fulfilled.type, (state, action) => {
		let stateCopy = JSON.parse(JSON.stringify(state));
		let currentCombo = stateCopy.catgenderoptions.find(x => {
			let countryMatch = x.country === action.payload.country;
			let catGenderMatch =
				`${x.category} ${x.gender}` === action.payload.catGender;
			return countryMatch && catGenderMatch;
		});

		currentCombo.players = action.payload.players;
		return stateCopy;
	});

	builder.addCase(assignall.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(assignplayer.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(assignplayer.fulfilled.type, (state, action) => {
		let stateCopy = JSON.parse(JSON.stringify(state));
		let currentCombo = stateCopy.catgenderoptions.find(x => {
			let countryMatch = x.country === action.payload.country;
			let catGenderMatch =
				`${x.category} ${x.gender}` === action.payload.catGender;
			return countryMatch && catGenderMatch;
		});

		currentCombo.players = action.payload.players;
		return stateCopy;
	});

	builder.addCase(assignplayer.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(unassignplayer.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(unassignplayer.fulfilled.type, (state, action) => {
		let stateCopy = JSON.parse(JSON.stringify(state));
		let currentCombo = stateCopy.catgenderoptions.find(x => {
			let countryMatch = x.country === action.payload.country;
			let catGenderMatch =
				`${x.category} ${x.gender}` === action.payload.catGender;
			return countryMatch && catGenderMatch;
		});

		currentCombo.players = action.payload.players;
		return stateCopy;
	});

	builder.addCase(unassignplayer.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default teamassignmentsReducer;
