import store from '../store/store.js';
import { fetchAuthSession } from '@aws-amplify/auth';
import { switchUserProfile } from '../services/authentication/authActions.js';
import { getMenu, clearMenu } from '../services/menu.js';
import { TableExport } from 'tableexport';
// const https = require('https-browserify');
export const backendURL = process.env.React_App_backendURL
	? process.env.React_App_backendURL
	: 'http://localhost:3001';

export function getCurrentYear() {
	const today = new Date();
	return today.getFullYear();
}

async function unauthorizedHandler(error) {
	if (error.response.status === 401) {
		try {
			const { tokens: session } = await fetchAuthSession();

			const user = {
				name: session.idToken.payload.name,
				token: session.idToken.toString(),
				groups: session.idToken.payload['cognito:groups']
			};
			// If a user exists and is authenticated, add their info to the store
			store.dispatch(switchUserProfile(user));
			store.dispatch(getMenu());
			return user.token;
		} catch (err) {
			// If a user is not authenticated, clear auth slice of store
			store.dispatch(clearMenu());
			store.dispatch(switchUserProfile(null));
			return Promise.reject();
		}
	}
}

export const request = (route, method = 'get', body = {}) => {
	const token = store.getState().auth.user.token;

	if (method === 'get') {
		// const agent = new https.Agent({
		//     rejectUnauthorized: false
		// });
		const reqOptions = {
			method: 'GET',
			data: body,
			headers: {
				Authorization: `Bearer ${token}`
			}
			// httpsAgent: agent
		};
		const url = `${backendURL}/${route}`;
		return fetch(url, reqOptions)
			.catch(unauthorizedHandler)
			.then(result => {
				const status = result.status;
				return result.json().then(data => ({ data, status }));
			});
	} else if (method === 'post') {
		// const agent = new https.Agent({
		//     rejectUnauthorized: false
		// });
		const reqOptions = {
			method: 'post',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
			// httpsAgent: agent
		};
		const url = `${backendURL}/${route}`;

		return fetch(url, reqOptions)
			.catch(unauthorizedHandler)
			.then(result => result.json())
			.then(result => ({ data: result }));
	} else if (method === 'delete') {
		// const agent = new https.Agent({
		//     rejectUnauthorized: false
		// });

		const reqOptions = {
			method: 'delete',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
			// httpsAgent: agent
		};

		const url = `${backendURL}/${route}`;
		return fetch(url, reqOptions)
			.catch(unauthorizedHandler)
			.then(result => result.json())
			.then(result => ({ data: result }));
	} else if (method === 'put') {
		// const agent = new https.Agent({
		//     rejectUnauthorized: false
		// });

		const reqOptions = {
			method: 'put',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
			// httpsAgent: agent
		};

		const url = `${backendURL}/${route}`;
		return fetch(url, reqOptions)
			.catch(unauthorizedHandler)
			.then(result => result.json())
			.then(result => ({ data: result }));
	}
};

export function cleanTime(datestring) {
	const timeIncrements = datestring.split(':');
	let hours = parseInt(timeIncrements[0]);
	const minutes = timeIncrements[1];
	const suffix = hours <= 11 ? 'AM' : 'PM';
	hours = hours % 12;
	hours = hours === 0 ? 12 : hours;
	const time = `${hours}:${minutes} ${suffix}`;
	return time;
}

export function downloadExcel(table_id, sheet_name) {
	let myTableObj = TableExport(document.getElementById(table_id));
	let myTable = myTableObj.getExportData()[table_id].xlsx;
	myTable.data.forEach(x => {
		for (let i = 0; i < x.length; i++) {
			x[i].t = 's';
		}
	});
	myTableObj.export2file(
		myTable.data,
		myTable.mimeType,
		myTable.filename,
		myTable.fileExtension,
		myTable.merges,
		myTable.RTL,
		sheet_name
	);
	myTableObj.remove();
}
