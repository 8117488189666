import { createReducer } from '@reduxjs/toolkit';
import { myCountryScores } from './mycountryscoresActions.js';

const myCountryScoresReducer = createReducer({}, builder => {
	builder.addCase(myCountryScores.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(myCountryScores.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			scores: action.payload ? action.payload.scores : [],
			teams: action.payload ? action.payload.teams : []
		};
	});

	builder.addCase(myCountryScores.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default myCountryScoresReducer;
