import React from 'react';
import GameInfo from './GameInfo.js';
import './ScoreCardStyle.css';

/**
 * Displays the upper half of the scorecard, containing
 * information about the game and relevant information
 * about the score (is there time left to request a change?
 *  did it reach the rachmanus point differential?)
 * @function ScoreCardTop
 * @param {Object} props
 * @param {string} props.sport - the sport for the game
 * @param {Object} props.gameInfo - props for {@link GameInfo}
 * @param {boolean} props.showRachmanusFlag - whether the rachmanus flag should be visible
 * @param {boolean} props.showTimer - whether the timer for score changes should be visible
 * @param {boolean} props.timeRemaining - whether there is time to request score changes
 * @returns {React.Component}
 */
export default function ScoreCardTop(props) {
	let upperRightIconRender;
	let tooltipRender = null;

	const rachflag = {
		width: '35px',
		position: 'absolute',
		right: '15px',
		top: '20px',
		zIndex: '2',
		cursor: 'pointer'
	};

	const toolTip = {
		border: '2px solid black',
		color: 'white',
		backgroundColor: 'black',
		borderRadius: '6px',
		padding: '5px 0',
		position: 'absolute',
		top: '10px',
		left: '10px',
		zIndex: '3',
		overflow: 'visible',
		fontSize: '76%',
		width: '80%'
	};

	if (!props.showRachmanusFlag && !props.showTimer) {
		upperRightIconRender = null;
	} else if (props.showRachmanusFlag) {
		upperRightIconRender = (
			<img
				style={rachflag}
				alt="Rachmanus flag"
				className="flag"
				src="assets/icons/rachmanus_flag.png"
			/>
		);
		tooltipRender = (
			<p
				style={toolTip}
				className="mytooltip"
			>{`The difference between the winning score and the losing score exceeded
			our Rachmanus Point Differential Cap. As such, this game contributes +${props.rachmanusPD}
			PD to the winning team and -${props.rachmanusPD} PD to the losing team.`}</p>
		);
	} else {
		if (props.timeRemaining) {
			upperRightIconRender = (
				<img
					alt="Timer: time remaining"
					src="assets/icons/greenclock.png"
				/>
			);
		} else {
			upperRightIconRender = (
				<img alt="Timer: none left" src="assets/icons/redclock.png" />
			);
		}
	}

	const divStyle = {
		position: 'relative',
		overflow: 'hidden',
		height: '100px'
	};

	const imgStyle = {
		height: '110px',
		position: 'absolute',
		top: '-35px',
		left: '-35px'
	};

	return (
		<div style={divStyle}>
			<img
				style={imgStyle}
				alt="sporticon"
				src={`assets/sporticons/ball-${props.sport
					.replace(' ', '-')
					.toLowerCase()}.png`}
			/>
			<GameInfo {...props.gameInfo} />
			{upperRightIconRender}
			{tooltipRender}
		</div>
	);
}

// const fakeprops = {
// 	sport: 'Volleyball',
// 	gameInfo: {
// 		datetime: 'Thursday, 11/28 | 09:40 am',
// 		location: 'Hillel - Volleyball Out',
// 		tournament: 'Ch D Male - Volleyball'
// 	},
// 	showRachmanusFlag: false,
// 	showTimer: false,
// 	timeRemaining: false
// };
