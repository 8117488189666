import React from 'react';
import { DynamicTableView } from '../components/general/DynamicTable';
import { connect } from 'react-redux';
import { scorechangelog } from '../services/scorechanges/scorechangesActions.js';

class MasterScoreChangeView extends React.Component {
	componentDidMount() {
		this.props.getMasterScoreChanges();
	}

	render() {
		if (this.props.scoreChanges) {
			return (
				<DynamicTableView
					data={this.props.scoreChanges}
					name="masterscorechanges"
				/>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.scorechanges;
	if (raw.scorechangelog) {
		return {
			scoreChanges: raw.scorechangelog
		};
	} else return { scoreChanges: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getMasterScoreChanges: () => dispatch(scorechangelog())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MasterScoreChangeView);
