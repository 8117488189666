import React from 'react';
import { connect } from 'react-redux';
import { masterSchedule } from '../services/schedule/masterscheduleActions.js';
import StaticSchedule from '../components/schedule/StaticSchedule.js';
import ToggleSelector from '../components/general/ToggleSelector.js';

/**
 * Represents the schedule of games and the date
 * @typedef DailySchedule
 * @type {Object}
 * @property {Object[]} schedule - props for {@link StaticSchedule}
 * @property {string} date - The date corresponding to the schedule
 */

/**
 * @class MasterScheduleView
 * @classdesc Shows the overall Maccabi Games tournament schedule
 * @param {Object} props
 * @param {Object[]} props.masterSchedule - each object is a {@link DailySchedule} object [redux]
 * @param {string[]} props.firstRow - each element is a field location (first row of timeslot) [redux]
 * @param {string[]} props.secondRow - each element is a field location (second row of timeslot) [redux]
 * @param {function} props.getMasterSchedule - load data for schedule into redux store [redux]
 * @returns {React.Component}
 */
class MasterScheduleView extends React.Component {
	constructor() {
		super();

		this.state = {
			//Date for which the daily schedule is shown
			selectedDate: ''
		};

		//Bind instance methods
		this.selectDate = this.selectDate.bind(this);
	}

	componentDidMount() {
		//Load schedule data
		this.props.getMasterSchedule().then(() => {
			this.setState({
				selectedDate: this.props.masterSchedule[0].date
			});
		});
	}

	//Select date that you want to see the schedule for
	selectDate(event) {
		this.setState({ selectedDate: event.target.innerHTML });
	}

	render() {
		if (this.props.masterSchedule) {
			if (this.props.masterSchedule.length == 0) {
				return <p>No Schedule is Available</p>;
			}
			//Get all unique dates
			let dateOptions = this.props.masterSchedule.map(x => x.date);

			let scheduleRender;
			if (this.state.selectedDate === '') scheduleRender = null;
			else {
				//Get schedule display for currently selected date
				let daySchedule = this.props.masterSchedule.find(
					x => x.date === this.state.selectedDate
				);
				scheduleRender = (
					<StaticSchedule
						schedule={daySchedule.schedule}
						firstRow={this.props.firstRow}
						secondRow={this.props.secondRow}
						date={this.state.selectedDate}
					/>
				);
			}

			/* Display a selectable list of dates and the
			schedule for the selected date */
			return (
				<div>
					<ToggleSelector
						itemsList={dateOptions}
						selectionObj={{
							selectedItem: this.state.selectedDate,
							selectItem: this.selectDate
						}}
					/>
					{scheduleRender}
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.masterschedule;
	if (raw)
		return {
			masterSchedule: raw.schedule,
			firstRow: raw.firstRow,
			secondRow: raw.secondRow
		};
	else return { masterSchedule: null, firstRow: null, secondRow: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getMasterSchedule: () => dispatch(masterSchedule())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterScheduleView);
