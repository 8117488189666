import { createReducer } from '@reduxjs/toolkit';
import { masterSchedule, refschedule } from './masterscheduleActions.js';

const masterScheduleReducer = createReducer({}, builder => {
	builder.addCase(masterSchedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(masterSchedule.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			schedule: action.payload.schedule,
			firstRow: action.payload.firstRow,
			secondRow: action.payload.secondRow
		};
	});

	builder.addCase(masterSchedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(refschedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(refschedule.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			refschedule: action.payload
		};
	});

	builder.addCase(refschedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default masterScheduleReducer;
