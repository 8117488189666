import React from 'react';
import MasterStandingsView from '../../pages/MasterStandingsView.js';
import PoolsView from '../../pages/PoolsView.js';
import ControlPanelView from '../../pages/ControlPanelView';
import MasterSchedule from '../../pages/MasterScheduleView';
import ManagePeopleView from '../../pages/ManagePeopleView';
import MySubscriptionsView from '../../pages/MySubscriptionsView';
import BroadcastView from '../../pages/BroadcastView';
import EventCenterView from '../../pages/EventCenterView';
import FAQView from '../../pages/FAQView';
import GroupScheduleChangesView from '../../pages/GroupScheduleChangesView';
import MyCountryScoresView from '../../pages/MyCountryScoresView';
import MasterScoreChangeView from '../../pages/MasterScoreChangeView';
import RedYellowCardView from '../../pages/RedYellowCardView';
import RulebookView from '../../pages/RulebookView';
import ScoreChangeRequestView from '../../pages/ScoreChangeRequestView';
import TeamSchedulesView from '../../pages/TeamSchedulesView';
import UntieTeamsView from '../../pages/UntieTeamsView';
import TeamAssignmentView from '../../pages/TeamAssignmentView';
import AdminScheduleView from '../../pages/AdminScheduleView';
import FamilyInfoView from '../../pages/FamilyInfoView';
import RegistrantInfoView from '../../pages/RegistrantInfoView';
import AwardMedalsView from '../../pages/AwardMedalsView.js';
import CaptainTeamAssignmentView from '../../pages/CaptainTeamAssignmentView';
import AdminTeamScheduleView from '../../pages/AdminTeamScheduleView';
import MasterScheduleChangesView from '../../pages/MasterScheduleChangesView.js';
import CaptainManagePeopleView from '../../pages/CaptainManagePeopleView.js';
import RefScheduleChangesView from '../../pages/RefScheduleChangesView.js';
import RefScheduleView from '../../pages/RefScheduleView.js';
import StaffManagePeopleView from '../../pages/StaffManagePeopleView.js';
import RegistrantChangesView from '../../pages/RegistrantChangesView.js';
import ManageSiteDirectorView from '../../pages/ManageSiteDirectorView.js';
import UploadPhotoPortal from '../../pages/UploadPhotoPortal.js';
import DatabaseInfoView from '../../pages/DatabaseInfoView.js';
import UnapprovedRegView from '../../pages/UnapprovedRegView.js';
import BackToBackView from '../../pages/BackToBackView.js';
import NewFeatReqView from '../../pages/NewFeatReqView.js';
import TeamSchedulesListView from '../../pages/TeamSchedulesListView.js';
import CloudApiDocsView from '../../pages/CloudApiDocsView.js';

export const pageMapper = {
	broadcast: { component: <BroadcastView /> },
	colorchart: { component: <AdminScheduleView /> },
	controlpanel: { component: <ControlPanelView /> },
	eventcenter: { component: <EventCenterView /> },
	faq: { component: <FAQView /> },
	groupschedchange: { component: <GroupScheduleChangesView /> },
	managepeople: { component: <ManagePeopleView /> },
	staffmanagepeople: { component: <StaffManagePeopleView /> },
	masterschedule: { component: <MasterSchedule /> },
	masterstandings: { component: <MasterStandingsView /> },
	managesitedirectors: { component: <ManageSiteDirectorView /> },
	mycountryscores: { component: <MyCountryScoresView /> },
	mysubscriptions: { component: <MySubscriptionsView /> },
	redyellowcard: { component: <RedYellowCardView /> },
	pools: { component: <PoolsView /> },
	rulebook: { component: <RulebookView /> },
	scorechangerequests: { component: <ScoreChangeRequestView /> },
	teamassignment: { component: <TeamAssignmentView /> },
	untieteams: { component: <UntieTeamsView /> },
	masterscorechanges: { component: <MasterScoreChangeView /> },
	familyinfo: { icon: 'users', component: <FamilyInfoView /> },
	registrantinfo: { icon: 'user-check', component: <RegistrantInfoView /> },
	awardmedals: { component: <AwardMedalsView /> },
	captainteamassignment: { component: <CaptainTeamAssignmentView /> },
	teamschedules: { component: <TeamSchedulesView /> },
	adminteamschedule: { component: <AdminTeamScheduleView /> },
	masterschedulechanges: { component: <MasterScheduleChangesView /> },
	captainmanagepeople: { component: <CaptainManagePeopleView /> },
	refschedulechanges: { component: <RefScheduleChangesView /> },
	refschedule: { component: <RefScheduleView /> },
	registrantchanges: { component: <RegistrantChangesView /> },
	uploadphoto: { component: <UploadPhotoPortal /> },
	databaseinfo: { component: <DatabaseInfoView /> },
	unapprovedreg: { component: <UnapprovedRegView /> },
	backtoback: { component: <BackToBackView /> },
	newfeatreq: { component: <NewFeatReqView /> },
	teamscheduleslist: { component: <TeamSchedulesListView /> },
	cloudapiinfo: { component: <CloudApiDocsView /> }
};
