import React from 'react';

/**
 * Displays a booking slot for a team/location
 * @function SiteDirectorCell
 * @param {Object} props
 * @param {boolean} props.active - whether cell requires a sitedirector or not
 * @param {string} props.sitedirector - the sitedirector currently assigned to this cell
 * @param {boolean} props.curSelected - whether the cell is currently selected
 * 										(the active site director is assigned to this cell)
 * @param {function} props.clickHandler - function to call when an active cell is clicked
 * @returns {React.Component}
 */
export default function SiteDirectorCell(props) {
	let cellStyle = {
		border: '1px solid grey',
		borderCollapse: 'collapse'
	};

	if (props.curSelected) cellStyle.backgroundColor = '#d1e7dd';

	if (!props.active) {
		// Grey out cell if it is inactive
		cellStyle.backgroundColor = '#d3d3d4';
		return <td style={cellStyle}>{props.sitedirector}</td>;
	} else {
		// Have click handler if cell is active
		if (props.activeSiteDirector !== '') cellStyle.cursor = 'pointer';
		return (
			<td style={cellStyle} onClick={props.clickHandler}>
				{props.sitedirector}
			</td>
		);
	}
}
