import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';
const d3 = require('d3-array');

export const subscriptionsData = createAsyncThunk(
	'GET_MY_SUBSCRIPTIONS',
	getSubscriptions
);
export const addSubscription = createAsyncThunk(
	'ADD_SUBSCRIPTION',
	addNewSubscription
);
export const deleteSubscription = createAsyncThunk(
	'DELETE_SUBSCRIPTION',
	removeSubscription
);

async function addNewSubscription(obj) {
	try {
		await request('subscription/me', 'post', { teamId: obj.id });
		return Promise.resolve(obj);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function removeSubscription(id) {
	try {
		await request('subscription/me', 'delete', { teamId: id });
		return Promise.resolve(id);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getSubscriptions() {
	try {
		let subscriptionsRaw = await request('subscription/me');
		let allTeams = await request('tournament/team/teams');
		let teamsByCountry = Object.fromEntries(
			d3.group(allTeams.data.content, x => x.country)
		);
		let subscriptions = subscriptionsRaw.data.content.map(x => {
			return { id: x.team_id, team_name: x.name };
		});
		return Promise.resolve({
			teamsByCountry: teamsByCountry,
			subscriptions: subscriptions
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
