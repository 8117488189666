import React from 'react';
import { connect } from 'react-redux';
import {
	familyinfo,
	updatefamilyinfo,
	unregisterfamily,
	createFamilyCard,
	updatemergefamily
} from '../services/familyinfo/familyinfoActions';
import FamilyInfoRow from '../components/logistics/FamilyInfoRow';
import EditFamilyArea from '../components/logistics/EditFamilyArea';
import FamilyCard from '../components/logistics/FamilyCard';
import ToggleSelector from '../components/general/ToggleSelector.js';
import {
	Button,
	Card,
	Form,
	Container,
	Row,
	Col,
	Spinner
} from 'react-bootstrap';

const d3 = require('d3-array');

class FamilyInfoView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			registration_id: '',
			paidFilter: 'All',
			searchText: '',
			loadingFamilyCard: false,
			familyCardModal: false,
			switchStatus: false,
			mergeFamilies: []
		};

		this.editFamily = this.editFamily.bind(this);
		this.saveFamily = this.saveFamily.bind(this);
		this.unregisterFamily = this.unregisterFamily.bind(this);
		this.cancelEditFamily = this.cancelEditFamily.bind(this);
		this.selectPaidFilter = this.selectPaidFilter.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.googleSuccess = this.googleSuccess.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.onSwitchAction = this.onSwitchAction.bind(this);
		this.mergeFamily = this.mergeFamily.bind(this);
		this.saveMerge = this.saveMerge.bind(this);
	}

	componentDidMount() {
		this.props.getFamilyInfo();
	}

	selectPaidFilter(event) {
		console.log('Paid Filter', event);
		this.setState({
			paidFilter: event.target.innerHTML
		});
	}

	unregisterFamily(registrationID) {
		this.props.unregisterFamily(registrationID);
		this.setState({
			registration_id: ''
		});
	}

	editFamily(registrationID) {
		this.setState({
			registration_id: registrationID
		});
	}

	cancelEditFamily() {
		this.setState({
			registration_id: ''
		});
	}

	toggleModal() {
		this.setState({
			familyCardModal: !this.state.familyCardModal
		});
	}

	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
	};
	scrollToTop = () => {
		this.messagesStart.scrollIntoView({ behavior: 'smooth' });
	};

	saveFamily(newFamilyInfo) {
		this.props.updateFamilyInfo(newFamilyInfo);

		this.setState({
			registration_id: ''
		});
	}

	async saveMerge() {
		//need to call backend func to merge
		//this.props.updateMergeFamilyInfo(mergeFamilies list)

		console.log('Merging Families: ', this.state.mergeFamilies);

		let responseStatus = null;

		if (this.state.mergeFamilies.length != 0) {
			responseStatus = await this.props.updateMergeFamily(
				this.state.mergeFamilies
			);
			// while (responseStatus === null){
			// 	return <Spinner animation="border" role="status">
			// 		<span className="visually-hidden">Loading...</span>
			// 	</Spinner>
			// }

			console.log('respstatus: ', responseStatus);
			console.log('Respstatus: ', responseStatus.payload.data.content);
			if (responseStatus.payload.data.content === 'true') {
				alert('Merge Families Success');
				this.props.getFamilyInfo();
			} else {
				alert('Merge Families Unsuccessful. Please try again');
			}
		} else {
			alert('Select Families to Merge');
		}

		this.setState({
			mergeFamilies: [],
			switchStatus: false
		});

		// this.setState({
		// 	mergeFamilies.push()
		// });
	}

	googleSuccess(token) {
		if (this.state.firstFamily && this.state.lastFamily) {
			this.setState({ loadingFamilyCard: true });
			this.props
				.createFamilyCard({
					authentication: token,
					first: this.state.firstFamily,
					last: this.state.lastFamily
				})
				.then(raw => {
					let result = raw.payload.response.result;
					console.log('URL open', result);
					this.setState({ loadingFamilyCard: false });
					this.toggleModal();
					window.open(result);
				})
				.catch(error => {
					this.toggleModal();
					console.log('Error in script', error);
				});
		} else {
			alert(
				'Please enter a value for the First Family Number and the Last Family Number'
			);
		}
	}

	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	onSwitchAction() {
		if (!this.state.switchStatus) {
			this.setState(
				{
					mergeFamilies: []
				},
				console.log(
					'Cleaning merge Families: ',
					this.state.mergeFamilies
				)
			);
		}
		this.setState({
			switchStatus: !this.state.switchStatus
		});
	}

	mergeFamily(familyID) {
		if (this.state.mergeFamilies.includes(familyID)) {
			console.log('uncheck');
			let filteredArray = this.state.mergeFamilies.filter(
				item => item != familyID
			);
			this.setState(
				{
					mergeFamilies: filteredArray
				},
				() => {
					console.log('Clean MF', this.state.mergeFamilies);
				}
			);
		} else {
			this.setState(
				{
					mergeFamilies: [...this.state.mergeFamilies, familyID]
				},
				() => {
					console.log('MF', this.state.mergeFamilies);
				}
			);
		}
	}

	render() {
		let editAreaRender = null;
		let familyCardRender = null;
		if (this.state.registration_id !== '') {
			let currentFamily = this.props.familiesList.find(
				x => x.registrationId === this.state.registration_id
			);
			editAreaRender = (
				<EditFamilyArea
					{...currentFamily}
					key={currentFamily.registrationId}
					unregisterFamily={this.unregisterFamily}
					saveFamily={this.saveFamily}
					cancelHandler={this.cancelEditFamily}
				/>
			);
		}

		if (this.state.familyCardModal) {
			familyCardRender = (
				<FamilyCard
					googleSuccess={this.googleSuccess}
					changeHandler={this.changeHandler}
					toggleModal={this.toggleModal}
					loading={this.state.loadingFamilyCard}
				/>
			);
		}

		if (this.props.familiesList) {
			let familiesToShow = this.props.familiesList;

			if (this.state.paidFilter === 'Paid') {
				familiesToShow = familiesToShow.filter(x => x.hasPaid === 1);
			} else if (this.state.paidFilter === 'Unpaid') {
				familiesToShow = familiesToShow.filter(x => x.hasPaid === 0);
			}

			if (this.state.searchText !== '') {
				let text = this.state.searchText.toLowerCase();
				familiesToShow = familiesToShow.filter(post => {
					const name = post.firstName.toLowerCase();
					const lastname = post.lastName.toLowerCase();
					const familyId = post.familyId.toString();
					const registrationId = post.registrationId.toLowerCase();
					return (
						`${name} ${lastname}`.includes(text) ||
						registrationId.includes(text) ||
						familyId.includes(text)
					);
				});
			}

			let toggleStyle = {
				display: 'flex',
				justifyContent: 'space-between',
				paddingBottom: '30px',
				minWidth: '900px',
				paddingTop: '30px'
			};

			let mergeModeStyle = {
				paddingTop: '15px',
				paddingBottom: '15px'
			};

			let theaderStyle = {
				textAlign: 'left',
				paddingBottom: '15px',
				fontSize: '15px'
			};
			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Families
						</Card.Header>
						<Card.Body>
							<Container>
								<Row>
									<Col
										style={{
											disply: 'flex',
											justifyContent: 'left'
										}}
									>
										<Form noValidate>
											<Form.Group
												className="mb-1"
												style={{ width: '50%' }}
											>
												<Form.Control
													className="me-sm-2"
													placeholder="Search"
													aria-label=""
													aria-describedby="basic-addon1"
													value={
														this.state.searchText
													}
													onChange={this.changeHandler.bind(
														null,
														'searchText'
													)}
												/>
											</Form.Group>
										</Form>
									</Col>
									<Col
										style={{
											disply: 'flex',
											justifyContent: 'end'
										}}
									>
										<Button
											variant="outline-primary ml-auto"
											onClick={this.toggleModal.bind(
												this
											)}
											style={{ float: 'right' }}
										>
											Print Family Cards
										</Button>
										<Button
											variant="outline-primary ml-auto"
											onClick={this.scrollToBottom.bind(
												this
											)}
											style={{ float: 'right' }}
										>
											Scroll to Bottom
										</Button>
									</Col>
								</Row>
							</Container>

							<ToggleSelector
								style={toggleStyle}
								itemsList={['All', 'Unpaid', 'Paid']}
								selectionObj={{
									selectedItem: this.state.paidFilter,
									selectItem: this.selectPaidFilter
								}}
							/>
							<Form.Check
								style={mergeModeStyle}
								type="switch"
								id="custom-switch"
								label="Merge Mode"
								checked={this.state.switchStatus}
								onChange={this.onSwitchAction}
							/>
							{/* {this.state.switchStatus && <p>Merging {this.state.mergeFamilies}</p>} */}
							{this.state.switchStatus && (
								<Button
									variant="primary"
									onClick={this.saveMerge}
								>
									Save Merge
								</Button>
							)}
							<div
								style={{ float: 'left', clear: 'both' }}
								ref={el => {
									this.messagesStart = el;
								}}
							></div>
							<table class="table table-striped">
								<thead style={theaderStyle}>
									<tr>
										{this.state.switchStatus && (
											<th class="text-center" scope="col">
												Merge
											</th>
										)}

										<th class="text-center" scope="col">
											Family ID
										</th>
										{/* <th>Registration ID</th> */}
										<th class="text-center" scope="col">
											Name
										</th>
										<th class="text-center" scope="col">
											Email
										</th>
										<th class="text-center" scope="col">
											Phone
										</th>
										<th class="text-center" scope="col">
											Time Stamp
										</th>
									</tr>
								</thead>
								<tbody>
									{familiesToShow.map(x => {
										//check whether x is in mergeFamilyList, pass in boolean to child
										let showCheck = false;
										if (
											this.state.mergeFamilies.includes(
												x.familyId
											)
										) {
											console.log('Show Check');
											showCheck = true;
										}
										return (
											<FamilyInfoRow
												checked={showCheck}
												key={x.registrationId}
												{...x}
												editHandler={this.editFamily}
												switchStatus={
													this.state.switchStatus
												}
												mergeFamily={this.mergeFamily}
											/>
										);
									})}
								</tbody>
							</table>
							<div
								style={{ float: 'left', clear: 'both' }}
								ref={el => {
									this.messagesEnd = el;
								}}
							></div>
							<Button
								variant="outline-primary ml-auto"
								onClick={this.scrollToTop.bind(this)}
								style={{ float: 'right' }}
							>
								Scroll to Top
							</Button>
						</Card.Body>
					</Card>

					{editAreaRender}
					{familyCardRender}
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.familyinfo;
	if (raw.families) {
		const familyMapper = ([key, value]) => {
			return {
				registrationId: key,
				familyName: `${value[0].firstname} ${value[0].lastname}`,
				familyId: value[0].mg_fam_id,
				firstName: value[0].firstname,
				lastName: value[0].lastname,
				email: value[0].email,
				phone: value[0].phonenumber,
				isMember: value[0].is_member,
				memberNumber: value[0].member_number,
				hasPaid: value[0].has_paid,
				registrants: value.map(x => ({
					name: x.registrant_name,
					registrantId: x.registrant_id
				})),
				totalCost: value[0].total_cost,
				timeStamp: value[0].timestamp
			};
		};
		let groupedData = Array.from(
			d3.group(raw.families, x => x.registration_id)
		).map(familyMapper);
		return {
			familiesList: groupedData
		};
	} else return { familiesList: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getFamilyInfo: () => dispatch(familyinfo()),
		updateFamilyInfo: info => dispatch(updatefamilyinfo(info)),
		unregisterFamily: registrationID =>
			dispatch(unregisterfamily(registrationID)),
		createFamilyCard: ({ authentication, first, last }) => {
			return dispatch(createFamilyCard({ authentication, first, last }));
		},
		updateMergeFamily: info => dispatch(updatemergefamily(info))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyInfoView);
