import React, { Component } from 'react';
import './multiselect.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default class MultiSelectCollapsible extends Component {
	constructor(props) {
		super(props);
		this.state = { options: [] };

		this.toggleGroupExpanded = this.toggleGroupExpanded.bind(this);

		this.changeHandler = this.changeHandler.bind(this);
	}

	componentDidMount() {
		this.setState({
			options: this.props.options
		});
	}

	toggleGroupExpanded(group) {
		let currentState = JSON.parse(JSON.stringify(this.state.options));
		let nextState = currentState;
		let currentExpanded = nextState.find(x => x.label === group).expanded;
		nextState.find(x => x.label === group).expanded = !currentExpanded;

		this.setState({ options: nextState });
	}

	changeHandler(x) {
		let currentState = JSON.parse(JSON.stringify(this.state.options));
		let nextState = currentState;
		nextState.forEach(x => {
			x.expanded = false;
		});

		this.setState({ options: nextState });

		this.props.onChange(x);
	}

	render() {
		let selectedTeamObjects = [];
		for (const i in this.props.options) {
			const countryTeams = this.props.options[i];
			const selectedTeamsFromCountry = countryTeams.options.filter(x =>
				this.props.selectedTeams.includes(x.value)
			);
			selectedTeamObjects.push(...selectedTeamsFromCountry);
		}

		let Group = props => {
			const { children, className, cx, getStyles, label } = props;
			const expanded = props.data.expanded;
			return (
				<div
					css={getStyles('group', props)}
					className={cx({ group: true }, className)}
				>
					<div>
						<div
							width="100%"
							onClick={() =>
								this.toggleGroupExpanded(props.label)
							}
						>
							{expanded ? (
								<FontAwesomeIcon
									className="checkbox-select-group-caret"
									icon={faCaretDown}
								/>
							) : (
								<FontAwesomeIcon
									className="checkbox-select-group-caret"
									icon={faCaretRight}
								/>
							)}
							{label}
						</div>
					</div>

					<div style={{ display: expanded ? 'block' : 'none' }}>
						{children}
					</div>
				</div>
			);
		};

		let sortedCountryOptions = JSON.parse(
			JSON.stringify(this.state.options)
		);
		sortedCountryOptions.sort((a, b) => (a.label < b.label ? -1 : 1));

		return (
			<div className="multiselect">
				<Select
					isMulti={true}
					classNamePrefix="gm"
					options={sortedCountryOptions}
					value={selectedTeamObjects}
					dropdownButton={'value'}
					onChange={this.changeHandler}
					components={{ Group: Group }}
					getDropdownButtonLabel={({
						placeholderButtonLabel,
						value
					}) => {
						if (!value) {
							return placeholderButtonLabel;
						}
						if (Array.isArray(value)) {
							if (value.length === 0) {
								return placeholderButtonLabel;
							}
							if (value.length === 1) {
								return value[0].label;
							}
							return ''.concat(value.length, ' selected');
						}
						return value.label;
					}}
				/>
			</div>
		);
	}
}
