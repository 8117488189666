import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
/**
 * Selection screen for which categories an event will be made out to
 * @function SetEvent
 * @param {Object} props
 * @param {string} props.eventTitle - the title of the event
 * @param {function} props.editEventTitle - function to change the title
 * @param {string} props.eventLocation - the location of the event
 * @param {function} props.editEventLocation - function to change the location
 * @param {string} props.eventDate - the date of the event
 * @param {function} props.editEventDate - function to change the date
 * @param {string} props.startTime - the start time of the event
 * @param {function} props.editStartTime - function to change the start time
 * @param {integer} props.duration - the duration of the event
 * @param {function} props.editDuration - function to change the duration
 * @returns {React.Component}
 */

export default function SetEvent(props) {
	return (
		<div>
			<Form.Group className="mb-3">
				<Form.Label>Event Title</Form.Label>
				<Form.Control
					type="text"
					value={props.eventTitle}
					onChange={props.editEventTitle}
				/>
			</Form.Group>

			<Form.Group className="mb-3">
				<Form.Label>Event Location</Form.Label>
				<Form.Control
					type="text"
					value={props.eventLocation}
					onChange={props.editEventLocation}
				/>
			</Form.Group>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Event Date</Form.Label>
						<Form.Control
							type="date"
							value={props.eventDate}
							onChange={props.editEventDate}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Start Time</Form.Label>
						<Form.Control
							type="time"
							value={props.startTime}
							onChange={props.editStartTime}
							step="600"
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Duration (mins)</Form.Label>
						<Form.Control
							type="number"
							value={props.duration}
							onChange={props.editDuration}
							min="0"
							step="10"
						/>
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
}
