import React from 'react';
import { connect } from 'react-redux';
import {
	registrantsinfo,
	updateregistrantinfo,
	unregisterplayer,
	downloadRegistrantRecords,
	getUsersAdVetSportList,
	putUsersAdVetSportList,
	getUsersAddonList,
	putUsersAddonList
} from '../services/familyinfo/familyinfoActions';
import EditRegistrantArea from '../components/logistics/EditRegistrantArea';
import { withLocation } from '../containers/routerUtils';
import { Button, Container, Row, Col, Form, Card } from 'react-bootstrap';
import { getRegistrantTableRender } from '../utils/registrantInfoViewUtils.js';

class RegistrantInfoView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			registrantID: '',
			selectedCountry: 'select',
			selectedCategoryGender: 'select',
			selectedTeam: 'select',
			searchText: '',
			userAdvet: [],
			userAddon: [],
			validPhotos: false
		};

		this.editRegistrant = this.editRegistrant.bind(this);
		this.saveRegistrant = this.saveRegistrant.bind(this);
		this.unregisterPlayer = this.unregisterPlayer.bind(this);
		this.cancelEditRegistrant = this.cancelEditRegistrant.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.changeAdVetSports = this.changeAdVetSports.bind(this);
		this.changeAddons = this.changeAddons.bind(this);
		this.togglePhotoFilter = this.togglePhotoFilter.bind(this);
	}

	componentDidMount() {
		this.props.getRegistrantInfo().then(() => {
			this.setState({
				registrantsToShow: this.props.registrantsList
			});
			if (this.props.location.state) {
				this.editRegistrant(this.props.location.state.openUser);
			}
		});
	}

	unregisterPlayer(registrantID) {
		let result = window.confirm(
			'Are you sure you want to unregister this player?'
		);
		if (result) {
			this.props.unregisterPlayer(registrantID);
			this.setState({
				registrantID: ''
			});
		}
	}

	downloadAllRecords() {
		this.props.downloadRegistrantRecords();
	}

	editRegistrant(registrantID) {
		this.props.getUsersAdVetSport(registrantID).then(response => {
			// console.log("User Advet Sport", response.payload)
			this.setState({
				userAdvet: response.payload
			});
		});
		this.props.getUsersAddon(registrantID).then(response => {
			console.log('User Addon Sport', response);
			this.setState({
				userAddon: response.payload
			});
		});
		this.setState({
			registrantID: registrantID
		});
	}

	modifyAdVetSport() {
		let registrantId = this.state.registrantID;
		this.props.putUsersAdVetSport({
			registrantId,
			sports: this.state.userAdvet
		});
	}

	modifyAddon() {
		let registrantId = this.state.registrantID;
		this.props.putUsersAddon({
			registrantId,
			addons: this.state.userAddon
		});
	}

	cancelEditRegistrant() {
		this.setState({
			registrantID: ''
		});
	}
	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
	};
	scrollToTop = () => {
		this.messagesStart.scrollIntoView({ behavior: 'smooth' });
	};

	saveRegistrant(newRegistrantInfo) {
		this.props.updateRegistrantInfo(newRegistrantInfo);
		this.modifyAdVetSport();
		this.modifyAddon();
		this.setState({
			registrantID: ''
		});
	}

	changeAdVetSports(sport) {
		let existingSport = this.state.userAdvet;
		let newSports = existingSport.map(p =>
			p.rpName === sport
				? { ...p, registered: p.registered === 1 ? 0 : 1 }
				: p
		);
		this.setState({
			registrantAdVetSport: sport,
			userAdvet: newSports
		});
	}

	changeAddons(addon) {
		let existingAddon = this.state.userAddon;
		let newAddons = existingAddon.map(p =>
			p.name === addon
				? { ...p, registered: p.registered === 1 ? 0 : 1 }
				: p
		);
		this.setState({
			registrantAddon: addon,
			userAddon: newAddons
		});
	}

	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	togglePhotoFilter() {
		this.setState({ validPhotos: !this.state.validPhotos });
	}

	render() {
		// Show modal to edit individual registrant info
		let editAreaRender = null;
		if (this.state.registrantID !== '' && this.props.registrantsList[0]) {
			let currentRegistrant = this.props.registrantsList.find(
				x => x.registrant_id === this.state.registrantID
			);
			editAreaRender = (
				<EditRegistrantArea
					{...currentRegistrant}
					key={currentRegistrant.registrant_id}
					unregisterPlayer={this.unregisterPlayer}
					saveRegistrant={this.saveRegistrant}
					cancelHandler={this.cancelEditRegistrant}
					allGender={this.props.gender}
					allGrade={this.props.grade}
					allCatGender={this.props.cat_gender}
					individualGrade={this.props.individualGrade}
					allCountries={this.props.countries}
					adVet={this.state.userAdvet}
					changeAdVetSports={this.changeAdVetSports}
					addon={this.state.userAddon}
					changeAddons={this.changeAddons}
				/>
			);
		}

		// Show list of registrants
		if (this.props.registrantsList) {
			// All registrants by default
			let registrantsToShow = this.props.registrantsList;
			let catGenderToShow = this.props.grade;
			let teamsToShow = this.props.teams;

			// Filter down registrants, category/gender options, and teams by country
			if (this.state.selectedCountry !== 'select') {
				registrantsToShow = registrantsToShow.filter(
					x => x.country === this.state.selectedCountry
				);
				catGenderToShow = catGenderToShow.filter(x => {
					return (
						registrantsToShow.filter(
							y => y.category_gender === x.category
						).length > 0
					);
				});
				teamsToShow = teamsToShow.filter(x =>
					x.includes(this.state.selectedCountry)
				);
			}

			// Filter down registrants and teams by category/gender
			if (this.state.selectedCategoryGender !== 'select') {
				registrantsToShow = registrantsToShow.filter(
					x => x.category_gender === this.state.selectedCategoryGender
				);
				teamsToShow = teamsToShow.filter(x =>
					x.includes(this.state.selectedCategoryGender.split('-')[0])
				);
			}

			// Filter down registrants by team
			if (this.state.selectedTeam !== 'select') {
				registrantsToShow = registrantsToShow.filter(
					x => x.team === this.state.selectedTeam
				);
			}

			// Filter down registrants by searching through some fields
			// name, lastname, registrant id, registration id
			if (this.state.searchText !== '') {
				let text = this.state.searchText.toLowerCase();
				registrantsToShow = registrantsToShow.filter(post => {
					const name = post.firstname.toLowerCase();
					const lastname = post.lastname.toLowerCase();
					const registrantId = post.mg_registrant_id.toString();
					const registrationId = post.registration_id.toLowerCase();
					return (
						`${name} ${lastname}`.includes(text) ||
						registrantId.includes(text) ||
						registrationId.includes(text)
					);
				});
			}

			if (this.state.validPhotos) {
				registrantsToShow = registrantsToShow.filter(
					x => x.image_accepted === 0
				);
			}

			const currentUserCanEdit =
				this.props.auth.user.groups.includes('registration_admin') ||
				this.props.auth.user.groups.includes('admin');

			let registrantTableRender = getRegistrantTableRender(
				registrantsToShow,
				currentUserCanEdit,
				this.editRegistrant
			);

			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Registrants
						</Card.Header>
						<Card.Body>
							<Container>
								<Form noValidate>
									<Row>
										<Col>
											<Form.Group
												className="mb-1"
												style={{ width: '70%' }}
											>
												{/* <Row> */}
												<Form.Label>Country</Form.Label>
												<Form.Select
													value={
														this.state
															.selectedCountry
													}
													onChange={this.changeHandler.bind(
														null,
														'selectedCountry'
													)}
													aria-label="Country"
												>
													<option value={'select'}>
														select
													</option>
													{this.props.countries.map(
														x => (
															<option
																key={x}
																value={x}
															>
																{x}
															</option>
														)
													)}
												</Form.Select>
												{/* </Row> */}
											</Form.Group>
										</Col>
										<Col>
											<Form.Group
												className="mb-1"
												style={{ width: '80%' }}
											>
												<Form.Label>
													Category/Gender
												</Form.Label>
												<Form.Select
													value={
														this.state
															.selectedCategoryGender
													}
													onChange={this.changeHandler.bind(
														null,
														'selectedCategoryGender'
													)}
													aria-label="Category Gender"
												>
													<option value={'select'}>
														select
													</option>
													{catGenderToShow.map(x => (
														<option
															value={x.category}
															key={`filter${x.category}`}
														>
															{x.category} -{' '}
															{x.grade}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group
												className="mb-1"
												style={{ width: '70%' }}
											>
												<Form.Label>Team</Form.Label>
												<Form.Select
													value={
														this.state.selectedTeam
													}
													onChange={this.changeHandler.bind(
														null,
														'selectedTeam'
													)}
													aria-label="Team"
												>
													<option value={'select'}>
														select
													</option>
													{teamsToShow.map(x => (
														<option
															value={x}
															key={x}
														>
															{x}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col
											style={{
												disply: 'flex',
												justifyContent: 'end'
											}}
										>
											<Button
												variant="outline-primary ml-auto"
												onClick={this.downloadAllRecords.bind(
													this
												)}
												style={{ float: 'right' }}
											>
												Download All Records
											</Button>
											<Button
												variant="outline-primary ml-auto"
												onClick={this.scrollToBottom.bind(
													this
												)}
												style={{ float: 'right' }}
											>
												Scroll to Bottom
											</Button>
											<Button
												variant="outline-primary ml-auto"
												onClick={this.togglePhotoFilter}
												style={{ float: 'right' }}
											>
												{this.state.validPhotos
													? 'Show All Photos'
													: 'Show Rejected Photos'}
											</Button>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col>
											<Form.Group className="mb-1 w-50">
												<Form.Control
													className="me-sm-2"
													placeholder="Search"
													aria-label=""
													aria-describedby="basic-addon1"
													value={
														this.state.searchText
													}
													onChange={this.changeHandler.bind(
														null,
														'searchText'
													)}
												/>
											</Form.Group>
										</Col>
										<Col>
											<p style={{ fontWeight: 'bold' }}>
												Total Registrants:
												<span>
													{
														registrantsToShow.filter(
															x =>
																x.is_registered !==
																0
														).length
													}
												</span>
											</p>
										</Col>
									</Row>
								</Form>
								<div
									style={{ float: 'left', clear: 'both' }}
									ref={el => {
										this.messagesStart = el;
									}}
								></div>
								{registrantTableRender}
								<div
									style={{ float: 'left', clear: 'both' }}
									ref={el => {
										this.messagesEnd = el;
									}}
								></div>
								<Button
									variant="outline-primary ml-auto"
									onClick={this.scrollToTop.bind(this)}
									style={{ float: 'right' }}
								>
									Scroll to Top
								</Button>
							</Container>
						</Card.Body>
					</Card>
					{editAreaRender}
				</div>
			);
		} else if (this.props.errorMessage) {
			return <p>{this.props.errorMessage}</p>;
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.familyinfo;
	if (raw.registrants) {
		return {
			registrantsList: raw.registrants,
			countries: raw.countries,
			cat_gender: raw.cat_gender,
			gender: raw.gender,
			grade: raw.grade,
			teams: raw.teams,
			downloadRegistrants: raw.downloadRegistrants,
			individualGrade: raw.individualGrade,
			auth: state.auth
		};
	} else if (raw.requestStatus === 'error') {
		return {
			registrantsList: null,
			countries: null,
			cat_gender: null,
			gender: null,
			grade: null,
			teams: null,
			auth: state.auth,
			errorMessage: raw.message
		};
	} else {
		return {
			registrantsList: null,
			countries: null,
			cat_gender: null,
			gender: null,
			grade: null,
			teams: null,
			auth: state.auth
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getRegistrantInfo: () => dispatch(registrantsinfo()),
		updateRegistrantInfo: info => dispatch(updateregistrantinfo(info)),
		unregisterPlayer: registrantID =>
			dispatch(unregisterplayer(registrantID)),
		downloadRegistrantRecords: () => dispatch(downloadRegistrantRecords()),
		getUsersAdVetSport: registrantID =>
			dispatch(getUsersAdVetSportList(registrantID)),
		putUsersAdVetSport: ({ registrantId, sports }) =>
			dispatch(putUsersAdVetSportList({ registrantId, sports })),
		getUsersAddon: registrantID =>
			dispatch(getUsersAddonList(registrantID)),
		putUsersAddon: ({ registrantId, addons }) =>
			dispatch(putUsersAddonList({ registrantId, addons }))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withLocation(RegistrantInfoView));
