import React from 'react';
import { connect } from 'react-redux';
import { moveGameToClipboard } from '../../services/colorchart/colorchartActions.js';

/**
 * Displays a gameslot in the color chart
 * @function MultiMoveClipboard
 * @param {Object} props
 * @param {string} props.date - date of gameslot
 * @param {string} props.time - starting time of gameslot
 * @param {string} props.rowType - "top" or "bottom", indicating which row
 * 									this cell belongs to within a timeslot
 * @returns {React.Component}
 */
function MultiMoveClipboard(props) {
	const dropHandler = e => {
		e.preventDefault();
		let gameInfo = JSON.parse(e.dataTransfer.getData('gameInfo'));
		const existingGameIDs = props.children.map(
			x => x.props.children.props.game_id
		);
		if (!existingGameIDs.includes(gameInfo.game_id)) {
			props.moveGameToClipboard(gameInfo);
		}
	};

	const preventDefaultDrop = e => e.preventDefault();

	const clipboard = {
		marginTop: '5px',
		// marginBottom: '0',
		padding: '20px',
		borderRadius: '5px',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		flexWrap: 'wrap',
		backgroundColor: '#f7fbff',
		border: '2px solid gray',
		justifyContent: 'flex-start',
		alignContent: 'center',
		textAlign: 'center'
		// minHeight: '40px'
	};

	return (
		<div
			style={clipboard}
			onDrop={dropHandler}
			onDragOver={preventDefaultDrop}
			onDragEnter={preventDefaultDrop}
		>
			{/* <p>Multi-Move Clipboard</p> */}
			{props.children}
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		moveGameToClipboard: game => dispatch(moveGameToClipboard(game))
	};
};

export default connect(null, mapDispatchToProps)(MultiMoveClipboard);
