/** @module MainMenu */
import React from 'react';
import MenuCard from '../components/menu/MenuCard';
import { Nav, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './../components/menu/menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

/* 
Synchronous vs Asynchronous code:

Synchronous code - blocks execution of anything else until it is done
Asynchronous code - continues execution of lines after it
*/

/**
 * Functional component that displays the menu
 * for the current user
 * @returns {React.Component}
 */
export default function MainMenu(props) {
	const dispatch = useDispatch();

	const imgStyle = {
		height: '6%',
		// width: '300px',
		// marginLeft: 'calc(0.5vw)',
		marginTop: '0',
		alignItems: 'center'
	};

	const linkStyle = {
		textDecoration: 'none',
		paddingLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		alignCotent: 'center',
		justifyContent: 'start'
	};
	const rowStyle = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center'
	};
	const iconStyle = {
		alignContent: 'center',
		justifyContent: 'center',
		display: 'flex',
		alignSelf: 'center',
		color: 'white'
	};
	//style={menuStyle}
	//https://stackoverflow.com/questions/60482018/make-a-sidebar-from-react-bootstrap
	return (
		<Nav className={`col-md-2 d-none d-md-block nav sidebar main-nav`}>
			<Container fluid>
				<Row
					className="justify-content-center"
					style={{ borderBottom: '1px solid #666666' }}
				>
					<img
						style={imgStyle}
						alt="Maccabi Games Logo"
						src="MG24LOGO_no_bg.png"
					/>
				</Row>
				<Row>
					{props.menuPages.map(cardProps => (
						<MenuCard
							keyItem={`menuitem-${cardProps.pageTitle}`}
							key={`menuitem-${cardProps.pageTitle}`}
							{...cardProps}
						/>
					))}
					<Row>
						<NavLink
							eventkey={props.key}
							style={() => linkStyle}
							onClick={props.signOutState}
							to={`/login`}
						>
							<div className="rowTitle" style={rowStyle}>
								<FontAwesomeIcon
									style={iconStyle}
									icon={faUser}
								/>
								<p className="sidebar-heading">Sign Out</p>
							</div>
						</NavLink>
					</Row>
				</Row>
			</Container>
		</Nav>
	);
}
