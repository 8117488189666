import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const masterSchedule = createAsyncThunk(
	'GET_MASTER_SCHEDULE',
	getMasterSchedule
);
export const refschedule = createAsyncThunk('GET_REF_SCHEDULE', getRefSchedule);

async function getMasterSchedule() {
	try {
		let result = await request('schedule/master');
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getRefSchedule() {
	try {
		let result = await request('schedule/referee');
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
