import React from 'react';

/**
 * Icon with a callback on click
 * @function LinkedIcon
 * @param {Object} props
 * @param {function} props.clickHandler - function to execute on icon click
 * @param {string} props.imageURL - source file for icon
 * @returns {React.Component}
 */
export default function LinkedIcon(props) {
	const iconStyle = {
		cursor: 'pointer',
		width: '2vw'
	};

	return (
		<img
			style={iconStyle}
			alt="iconimage"
			onClick={props.clickHandler}
			src={props.imageURL}
		/>
	);
}

// const fakeprops = {
// 	clickHandler: () => console.log('clicked'),
// 	imageURL: 'https://www.google.com/logos/doodles/2015/googles-new-logo-5078286822539264.2-hp.gif'
// };
