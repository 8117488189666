/* This page is the source for our Single Page App using React.js */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { awsconfig } from './global/awsconfig';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './containers/app/App.js';
import AmplifyBridge from './services/authentication/AmplifyBridge.js';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import UploadPhotoPortal from './pages/UploadPhotoPortal';

/* Set up AWS user pool configuration */
Amplify.configure(awsconfig);
new AmplifyBridge(store);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDIscF0WemnkdKm9KC9vnrZIX2XOL1xAB8',
	authDomain: 'maccabi-games-cfb5d.firebaseapp.com',
	databaseURL: 'https://maccabi-games-cfb5d.firebaseio.com',
	projectId: 'maccabi-games-cfb5d',
	storageBucket: 'maccabi-games-cfb5d.appspot.com',
	messagingSenderId: '269247350564',
	appId: '1:269247350564:web:97a3c14a05d57679',
	measurementId: 'G-0FX5NK4HTG'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.debug(app);
getAnalytics(app);
// console.log("Analytics", analytics);
// window.LOG_LEVEL='DEBUG';

/* Render root elements
-	Provider component makes Redux store available to children
-	BrowserRouter uses React Router to handle navigation
-	App contains the protected aspects of the webapp
*/
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					{/* <Route path='/docs/index.html' element={() => window.location.reload()}/> */}
					<Route
						path="/photoupload/:registrant_id"
						element={<UploadPhotoPortal />}
					/>
					<Route path="*" element={<App />} />
				</Routes>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);
