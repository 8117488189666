import React from 'react';

/**
 * Displays the teams in a pool
 * @function PoolBlock
 * @param {Object} props
 * @param {string[]} props.poolTeams - names of each team in the pool
 * @returns {React.Component}
 */
export default function PoolBlock(props) {
	const divStyle = {
		border: '2px solid black'
	};

	return (
		<div style={divStyle}>
			{props.poolTeams.map(element => (
				<p key={element}>{element}</p>
			))}
		</div>
	);
}

// export const fakeprops = {
// 	poolTeams: [
// 		'Ch E Female ARG 1',
// 		'Ch E Female COL 2',
// 		'Ch E Female ISR 1',
// 		'Ch E Female VEN 2'
// 	]
// };
