import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const managePeopleInfo = createAsyncThunk(
	'GET_MANAGEPEOPLE_INFO',
	getManagePeopleInfo
);
export const addusertogroup = createAsyncThunk(
	'ADD_USER_TO_GROUP',
	addUserToGroup
);
export const removeuserfromgroup = createAsyncThunk(
	'REM_USER_FROM_GROUP',
	removeUserFromGroup
);
export const manageCoachInfo = createAsyncThunk(
	'GET_MANAGECOACH_INFO',
	getManageCoachInfo
);
export const managecountryinfo = createAsyncThunk(
	'GET_MANAGECOUNTRY_INFO',
	getManageCountryInfo
);
export const changesportforref = createAsyncThunk(
	'CHANGE_SPORT_REF',
	changeSportForRef
);

async function changeSportForRef({ sport, username, change }) {
	try {
		await request(`user/ref/change/${username}`, 'post', { sport, change });

		return Promise.resolve(await getManagePeopleInfo());
	} catch (err) {
		return Promise.reject(err);
	}
}

async function getManageCoachInfo(team) {
	try {
		let coachresp = await request(`user/managecoachesinfo/${team}`);

		return Promise.resolve({
			team: team,
			coachinfo: coachresp.data.content
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getManageCountryInfo() {
	try {
		let teams = await request(`tournament/team/captain`);

		return Promise.resolve(teams.data.content);
	} catch (err) {
		return Promise.reject(err);
	}
}

async function removeUserFromGroup({ group, user }) {
	try {
		switch (group) {
			case 'REFEREE':
				await request(`user/ref/${user.id}`, 'delete');
				break;
			case 'COORDINATOR':
				await request(`user/coordinator/${user.id}`, 'delete');
				break;
			case 'STAFF':
				await request(`user/staff/${user.id}`, 'delete');
				break;
			case 'SITEDIRECTOR':
				await request(`user/sitedirector/${user.id}`, 'delete');
				break;
			case 'CAPTAIN':
				await request(
					`user/captain/${user.country}/${user.id}`,
					'delete'
				);
				break;
			case 'COACH':
				await request(
					`user/coach/${user.team_id}/${user.id}`,
					'delete'
				);
				return Promise.resolve({
					group: group,
					data: await getManageCoachInfo(user.team_id)
				});
			default:
				break;
		}

		return Promise.resolve({
			group: group,
			data: await getManagePeopleInfo()
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function addUserToGroup({ group, user }) {
	try {
		switch (group) {
			case 'REFEREE':
				await request(`user/ref/${user.id}`, 'post');
				break;
			case 'COORDINATOR':
				await request(`user/coordinator/${user.id}`, 'post');
				break;
			case 'STAFF':
				await request(`user/staff/${user.id}`, 'post');
				break;
			case 'SITEDIRECTOR':
				await request(`user/sitedirector/${user.id}`, 'post');
				break;
			case 'CAPTAIN':
				await request(
					`user/captain/${user.country}/${user.id}`,
					'post'
				);
				break;
			case 'COACH':
				await request(`user/coach/${user.team_id}/${user.id}`, 'post');
				return Promise.resolve({
					group: group,
					data: await getManageCoachInfo(user.team_id)
				});
			default:
				break;
		}

		return Promise.resolve({
			group: group,
			data: await getManagePeopleInfo()
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getManagePeopleInfo() {
	try {
		const [
			staffresp,
			sitedirectorresp,
			captainsresp,
			refereeresp,
			coordinatorsresp,
			countriesresp,
			teamsresp
		] = await Promise.all([
			request('user/managestaffinfo'),
			request('user/managesitedirectorinfo'),
			request('user/managecaptainsinfo'),
			request('user/managerefinfo'),
			request('user/managecoordinatorinfo'),
			request('tournament/countries'),
			request('tournament/team/teams')
		]);

		const output = {
			staff: staffresp.data.content.staff,
			potentialstaff: staffresp.data.content.potentialstaff,
			sitedirector: sitedirectorresp.data.content.sitedirector,
			potentialsitedirector:
				sitedirectorresp.data.content.potentialsitedirector,
			countries: countriesresp.data.content.countries.map(
				x => x.short_name
			),
			captains: captainsresp.data.content.captains,
			potentialcaptains: captainsresp.data.content.potentialcaptains,
			referees: refereeresp.data.content.referees,
			potentialreferees: refereeresp.data.content.potentialref,
			coordinators: coordinatorsresp.data.content.coordinators,
			potentialcoordinators:
				coordinatorsresp.data.content.potentialcoordinators,
			sports: refereeresp.data.content.sports,
			refsports: refereeresp.data.content.refsports,
			teams: teamsresp.data.content
		};

		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
