import React from 'react';
import BookingInfo from './BookingInfo';
import ButtonRow from '../general/ButtonRow.js';

/**
 * Card for a double booked team
 * @function DoubleBookedTeamCard
 * @param {Object} props
 * @param {Object} props.bookingInfo - props for {@link BookingInfo}
 * @param {int} props.numBookings - how many times this team is booked for this lsot
 * @param {}
 */
export default function DoubleBookedTeamCard(props) {
	const containerStyle = {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '7.5%',
		padding: '2% 10px',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0',
		color: 'black'
	};

	const cardStyle = {
		textAlign: 'center',
		backgroundColor: '#F9F9F9',
		margin: '5% 0',
		padding: '10% 30px',
		borderRadius: '15px',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		whiteSpace: 'nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		width: '120px'
	};

	const circle = {
		position: 'absolute',
		right: '10px',
		bottom: '10px',
		borderRadius: '50%',
		width: '25px',
		height: '25px',
		margin: '0',
		backgroundColor: '#1857C3',
		color: 'white',
		alignText: 'center',
		padding: '3px 1.5px 0 1.5px'
	};

	return (
		<div style={containerStyle}>
			<div style={cardStyle}>
				<BookingInfo {...props.bookingInfo} />
				<p style={circle}>{props.gameIDs.length}</p>
			</div>
			<ButtonRow
				leftLabel="View"
				rightLabel="Dismiss"
				leftClickHandler={props.viewHandler.bind(null, props.gameIDs)}
				rightClickHandler={props.dismissHandler.bind(null, props)}
			/>
		</div>
	);
}

// export const fakeprops = {
// 	bookingInfo: {
// 		title: 'Ad Male ARG 1',
// 		date: 'Fri, 25-Nov',
// 		time: '3:30pm'
// 	},
// 	gameIDs: [47, 52, 59],
// 	viewHandler: (ids) => console.log(`View these games ${JSON.stringify(ids)}`),
// 	dismissHandler: (ids) => console.log(`Dismiss these games ${JSON.stringify(ids)}`)
// }
