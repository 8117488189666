import React from 'react';
import SiteDirectorCell from './SiteDirectorCell';

// const datesList = [
// 	"Thu, Nov-18",
// 	"Sat, Nov-20",
// 	"Sun, Nov-21",
// 	"Mon, Nov-22",
// 	"Tue, Nov-23",
// 	"Wed, Nov-24",
// 	"Thu, Nov-25",
// 	"Fri, Nov-26",
// 	"Sat, Nov-27",
// 	"Sun, Nov-28"
// ];

// const locationsList = [
// 	"Hillel - Basketball Out",
// 	"Hillel - Field 1",
// 	"Hillel - Field 2",
// 	"Hillel - Field 3",
// 	"Hillel - Field 4",
// 	"Hillel - Gym Basketball",
// 	"Hillel - Gym Volleyball",
// 	"JCC Back Field 1",
// 	"JCC Back Field 2",
// 	"JCC Back Field 3",
// 	"JCC Back Field 4",
// 	"JCC Basketball Out 1",
// 	"JCC Basketball Out 2",
// 	"JCC Gym North",
// 	"JCC Gym South",
// 	"JCC Plaza",
// 	"JCC Tennis Court 9",
// 	"Sinai - Basketball",
// 	"Sinai - Kickball",
// 	"Sinai - Soccer",
// 	"Tennis Courts"
// ];

/**
 * Displays the interactive grid of (date, location) tuples which
 * allows assignment of sitedirectors to each cell
 * @function AssignSiteDirectorGrid
 * @param {Object} props
 * @param {string[]} props.datesList - list of dates for games
 * @param {string[]} props.locationsList - list of locations for games
 * @param {Object[]} props.assignments - list of site director assignments
 * @param {string} props.activeSiteDirector - currently selected site director
 * @param {function} props.clickHandler - function to call when an active cell is clicked
 * @returns {React.Component}
 */
export default function AssignSiteDirectorGrid(props) {
	return (
		<table>
			<thead>
				<tr>
					<th></th>
					{props.datesList.map(x => (
						<th>{x}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.locationsList.map(x => (
					<tr>
						<td>{x}</td>
						{props.datesList.map(y => {
							let curObj = props.assignments.find(
								cell =>
									cell.location_name === x && cell.date === y
							);
							// Determine if cell should be grayed out
							// Determine what name to have on the cell
							// Determine if cell should be green
							return (
								<SiteDirectorCell
									active={curObj.active === 1}
									sitedirector={curObj.name}
									activeSiteDirector={
										props.activeSiteDirector
									}
									curSelected={
										props.activeSiteDirector ===
										curObj.username
									}
									clickHandler={props.clickHandler.bind(
										null,
										curObj
									)}
								/>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
}
