import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';
export const familyinfo = createAsyncThunk('GET_FAMILY_INFO', getFamilyInfo);
export const updatefamilyinfo = createAsyncThunk(
	'UPDATE_FAMILY_INFO',
	updateFamilyInfo
);
export const unregisterfamily = createAsyncThunk(
	'UNREGISTER_FAMILY',
	unregisterFamily
);
export const registrantsinfo = createAsyncThunk(
	'GET_REGISTRANT_INFO',
	getRegistrantInfo
);
export const updateregistrantinfo = createAsyncThunk(
	'UPDATE_REGISTRANT_INFO',
	updateRegistrantInfo
);
export const unregisterplayer = createAsyncThunk(
	'UNREGISTER_REGISTRANT',
	unregisterPlayer
);
export const downloadRegistrantRecords = createAsyncThunk(
	'DOWNLOAD_REGISTRANTS',
	downloadRegistrant
);
export const createFamilyCard = createAsyncThunk(
	'CREATE_FAMILY_CARD',
	createFamilyCardAPI
);

export const getUsersAdVetSportList = createAsyncThunk(
	'GET_USER_ADVETSPORT',
	getUsersAdVetSport
);
export const putUsersAdVetSportList = createAsyncThunk(
	'PUT_USER_ADVETSPORT',
	putUsersAdVetSport
);

export const getUsersAddonList = createAsyncThunk(
	'GET_USER_ADDON',
	getUsersAddon
);
export const putUsersAddonList = createAsyncThunk(
	'PUT_USER_ADDON',
	putUsersAddon
);

export const updatemergefamily = createAsyncThunk(
	'MERGE_FAMILY',
	updateMergeFamily
);
export const getunapprovedreg = createAsyncThunk(
	'GET_UNAPP_REG',
	getUnapprovedReg
);

const databaseSchema =
	process.env.React_App_cognito_setup === 'prod' ? 'mgdb_prod' : 'mgdb';

async function getUnapprovedReg() {
	try {
		let result = await request(`logistics/unapprovedreg`);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getRegistrantInfo() {
	try {
		let result = await request(`logistics/registrantinfo`);
		if (result.status === 200) {
			let teams = new Set(result.data.registrants.map(x => x.team));
			result.data.teams = [...teams].sort().filter(x => x);
			console.debug(result.data);
			return Promise.resolve(result.data);
		} else {
			console.warn(result.data);
			return Promise.reject(result.data);
		}
	} catch (err) {
		console.error(err);
		return Promise.reject(err);
	}
}

async function updateRegistrantInfo(body) {
	try {
		let reqBody = {
			is_member: body.isMember ? 1 : 0,
			firstname: body.firstName,
			lastname: body.lastName,
			country: body.country,
			phonenumber: body.phoneNum,
			dob: body.dob,
			gender: body.gender,
			grade: body.grade,
			category_gender: body.catGender,
			shirtsize: body.shirtsize,
			n_challah: body.n_challah,
			registrant_id: body.registrant_id,
			email: body.email,
			notes: body.notes,
			approved_country_change: body.approvedCountryChange ? 1 : 0
		};

		await request(`logistics/registrantinfo`, 'put', reqBody);
		return Promise.resolve(await getRegistrantInfo());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function unregisterPlayer(registrantID) {
	try {
		await request(`logistics/registrantstatus`, 'put', {
			registrant_id: registrantID
		});
		return Promise.resolve(await getRegistrantInfo());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function downloadRegistrant() {
	try {
		let result = await request(`user/registrants`);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getFamilyInfo() {
	try {
		let result = await request(`logistics/familyinfo`);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function updateFamilyInfo(body) {
	try {
		let reqBody = {
			firstname: body.firstName,
			lastname: body.lastName,
			email: body.email,
			phonenumber: body.phoneNum,
			is_member: body.isMember ? 1 : 0,
			member_number:
				body.memberNumber === undefined ? '' : body.memberNumber,
			has_paid: body.hasPaid ? 1 : 0,
			registration_id: body.registrationID
		};

		await request(`logistics/familyinfo`, 'put', reqBody);
		return Promise.resolve(await getFamilyInfo());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function unregisterFamily(registrationID) {
	try {
		await request(`logistics/familyregistrantstatus`, 'put', {
			registration_id: registrationID
		});
		return Promise.resolve(await getFamilyInfo());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getUsersAdVetSport(registrantId) {
	try {
		let result = await request(`logistics/${registrantId}/advetsport`);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getUsersAddon(registrantId) {
	try {
		let result = await request(`logistics/${registrantId}/addons`);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function putUsersAddon({ registrantId, addons }) {
	// console.log("Submitting Advet sports", sports)
	try {
		let result = await request(
			`logistics/${registrantId}/addons`,
			'put',
			addons
		);
		return Promise.resolve(result);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function putUsersAdVetSport({ registrantId, sports }) {
	// console.log("Submitting Advet sports", sports)
	try {
		let result = await request(
			`logistics/${registrantId}/advetsport`,
			'put',
			sports
		);
		return Promise.resolve(result);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function createFamilyCardAPI({ authentication, first, last }) {
	try {
		const body = {
			function: 'startScipt',
			devMode: false,
			parameters: [parseInt(first), parseInt(last), databaseSchema]
		};
		const url = `https://script.googleapis.com/v1/scripts/AKfycbwXY6jyw_ArjfM5YftjYNxXdVRQv2EuGv9fzvv3s3-TyKb9agLbq9HPmC03mifsMF2qNw:run`;

		const reqOptions = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${authentication}`,
				'Content-Type': 'application/json'
			}
		};

		let result = await fetch(url, reqOptions);
		result = await result.json();
		return result;
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function updateMergeFamily(mergeFamilies) {
	try {
		// let reqBody = {
		// 	mergeFamilies: body.mergeFamilies,
		// };

		console.log('mfam: ', mergeFamilies);

		let result = await request(
			`logistics/mergefamilyinfo/${mergeFamilies}`,
			'put'
		);
		return Promise.resolve(result);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
