import React from 'react';

export default function RefScheduleInfoRow(props) {
	let teamsRender;
	if (props.team1 === null && props.team2 === null) {
		teamsRender = 'TBD';
	} else {
		teamsRender = `${props.team1} vs. ${props.team2}`;
	}

	return (
		<tr>
			<th className="text-center" scope="row">
				{props.change_date}
			</th>
			<td className="text-center">
				{props.type === 'Round - 1'
					? props.tourny
					: `${props.tourny} ${props.type}`}
			</td>
			<td className="text-center">{teamsRender}</td>
			<td className="text-center">{props.old_date}</td>
			<td className="text-center">{props.old_time}</td>
			<td className="text-center">{props.old_location}</td>
			<td className="text-center">{props.date}</td>
			<td className="text-center">{props.time}</td>
			<td className="text-center">{props.location}</td>
			<td className="text-center">{props.sport}</td>
			<td className="text-center">{props.change_type}</td>
		</tr>
	);
}
