import React from 'react';
import './multigame.css';
/**
 * Displays a game
 * @function ColorChartGame
 * @param {Object} props
 * @param {string} props.date - date of game
 * @param {string} props.time - starting time of game
 * @param {int} props.game_id - unique identifier for game
 * @param {string} props.background - color corresponding to tournament
 * @param {string} props.textcolor - color corresponding to tournament
 * @param {string} props.rowType - "top" or "bottom", indicating which row
 * 									this cell belongs to within a timeslot
 * @param {boolean} props.empty - whether there is a game in this cell or not
 * @param {string} props.text - description for game
 * @param {int} props.bold - 1 or 0. Whether the text should be bold or not
 * @param {int} props.strike - 1 or 0. Whether there should be a strikethrough or not
 * @param {string} props.sport - Sport that the game is for
 * @returns {React.Component}
 */
export default function ColorChartGame(props) {
	let cellStyle = {
		backgroundColor: props.background,
		color: props.textcolor,
		cursor: 'grab',
		borderRadius: '5px',
		padding: '1px 10px'
	};

	let displayText = props.showSport
		? `${props.text} - ${props.sport}`
		: props.text;

	//Apply formatting to text
	if (props.bold === 1) cellStyle.fontWeight = 'bold';
	if (props.sport === 'Kickball') displayText = displayText.toLowerCase();

	const dragStartHandler = e => {
		const target = e.target;
		e.dataTransfer.setData('gameInfo', JSON.stringify(props));

		setTimeout(() => (target.style.display = 'none'), 0);
	};
	const preventDefaultDrop = e => e.preventDefault();
	const dragEndHandler = e => {
		e.target.style.display = 'block';
	};

	if (props.summonMode) {
		cellStyle.cursor = 'default';

		return (
			<p
				className="gamecell-colorchart"
				draggable="false"
				style={cellStyle}
				onClick={props.addToSummonList.bind(null, props)}
			>
				{displayText}
			</p>
		);
	} else if (props.filtering) {
		cellStyle.cursor = 'default';

		return (
			<p
				className="gamecell-colorchart"
				draggable="false"
				style={cellStyle}
			>
				{displayText}
			</p>
		);
	} else if (props.strike === 1) {
		let gameID = displayText.split(' ').slice(-1);
		let gameText = displayText.split(' ').slice(0, -1).join(' ');
		return (
			<div>
				<p
					draggable="true"
					className="gamecell-colorchart"
					style={cellStyle}
					onDragStart={dragStartHandler}
					onDragOver={preventDefaultDrop}
					onDragEnter={preventDefaultDrop}
					onDragEnd={dragEndHandler}
					onMouseOver={props.showScores.bind(null, props.game_id)}
					onMouseLeave={props.hideScores}
				>
					<span
						style={{ textDecoration: 'line-through' }}
					>{`${gameText} `}</span>
					{gameID}
				</p>
			</div>
		);
	} else {
		return (
			<p
				draggable="true"
				className="gamecell-colorchart"
				style={cellStyle}
				onDragStart={dragStartHandler}
				onDragOver={preventDefaultDrop}
				onDragEnter={preventDefaultDrop}
				onDragEnd={dragEndHandler}
			>
				{displayText}
			</p>
		);
	}
}
