import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const untieData = createAsyncThunk('GET_UNTIEDTEAMS', untieDataAction);
export const updateUntie = createAsyncThunk(
	'POST_UNTIEDTEAMS',
	updateUntieAction
);

async function untieDataAction() {
	try {
		let data = await request('standings/tiedteams');
		return Promise.resolve(data.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function updateUntieAction(body) {
	try {
		console.log('Submitting Body', body.tied);
		let data = await request('standings/tiedteams', 'put', body.tied);
		console.log(data);
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
