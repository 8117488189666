import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const awardpointsviewinfo = createAsyncThunk(
	'GET_AWARDPOINTS_INFO',
	getAwardPointsViewInfo
);
export const pointsbyevent = createAsyncThunk(
	'GET_POINTS_BY_EVENT',
	getPointsByEvent
);
export const addpointstoevent = createAsyncThunk(
	'ADD_POINTS',
	addPointsToEvent
);
export const deletepoints = createAsyncThunk('DELETE_POINT', deletePoints);

async function getAwardPointsViewInfo() {
	try {
		let events = await request('tournament/coordinatorevents');
		let countries = await request('tournament/countries');
		let output = {
			events: events.data,
			countries: countries.data.content.countries
		};
		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getPointsByEvent(event_id) {
	try {
		let points = await request(`tournament/pointsbyevent/${event_id}`);
		return Promise.resolve(points.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function addPointsToEvent(pointsInfo) {
	try {
		let eventPoints = await request(
			`tournament/points`,
			'post',
			pointsInfo
		);
		return Promise.resolve(eventPoints.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function deletePoints(points_id) {
	try {
		await request(`tournament/points/${points_id}`, 'delete');
		return Promise.resolve(points_id);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
