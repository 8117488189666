import React from 'react';
import DragDropItem from '../general/DragDropItem';
import DragDropList from '../general/DragDropList';
import { Form, Col, Row } from 'react-bootstrap';
/**
 * @class SetCoordinator
 * @classdesc Page to add/remove selected coordinators for an event
 * @param {Object} props
 * @param {string} props.searchText - string containing the current sext that has been written in the search text box
 * @param {function} props.updateSearch - function to control and update the text in the unlelected coordinators search box
 * @param {function} props.removeSelectedUser - function to remove a coordinator from the current list of selected coordinators
 * @param {function} props.makeSelectedUser - function to add a coordinator to the current list of selected coordinators
 * @param {Object[]} props.unselectedUsersToDisplay - list of coordinators who are currently unslelected. Props for {@link DragDropItem}
 * @param {Object[]} props.selectedUsersToDisplay - list of coordinators who are currently slelected. Props for {@link DragDropItem}
 * @returns {React.Component}
 */
export default function SetCoordinator(props) {
	return (
		<div>
			<Form.Group className="mb-3">
				<Form.Label>Add Coordinators</Form.Label>
				<Row>
					<Col>
						<Form.Label>Search</Form.Label>
						<Form.Control
							type="text"
							onChange={props.updateSearch}
							value={props.searchText}
						></Form.Control>
					</Col>
					<Col></Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>All Users</Form.Label>
						<DragDropList
							cardDropHandler={props.removeSelectedUser}
							stylePBox={{ border: '0.5px solid black' }}
						>
							{props.unselectedUsersToDisplay.map(element => (
								<DragDropItem
									stylePBox={{ width: '50%' }}
									key={element.id}
									{...element}
								/>
							))}
						</DragDropList>
					</Col>
					<Col>
						<Form.Label>Coordinators</Form.Label>
						<DragDropList
							cardDropHandler={props.makeSelectedUser}
							stylePBox={{ border: '0.5px solid black' }}
						>
							{props.selectedUsersToDisplay.map(element => (
								<DragDropItem
									stylePBox={{ width: '50%' }}
									key={element.id}
									{...element}
								/>
							))}
						</DragDropList>
					</Col>
				</Row>
			</Form.Group>
		</div>
	);
}
