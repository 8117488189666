import React from 'react';
import BookingInfo from './BookingInfo.js';
import DoubleBookedGame from './DoubleBookedGame.js';

export default class DoubleBookedLocationCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			viewing: false
		};

		this.toggleViewing = this.toggleViewing.bind(this);
	}

	toggleViewing() {
		this.setState({ viewing: !this.state.viewing });
	}

	render() {
		const containerStyle = {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '7.5%',
			padding: '2% 10px',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '0',
			color: 'black'
		};

		const cardStyle = {
			textAlign: 'center',
			backgroundColor: '#F9F9F9',
			margin: '5% 0',
			padding: '10% 30px',
			borderRadius: '15px',
			boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
			whiteSpace: 'nowrap',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			width: '160px'
		};

		const circle = {
			position: 'absolute',
			right: '10px',
			bottom: '10px',
			borderRadius: '50%',
			width: '25px',
			height: '25px',
			margin: '0',
			backgroundColor: '#1857C3',
			color: 'white',
			alignText: 'center',
			padding: '3px 1.5px 0 1.5px'
		};

		const greenButton = {
			backgroundColor: 'green',
			cursor: 'pointer',
			borderRadius: '15px',
			marginRight: '5px',
			border: '0',
			outline: 'none',
			padding: '3px 10px',
			color: 'white'
		};

		const yellowButton = {
			backgroundColor: 'yellow',
			cursor: 'pointer',
			borderRadius: '15px',
			marginRight: '5px',
			border: '0',
			outline: 'none',
			padding: '3px 10px',
			color: 'black'
		};

		const redButton = {
			backgroundColor: 'red',
			cursor: 'pointer',
			borderRadius: '15px',
			border: '0',
			outline: 'none',
			padding: '3px 10px',
			color: 'white'
		};

		const buttonRow = {
			display: 'flex'
		};

		if (this.state.viewing) {
			return (
				<div style={containerStyle}>
					<div style={cardStyle}>
						{this.props.games.map(x => (
							<DoubleBookedGame key={x.gameID} {...x} />
						))}
					</div>
					<div style={buttonRow}>
						<button
							style={yellowButton}
							onClick={this.toggleViewing}
						>
							Back
						</button>
						<button style={redButton}>Dismiss</button>
					</div>
				</div>
			);
		} else {
			return (
				<div style={containerStyle}>
					<div style={cardStyle}>
						<BookingInfo {...this.props.bookingInfo} />
						<p style={circle}>{this.props.gameIDs.length}</p>
					</div>
					<div style={buttonRow}>
						<button
							style={greenButton}
							onClick={this.toggleViewing}
						>
							View
						</button>
						<button
							style={redButton}
							onClick={this.props.dismissHandler.bind(
								null,
								this.props
							)}
						>
							Dismiss
						</button>
					</div>
				</div>
			);
		}
	}
}

export const fakeprops = {
	bookingInfo: {
		title: 'Hillel Field 1',
		date: 'Fri, 25-Nov',
		time: '3:30pm'
	},
	gameIDs: [14, 22, 43],
	games: [
		{
			backgroundColor: 'yellow',
			sport: 'Mini Soccer',
			gameTitle: 'ARG1-COL1',
			gameID: 6
		},
		{
			backgroundColor: 'light green',
			sport: 'Mini Soccer',
			gameTitle: 'COL2-VEN1',
			gameID: 62
		}
	]
};
