import React from 'react';
import { NavLink } from 'react-router-dom';
import iconMapper from '../../containers/app/iconMapper.js';

function MenuCard(props) {
	const linkStyle = {
		textDecoration: 'none',
		paddingLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		alignCotent: 'center',
		justifyContent: 'start'
	};
	const rowStyle = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center'
	};

	if (props.pageTitle) {
		return (
			<NavLink
				eventkey={props.keyItem}
				style={() => linkStyle}
				to={`${props.url}`}
			>
				<div className="rowTitle" style={rowStyle}>
					{iconMapper[props.image]}
					{/* <i style={iconStyle} className={props.image}/> */}
					<p className="sidebar-heading">{props.pageTitle}</p>
				</div>
			</NavLink>
		);
	} else {
		return null;
	}
}

export default MenuCard;
