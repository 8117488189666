/** @module awsconfig */

/**
 *  This is the configuration object for AWS Cognito Authentication
 *  @constant
 *  @type {Object}
 *  @default
 */

var clientId =
	process.env.React_App_cognito_setup === 'prod'
		? '2438hs8jc4st5m534s58vga01d'
		: '7jhbh4is6iu8s8odd7rg20bvl3';
var poolId =
	process.env.React_App_cognito_setup === 'prod'
		? 'us-east-1_11ASbFLHw'
		: 'us-east-1_0aOfyleQh';

export const awsconfig = {
	Auth: {
		Cognito: {
			userPoolClientId: clientId,
			userPoolId: poolId,
			loginWith: {
				username: 'true'
			}
		}
	}
};
