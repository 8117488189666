import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const eventsData = createAsyncThunk('GET_EVENTS', getEvents);
export const createNewEvt = createAsyncThunk('POST_EVENTS', postEvents);
export const deleteEvt = createAsyncThunk('DELETE_EVENTS', deleteEvents);
export const editOldEvt = createAsyncThunk('PUT_EVENTS', putEvents);

async function getEvents() {
	try {
		let data = await request('tournament/event');
		console.log(data);
		return Promise.resolve(data.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function postEvents(body) {
	try {
		let data = await request('tournament/event', 'post', body);
		console.log(data);
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function deleteEvents(id) {
	try {
		let data = await request(`tournament/event/${id}`, 'delete');
		console.log(data);
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function putEvents(body) {
	try {
		let data = await request('tournament/event', 'put', body);
		console.log(data);
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
