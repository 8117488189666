import React from 'react';
import { connect } from 'react-redux';
import {
	subscriptionsData,
	addSubscription,
	deleteSubscription
} from '../services/subscriptions/mysubscriptionsActions.js';
import ToggleSelector from '../components/general/ToggleSelector.js';
import DragDropItem from '../components/general/DragDropItem.js';
import DragDropList from '../components/general/DragDropList.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faCirclePlus,
	faTrash
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';

class MySubscriptionsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedCountry: ''
		};

		this.selectCountry = this.selectCountry.bind(this);
		this.addSubscription = this.addSubscription.bind(this);
		this.removeSubscription = this.removeSubscription.bind(this);
	}

	componentDidMount() {
		this.props.getSubscriptionsData().then(() => {
			this.setState({
				selectedCountry: this.props.countries[0]
			});
		});
	}

	selectCountry(event) {
		this.setState({
			selectedCountry: event.target.innerHTML
		});
	}

	addSubscription(ID) {
		const teamID = parseInt(ID);
		let teamToAdd = this.props.teamsByCountry[
			this.state.selectedCountry
		].find(x => x.id === teamID);
		this.props.addNewSubscription({
			id: teamToAdd.id,
			team_name: teamToAdd.team_name
		});
	}

	removeSubscription(ID) {
		const teamID = parseInt(ID);
		this.props.removeSubscription(teamID);
	}

	render() {
		let countrySelection = {
			selectItem: this.selectCountry,
			selectedItem: this.state.selectedCountry
		};

		let teamsToDisplay = [];
		if (this.state.selectedCountry !== '') {
			teamsToDisplay =
				this.props.teamsByCountry[this.state.selectedCountry];
			teamsToDisplay = teamsToDisplay.filter(x => {
				return (
					this.props.subscriptions.find(team => team.id === x.id) ===
					undefined
				);
			});
		} else return <p>No country selected</p>;

		return (
			<div>
				<Card>
					<Card.Header
						style={{
							fontSize: 30,
							fontWeight: '500',
							color: '#4E73DF'
						}}
					>
						My Subscriptions
					</Card.Header>
					<Card.Body>
						<Card.Title>
							Drag and Drop teams to add them to your
							subscriptions.
						</Card.Title>
						<Container>
							<Row>
								<ToggleSelector
									itemsList={this.props.countries}
									selectionObj={countrySelection}
								/>
							</Row>
							<Row>
								<Col className="align-self-center">
									<Row className="row text-center">
										<Form.Label>Available Teams</Form.Label>
									</Row>
									<Row>
										<DragDropList>
											{teamsToDisplay.map(x => (
												<DragDropItem
													key={x.id}
													text={x.team_name}
													id={x.id}
												/>
											))}
										</DragDropList>
									</Row>
								</Col>
								<Col className="text-center align-self-center">
									<FontAwesomeIcon
										icon={faArrowRight}
										size="3x"
									/>
								</Col>
								<Col>
									<Row className="row text-center">
										<Form.Label>
											Your Subscriptions
										</Form.Label>
									</Row>
									<Row className="border-bottom">
										<DragDropList
											cardDropHandler={
												this.addSubscription
											}
										>
											{this.props.subscriptions.map(x => (
												<DragDropItem
													key={x.id}
													text={x.team_name}
													id={x.id}
												/>
											))}
											<FontAwesomeIcon
												style={{ color: 'green' }}
												icon={faCirclePlus}
												size="3x"
											/>
										</DragDropList>
									</Row>
									<Row>
										<DragDropList
											style={{ border: '1px solid grey' }}
											className="text-center"
											stylePBox={{
												height: '50px',
												overflow: 'hidden'
											}}
											cardDropHandler={
												this.removeSubscription
											}
										>
											<FontAwesomeIcon
												icon={faTrash}
												size="3x"
											/>
										</DragDropList>
									</Row>
								</Col>
							</Row>
						</Container>
					</Card.Body>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.subscriptions;
	if (raw.subscriptions) {
		let rawCountries = Object.keys(raw.teamsByCountry);
		rawCountries.sort();
		return {
			countries: rawCountries,
			teamsByCountry: raw.teamsByCountry,
			subscriptions: raw.subscriptions
		};
	} else
		return {
			countries: null,
			teamsByCountry: null,
			subscriptions: null
		};
};

const mapDispatchToProps = dispatch => {
	return {
		getSubscriptionsData: () => dispatch(subscriptionsData()),
		addNewSubscription: obj => dispatch(addSubscription(obj)),
		removeSubscription: id => dispatch(deleteSubscription(id))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MySubscriptionsView);

//My subscriptions - drop items here and they should update state: drop items that are already there?

// export const fakeprops = {
//   countries: ["COL", "ARG", "ISR"],
//   teamsByCountry: {
//     COL: [{id: 1 ,text: "Ch A 1"}, {id: 2, text: "Ch C Male 1"}],
//     ARG: [{id: 3, text: "Ch D COED 2"}, {id: 4, text: "Ad Male 2"}],
//     ISR: [{id: 5, text: "Ch C Female 3"},{id: 6, text: "Ad Female 3"}]
//   }
// };
