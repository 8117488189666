import React from 'react';
import TeamSchedule from '../components/schedule/TeamSchedule';
// import MultiSelectSearchableDropdown from '../components/general/MultiSelectSearchableDropdown';
// import ReactToPrint from "react-to-print";
import { connect } from 'react-redux';
import {
	getTeamSchedule,
	getVersions
} from '../services/teamschedules/teamSchedulesActions.js';
import { Button, Form } from 'react-bootstrap';

class AdminTeamScheduleView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			modId: '',
			versions: [],
			selectedVersion: 1
		};

		this.getTeamSchedule = this.getTeamSchedule.bind(this);
	}

	componentDidMount() {
		// console.log("Getting Schedule versions...")
		this.props.getScheduleVersions().then(version => {
			console.log('Schedule Version is ', version);
			this.setState({ versions: version.payload.content });
		});
	}

	getTeamSchedule() {
		// console.log("Getting Team Schedules")
		this.props.getTeamSchedule(this.state.selectedVersion).then(() => {
			// console.log("Schedules are " , teams)
			// let teams1 = this.props.teams.filter(function (team) {
			//     return team.games.length > 0;
			//   }).map(x => x.id)
			// this.setState({versionGames: teams1})
		});
	}
	changeVersion(event) {
		this.setState({ selectedVersion: event.target.value });
	}

	render() {
		// console.log("Current Team", this.props)
		// console.log("Current Team state", this.state)

		let schedulesRender;

		if (this.props.teams) {
			let teamSchedules = Object.entries(this.props.teams);
			console.log(teamSchedules);
			teamSchedules.sort((a, b) => {
				let [, v1] = a;
				let [, v2] = b;

				return v1['title'] < v2['title']
					? -1
					: v2['title'] < v1['title']
					? 1
					: 0;
			});
			schedulesRender = teamSchedules.map(([k, v]) => {
				if (v['games'].length > 0) {
					return (
						<TeamSchedule
							key={k}
							team={v['title']}
							events={[...v['games']]}
							startTime={this.props.stime}
							endTime={this.props.etime}
							startDate={this.props.sday}
							endDate={this.props.eday}
						></TeamSchedule>
					);
				} else return <div key={k}></div>;
			});
		}

		return (
			<div>
				<Form.Select
					onChange={this.changeVersion.bind(this)}
					aria-label="Default select example"
				>
					<option>Select a version</option>
					{this.state.versions.map(x => {
						return (
							<option value={x.schedule_key} key={x.schedule_key}>
								{x.nickname}
							</option>
						);
					})}
				</Form.Select>
				<Button
					variant="primary"
					onClick={() => this.getTeamSchedule()}
				>
					Get Schedules
				</Button>
				<div ref={el => (this.componentRef = el)}>
					{schedulesRender}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.teamschedules;
	if (raw.games) {
		const teams = Object.entries(raw.games.teams).map(([k, v]) => {
			return {
				...v,
				id: k,
				games: v['games'].map(x => {
					return {
						...x,
						start: new Date(x.start),
						end: new Date(x.end)
					};
				})
			};
		});

		let stime = new Date();
		stime.setHours(...raw.games.stime.split(':').map(x => parseInt(x)));
		let etime = new Date();
		etime.setHours(...raw.games.etime.split(':').map(x => parseInt(x)));

		return {
			teams: teams,
			sday: new Date(raw.games.sday),
			eday: new Date(raw.games.eday),
			stime: stime,
			etime: etime
		};
	} else return { teams: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getTeamSchedule: id => dispatch(getTeamSchedule(id)),
		getScheduleVersions: () => dispatch(getVersions())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminTeamScheduleView);

// /schedule/teamid
// get subscribed teams and thier events
// get their events
