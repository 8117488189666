import React from 'react';
import { Button, Row } from 'react-bootstrap';
/**
 * Buttons to change or cancel creaction/changing of an event
 * @function Finalize
 * @param {Object} props
 * @param {function} props.create - function to save/create
 * @param {function} props.cancel - function to cancel changes
 * @returns {React.Component}
 */

export default function Finalize(props) {
	return (
		<Row>
			<Button variant="success" onClick={props.create}>
				Save
			</Button>
			<Button variant="danger" onClick={props.cancel}>
				Cancel
			</Button>
		</Row>
	);
}
