import React from 'react';

/**
 * @class SearchableDropdown
 * @classdesc Displays a list of items of which one can be selected.
 * Searchbar is optionally available
 * @param {Object} props
 * @param {array} props.itemsList - list of items
 * @param {string} props.selectedItems - current item selected
 * @param {function} props.toggleItem - function to call when an item is clicked on
 * @param {Object} props.selectedItemStyle - style for the selected item
 * @param {boolean} props.searchable - whether the list should be searchable or not
 * @returns {React.Component}
 */
export default class MultiSelectSearchableDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//Text that is in the searchbar
			searchText: '',
			isOpen: false
		};

		//Bind functions to this instance
		this.changeSearchText = this.changeSearchText.bind(this);
		this.filterItems = this.filterItems.bind(this);
		this.toggleDisplayDropdown = this.toggleDisplayDropdown.bind(this);
		this.toggleItem = this.toggleItem.bind(this);
	}

	toggleItem(id) {
		this.props.toggleItem(id);
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	toggleDisplayDropdown() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	//Event handler for user input
	changeSearchText(event) {
		this.setState({
			searchText: event.target.value
		});
	}

	//Filter itemsList based on search text
	filterItems() {
		const rawItems = this.props.itemsList;
		return rawItems.filter(x =>
			x.text.toLowerCase().includes(this.state.searchText.toLowerCase())
		);
	}

	render() {
		//Filter items
		const filteredItems = this.filterItems();

		//Render search bar if needed
		const searchRender = this.props.searchable ? (
			<input
				onChange={this.changeSearchText}
				value={this.state.searchText}
			></input>
		) : null;

		if (!this.state.isOpen) {
			if (this.props.selectedItems.length === 0) {
				return <p onClick={this.toggleDisplayDropdown}>Select</p>;
			} else {
				return (
					<div>
						<p onClick={this.toggleDisplayDropdown}>Select</p>
						{this.props.selectedItems.map(x => (
							<p>
								{
									this.props.itemsList.find(y => y.id === x)
										.text
								}
							</p>
						))}
					</div>
				);
			}
		} else {
			return (
				<div>
					<p onClick={this.toggleDisplayDropdown}>Select</p>
					{searchRender}
					<div
						className={this.props.selectorStyle}
						style={{ zIndex: 10 }}
					>
						{filteredItems.map(x => {
							return (
								<label
									key={x.id}
									style={{
										display: 'block'
									}}
								>
									<input
										type="checkbox"
										value={x.id}
										checked={this.props.selectedItems.includes(
											x.id
										)}
										onChange={this.toggleItem}
									/>
									{x.text}
								</label>
							);
						})}
					</div>
				</div>
			);
		}
	}
}
