import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
/**
 * @class SearchableDropdown
 * @classdesc Displays a list of items of which one can be selected.
 * Searchbar is optionally available
 * @param {Object} props
 * @param {array} props.itemsList - list of items
 * @param {string} props.selectedItem - current item selected
 * @param {function} props.selectItem - function to call when an item is clicked on
 * @param {Object} props.selectedItemStyle - style for the selected item
 * @param {boolean} props.searchable - whether the list should be searchable or not
 * @returns {React.Component}
 */
export default class SearchableDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//Text that is in the searchbar
			searchText: ''
		};

		//Bind functions to this instance
		this.changeSearchText = this.changeSearchText.bind(this);
		this.filterItems = this.filterItems.bind(this);
		this.selectItem = this.selectItem.bind(this);
	}

	//Event handler for user input
	changeSearchText(event) {
		this.setState({
			searchText: event.target.value
		});

		const filteredResults = this.props.itemsList.filter(x =>
			x.text.toLowerCase().includes(event.target.value.toLowerCase())
		);
		this.props.selectItem(filteredResults[0].id);
	}

	//Filter itemsList based on search text
	filterItems() {
		const rawItems = this.props.itemsList;
		if (this.props.searchable) {
			return rawItems.filter(x =>
				x.text
					.toLowerCase()
					.includes(this.state.searchText.toLowerCase())
			);
		} else return rawItems;
	}

	selectItem(event) {
		this.props.selectItem(event.target.value);
	}

	render() {
		//Filter items
		const filteredItems = this.filterItems();

		//Render search bar if needed
		const searchRender = this.props.searchable ? (
			<Form.Control
				type="text"
				placeholder="Search"
				onChange={this.changeSearchText}
				value={this.state.searchText}
			></Form.Control>
		) : null;

		return (
			<div className={this.props.className}>
				<Row>
					{this.props.searchable && <Col xs={2}>{searchRender}</Col>}
					<Col>
						<Form.Select
							value={this.props.selectedItem}
							onChange={this.selectItem}
						>
							{filteredItems.map(x => (
								<option key={x.id} value={x.id}>
									{x.text}
								</option>
							))}
						</Form.Select>
					</Col>
				</Row>
			</div>
		);
	}
}
