import React from 'react';
import { connect } from 'react-redux';
import { masterStandingsData } from '../services/standings/masterStandingsActions.js';
import SingleSelectList from '../components/general/SingleSelectList.js';
import PoolStandings from '../components/standings/PoolStandings.js';
import PoolGames from '../components/scores/PoolGames.js';
const d3 = require('d3-array');

/**
 * @class MasterStandingsView
 * @classdesc Shows the standings for all tournaments and teams
 * @param {Object} props
 * @param {Object[]} props.tournaments
 * @returns {React.Component}
 */
class MasterStandingsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTournament: null
		};

		this.selectTournament = this.selectTournament.bind(this);
	}

	componentDidMount() {
		this.props.getMasterStandings().then(result => {
			if (result.meta.requestStatus === 'fulfilled') {
				this.setState({
					selectedTournament:
						result.payload.tournaments[0]['tournament_id']
				});
			} else {
				window.alert('Error getting standings');
			}
		});
	}

	selectTournament(tournamentName) {
		const raw = this.props.tournaments.find(
			x => x.tournament === tournamentName
		);
		this.setState({
			selectedTournament: raw['tournament_id']
		});
	}

	render() {
		if (this.props.tournaments) {
			const selectedTournamentStyle = {
				borderRadius: '15px',
				padding: '3px 20px',
				margin: '5px',
				cursor: 'pointer',
				backgroundColor: '#1857C3',
				color: '#fff'
			};
			const selectedTournamentObj = this.props.tournaments.find(
				x => x.tournament_id === this.state.selectedTournament
			);
			let selectedItem = '';
			let standingsRender = null;
			let scoresRender = null;

			if (this.state.selectedTournament !== null) {
				selectedItem = selectedTournamentObj.tournament;
				const tournamentStandings =
					this.props.standingsByTournament[
						this.state.selectedTournament
					];
				if (tournamentStandings && tournamentStandings.length > 0) {
					if (tournamentStandings.length === 1) {
						standingsRender = tournamentStandings.map(x => (
							<PoolStandings
								key={x.header}
								{...x}
								collapsed={false}
							/>
						));
					} else {
						standingsRender = tournamentStandings.map((x, i) => {
							return (
								<PoolStandings
									key={`${x.header}${i}`}
									{...x}
									collapsed={false}
								/>
							);
						});
					}
				} else {
					const divStyle = {
						display: 'flex',
						flexDirection: 'column',
						margin: '0 1vw',
						padding: '0 1vw'
						// overflowY: 'scroll',
						// maxHeight: '74vh'
					};

					const headingStyle = {
						textAlign: 'center',
						border: 'solid 2px #5CAEFA',
						borderRadius: '15px',
						backgroundColor: '#5CAEFA',
						color: '#fff',
						padding: '3px 0',
						width: '330px',
						alignSelf: 'center'
					};

					standingsRender = (
						<div style={divStyle}>
							<p style={headingStyle}>No standings data</p>
						</div>
					);
				}

				const tournamentScores =
					this.props.scoresByTournament[
						this.state.selectedTournament
					];

				if (tournamentScores) {
					if (tournamentScores.length === 1) {
						scoresRender = tournamentScores.map(x => (
							<PoolGames
								key={x.header}
								{...x}
								collapsed={false}
							/>
						));
					} else {
						scoresRender = tournamentScores.map((x, i) => {
							return (
								<PoolGames
									key={`${x.header}${i}`}
									{...x}
									collapsed={false}
								/>
							);
						});
					}
				} else {
					const divStyle = {
						display: 'flex',
						flexDirection: 'column',
						// maxHeight: '74vh',
						// overflowY: 'scroll',
						margin: '0 1vw',
						padding: '0 1vw'
					};

					const headingStyle = {
						textAlign: 'center',
						border: 'solid 2px #5CAEFA',
						borderRadius: '15px',
						backgroundColor: '#5CAEFA',
						color: '#fff',
						padding: '3px 0',
						width: '330px',
						alignSelf: 'center'
					};

					scoresRender = (
						<div style={divStyle}>
							<p style={headingStyle}>No scores data</p>
						</div>
					);
				}
			}

			const divStyle = {
				display: 'flex',
				flexDirection: 'row'
			};

			const singleColStyle = {
				display: 'flex',
				flexDirection: 'column',
				maxHeight: '74vh',
				overflowY: 'scroll'
			};

			return (
				<div style={divStyle}>
					<SingleSelectList
						itemsList={this.props.tournaments.map(
							x => x.tournament
						)}
						selectItem={this.selectTournament}
						selectedItem={selectedItem}
						selectedItemStyle={selectedTournamentStyle}
						searchable={true}
					/>
					<div style={singleColStyle}>{standingsRender}</div>
					<div style={singleColStyle}>{scoresRender}</div>
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.masterstandings;
	if (raw.tournaments) {
		let standings = raw.standings;
		const tiebreakers = raw.tiebreakers;

		const tiebreakerSorter = (a, b) => {
			return a.order - b.order;
		};

		const formatStandings = teamstanding => {
			return {
				teamID: teamstanding.team_id,
				ranking: teamstanding.rank,
				wins: teamstanding.wins,
				losses: teamstanding.losses,
				ties: teamstanding.ties,
				teamName: teamstanding.team,
				teamShirt: teamstanding.shirt,
				qualify: teamstanding.qualify,
				medal: teamstanding.medal,
				tiebreaker_points:
					teamstanding.tiebreaker_points.sort(tiebreakerSorter)
			};
		};

		const mapPoolToStandings = ([key, val]) => {
			if (key !== '') {
				return {
					header: `${val[0].tournament}: ${key}`,
					teamStandings: val.map(formatStandings)
				};
			} else {
				return {
					header: val[0].tournament,
					teamStandings: val.map(formatStandings)
				};
			}
		};

		let formatSetScore = (
			team1_set1,
			team2_set1,
			team1_set2,
			team2_set2,
			team1_set3,
			team2_set3
		) => {
			if (team1_set3 === null)
				return `${team1_set1} - ${team2_set1} | ${team1_set2} - ${team2_set2}`;
			else
				return `${team1_set1} - ${team2_set1} | ${team1_set2} - ${team2_set2} | ${team1_set3} - ${team2_set3}`;
		};

		let formatScores = game => {
			return {
				gameID: game.game_id,
				score: `${game.score1} - ${game.score2}`,
				setScore: formatSetScore(
					game.team1_set1,
					game.team2_set1,
					game.team1_set2,
					game.team2_set2,
					game.team1_set3,
					game.team2_set3
				),
				scoreCardTop: {
					sport: game.sport,
					showRachmanusFlag: game.rachmanus_flag === 1,
					rachmanusPD: game.rachmanus_max_pd,
					showTimer: false,
					timeRemaining: false,
					gameInfo: {
						datetime: `${game.date} | ${game.time}`,
						location: game.location,
						tournament: game.tournament
					}
				},
				scoreCardBottom: {
					team1Icon: {
						imageURL: `assets/countryicons/flag-${game.flag1}.png`,
						label: game.team1
					},
					team2Icon: {
						imageURL: `assets/countryicons/flag-${game.flag2}.png`,
						label: game.team2
					},
					hasSet: game.hasSet === 1,
					coaches: game.coaches
				}
			};
		};

		const mapPoolToScores = ([key, val]) => {
			if (key !== '') {
				return {
					header: `${val[0].tournament}: ${key}`,
					games: val.map(formatScores)
				};
			} else {
				return {
					header: val[0].tournament,
					games: val.map(formatScores)
				};
			}
		};

		let scores = raw.scores;

		let standingsWithTiebreakers = standings.map(teamObj => {
			return {
				...teamObj,
				tiebreaker_points: tiebreakers.filter(
					x =>
						x.team_id === teamObj.team_id &&
						x.tournament_id === teamObj.tournament_id
				)
			};
		});

		// Scores and standings grouped by tournament id and then subgrouped by pool
		let groupedStandings = Object.fromEntries(
			d3.group(
				standingsWithTiebreakers,
				x => x.tournament_id,
				x => x.pool
			)
		);

		let groupedScores = Object.fromEntries(
			d3.group(
				scores,
				x => x.tournament_id,
				x => x.pool
			)
		);
		let scoresByTournament = {};
		let standingsByTournament = {};

		raw.tournaments
			.map(x => x.tournament_id)
			.forEach(tournamentID => {
				if (groupedStandings[tournamentID] === undefined)
					standingsByTournament[tournamentID] = [];
				else
					standingsByTournament[tournamentID] = Array.from(
						groupedStandings[tournamentID],
						mapPoolToStandings
					);

				if (groupedScores[tournamentID] === undefined)
					scoresByTournament[tournamentID] = [];
				else
					scoresByTournament[tournamentID] = Array.from(
						groupedScores[tournamentID],
						mapPoolToScores
					);
			});

		return {
			tournaments: raw.tournaments,
			standingsByTournament: standingsByTournament,
			scoresByTournament: scoresByTournament
		};
	} else {
		return {
			tournaments: null,
			standingsByTournament: null,
			scoresByTournament: null
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getMasterStandings: () => dispatch(masterStandingsData())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MasterStandingsView);
