import React from 'react';

/**
 * Displays a booking slot for a team/location
 * @function BookingInfo
 * @param {Object} props
 * @param {string} props.title - booking title
 * @param {string} props.date - date of booking slot
 * @param {string} props.time - time of booking slot
 * @returns {React.Component}
 */
export default function BookingInfo(props) {
	const headerStyle = {
		margin: '0',
		padding: '2px 0',
		fontWeight: 'bold',
		color: 'black'
	};

	const textStyle = {
		margin: '0',
		padding: '0'
		// color: '#5CAEFA'
	};

	return (
		<div>
			<p style={headerStyle}>{props.title}</p>
			<p style={textStyle}>{props.displaydate}</p>
			<p style={textStyle}>{props.displaytime}</p>
		</div>
	);
}

// export const fakeprops = {
// 	title: 'Ad Male ARG 1',
// 	date: 'Fri, 25-Nov',
// 	time: '3:30pm'
// };
