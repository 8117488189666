/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as dates from 'date-arithmetic';
import { Calendar, Views, momentLocalizer, Navigate } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './TeamSchedule.css';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { getCurrentYear } from '../../global/utils';
// import mgSponsors from './../../../public/assets/pageicons/maccabi-games-sponsors.png'

const localizer = momentLocalizer(moment);

class MyWeek extends React.Component {
	render() {
		//console.log(this.props)
		let { date, endDate } = this.props;
		let range = MyWeek.range(date, endDate);

		return <TimeGrid {...this.props} range={range} eventOffset={15} />;
	}
}

MyWeek.range = (date, endDate) => {
	//console.log(endDate)
	let start = date;
	let end = endDate;

	let current = start;
	let range = [];

	while (dates.lte(current, end, 'day')) {
		if (
			current ===
			'Fri Nov 19 2021 00:00:00 GMT-0500 (Eastern Standard Time)'
		) {
			current = dates.add(current, 1, 'day');
			continue;
		}
		range.push(current);
		current = dates.add(current, 1, 'day');
	}

	return range;
};

MyWeek.navigate = (date, action) => {
	switch (action) {
		case Navigate.PREVIOUS:
			return date;

		case Navigate.NEXT:
			return date;

		default:
			return date;
	}
};

MyWeek.title = date => {
	return `Maccabi Games Schedule`;
};

export default class TeamSchedule extends React.Component {
	constructor() {
		super();

		this.myReference = React.createRef();
	}

	render() {
		const dayFormat = (date, culture, localizer) => {
			return localizer.format(date, 'ddd[,]D[-]MMM', culture);
		};
		// console.log("End Date is ",this.props.endDate)
		// console.log("Start Date", this.props.startDate)
		return (
			<div className="printable-schedule">
				<Row
					className="row text-center text-light bg-dark headerTitle"
					style={{ backgroundColor: 'black' }}
				>
					<Col>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								alignItems: 'center'
							}}
						>
							<img
								alt="Maccabi Games Logo"
								src="assets/pageicons/mar_white.png"
								className="schedule-image"
							></img>
							<h1 className="text-light textTitle">
								Maccabi Games {getCurrentYear()}
							</h1>
							<img
								alt="Maccabi Games Logo"
								src="assets/pageicons/safra_white.png"
								className="schedule-image"
							></img>
							<img
								alt="Maccabi Games Logo"
								src="assets/pageicons/jerry_white.png"
								className="schedule-image"
							></img>

							<h1 className="text-light textTitle">
								{this.props.team}
							</h1>
							<img
								alt="Maccabi Games Logo"
								src="MG24LOGO_no_bg.png"
								className="schedule-image"
							></img>
						</div>
					</Col>
				</Row>

				<Calendar
					toolbar={false}
					localizer={localizer}
					defaultDate={this.props.startDate}
					defaultView={Views.WEEK}
					events={this.props.events}
					dayLayoutAlgorithm={'no-overlap'}
					step={50}
					timeslots={1}
					views={{ month: true, day: true, week: MyWeek }}
					formats={{
						dayFormat,
						eventTimeRangeFormat: () => null
					}}
					components={{ event: CustomEvent }}
					eventPropGetter={(event, start, end, isSelected) => {
						return {
							className: 'event-style',
							style: {
								backgroundColor:
									event.type === 'normal'
										? event.background
										: 'grey'
							}
						};
					}}
					min={this.props.startTime}
					max={this.props.endTime}
					endDate={this.props.endDate}
				/>
				<p
					style={{
						position: 'relative',
						bottom: '30px',
						left: '200px',
						fontSize: '9px'
					}}
				>
					Printed on <br />
					{moment().format('MM-DD h:mm a')}
				</p>
			</div>
		);
	}
}

class CustomEvent extends React.Component {
	render() {
		return (
			<div
				style={{
					alignContent: 'center',
					alignItems: 'center',
					display: 'flex'
				}}
			>
				<p
					className="eventText"
					style={{
						fontSize: this.props.event.text.includes('|')
							? '6px'
							: '9px',
						fontWeight: 'bold',
						color: this.props.event.textColor
							? this.props.event.textColor
							: 'white',
						textAlign: 'center',
						flex: 1,
						alignItems: 'center',
						justifyContent: 'center',
						lineHeight: 1.3
					}}
				>
					{this.props.event.text}
				</p>
			</div>
		);
	}
}
