import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const masterStandingsData = createAsyncThunk(
	'GET_MASTER_STANDINGS',
	getMasterStandings
);

async function getMasterStandings() {
	try {
		let tournaments = await request('tournament');
		let standings = await request('standings');
		let scores = await request('game/standingscores');
		const outputObj = {
			tournaments: tournaments.data,
			standings: standings.data.standingsInfo,
			tiebreakers: standings.data.tiebreakerInfo,
			scores: scores.data
		};
		return Promise.resolve(outputObj);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
