import React from 'react';
import { Button, Card } from 'react-bootstrap';

export default function NewFeatReqView(props) {
	return (
		<Card>
			<Card.Header
				style={{ fontSize: 30, fontWeight: '500', color: '#4E73DF' }}
			>
				New Feature Request
			</Card.Header>
			<Card.Body>
				<Button href="mailto:josephl@maccabi.org?subject=New Feature Request">
					Submit New Feature Request
				</Button>
			</Card.Body>
		</Card>
	);
}
