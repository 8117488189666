const environment_setup = process.env.React_App_cognito_setup;

const cloudResourceOptions = {
	prod: {
		databaseSchemaEndpoint:
			'https://wa7p9zf073.execute-api.us-east-1.amazonaws.com/v1'
	},
	dev: {
		databaseSchemaEndpoint:
			'https://656wxd7c3k.execute-api.us-east-1.amazonaws.com/v1'
	}
};

export const cloudResources = cloudResourceOptions[environment_setup];
