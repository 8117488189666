import React from 'react';
import GameCell from './GameCell.js';
import { cleanTime } from '../../global/utils.js';

/**
 * Displays the schedule of games for a single time
 * @function StaticScheduleRow
 * @param {Object} props
 * @param {string} props.date - date of game
 * @param {string} props.time - starting time of game
 * @param {Object[]} props.firstRow - each element is props for {@link GameCell} except rowType
 * @param {Object[]} props.secondRow - each element is props for {@link GameCell} except rowType
 * @returns {React.Component}
 */
export default function StaticScheduleRow(props) {
	/* Returns two rows of games
	 */
	return [
		<tr key={`${props.date};${props.time};firstrow`}>
			<td>{cleanTime(props.time)}</td>
			{props.firstRow.map((x, i) => {
				//Loop over each location in the row. Show a game if there is one
				if (x !== null)
					return <GameCell key={x.game_id} rowType={'top'} {...x} />;
				else
					return (
						<GameCell
							key={`${props.date};${props.time};firstrow${i}`}
							rowType={'top'}
							empty={true}
						/>
					);
			})}
		</tr>,
		<tr key={`${props.date};${props.time};secondrow`}>
			<td style={{ color: 'transparent' }}>{cleanTime(props.time)}</td>
			{props.secondRow.map((x, i) => {
				//Loop over each location in the row. Show a game if there is one
				if (x !== null)
					return (
						<GameCell key={x.game_id} rowType={'bottom'} {...x} />
					);
				else
					return (
						<GameCell
							key={`${props.date};${props.time};secondrow${i}`}
							rowType={'bottom'}
							empty={true}
						/>
					);
			})}
		</tr>
	];
}
