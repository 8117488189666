import React from 'react';
/**
 * Displays a game
 * @function GameCell
 * @param {Object} props
 * @param {string} props.date - date of game
 * @param {string} props.time - starting time of game
 * @param {int} props.game_id - unique identifier for game
 * @param {string} props.background - color corresponding to tournament
 * @param {string} props.textcolor - color corresponding to tournament
 * @param {string} props.rowType - "top" or "bottom", indicating which row
 * 									this cell belongs to within a timeslot
 * @param {boolean} props.empty - whether there is a game in this cell or not
 * @param {string} props.text - description for game
 * @param {int} props.bold - 1 or 0. Whether the text should be bold or not
 * @param {int} props.strike - 1 or 0. Whether there should be a strikethrough or not
 * @param {string} props.sport - Sport that the game is for
 * @returns {React.Component}
 */
export default function GameCell(props) {
	let cellStyle = {
		borderTop: '.01em solid black',
		borderLeft: '.01em solid black',
		borderRight: '.01em solid black',
		borderBottom: '.01em dashed grey',
		// padding: '0px',
		fontSize: '0.5vw',
		backgroundColor: props.background,
		color: props.textcolor
	};

	//Style border so that solid borders distinguish timeslots
	if (props.rowType === 'top') {
		cellStyle.borderTop = '.01em solid black';
		cellStyle.borderBottom = '.01em dashed grey';
	} else {
		cellStyle.borderBottom = '.01em solid black';
		cellStyle.borderTop = '.01em dashed grey';
	}

	//Check if there is no game
	if (props.empty) return <td style={cellStyle}></td>;
	else {
		let displayText = props.text;

		//Apply formatting to text
		if (props.bold === 1) cellStyle.fontWeight = 'bold';
		if (props.sport === 'Kickball') displayText = displayText.toLowerCase();
		if (props.strike === 1) cellStyle.textDecoration = 'line-through';

		return <td style={cellStyle}>{displayText}</td>;
	}
}
