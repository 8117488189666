import React from 'react';
// import GoogleLogin from 'react-google-login';
import { Oval } from 'react-loader-spinner';
import SearchBar from './../../components/SearchBar';
import GoogleLoginButton from './GoogleLogin';

export default class FamilyCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	selectToggle(key, event) {
		this.setState({
			[key]: event.target.innerHTML
		});
	}

	render() {
		let modalStyle = {
			display: 'flex',
			flexDirection: 'column',
			position: 'fixed',
			backgroundColor: 'white',
			border: '1px solid black',
			top: '7vh',
			left: '50vw',
			padding: '70px',
			zIndex: '5',
			borderRadius: '15px',
			width: '100',
			height: '100',
			color: 'black',
			boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
		};

		let blackButton = {
			backgroundColor: 'black',
			color: 'white',
			borderRadius: '15px',
			marginBottom: '10px',
			cursor: 'pointer'
		};

		let whiteButton = {
			backgroundColor: 'white',
			color: 'black',
			borderRadius: '15px',
			marginBottom: '20px',
			cursor: 'pointer'
		};

		let headerStyle = {
			textAlign: 'center',
			paddingBottom: '30px'
		};

		return (
			<div style={modalStyle}>
				<h1 style={headerStyle}>Family Card</h1>

				<div>
					<div>
						<a>First Family Number (ex. 101)</a>
						<SearchBar
							changeHandler={this.props.changeHandler.bind(
								null,
								'firstFamily'
							)}
							text={this.state.searchText}
						/>
					</div>
					<div>
						<a>Last Family Number (ex. 110)</a>
						<SearchBar
							changeHandler={this.props.changeHandler.bind(
								null,
								'lastFamily'
							)}
							text={this.state.searchText}
						/>
					</div>
					<GoogleLoginButton
						onSuccess={response =>
							this.props.googleSuccess(response)
						}
					/>
					{/* <GoogleLogin
                        clientId="269247350564-9u31ddvc2er43t3ph9e5fmnnloat0fu8.apps.googleusercontent.com"
                        buttonText="Create Family Cards"
                        onSuccess={(response) => this.props.googleSuccess(response)}
                        // onFailure={responseGoogle}
                        scope="https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/script.external_request https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/spreadsheets"
                        cookiePolicy={'single_host_origin'}
                    /> */}
					{this.props.loading && (
						<Oval
							color="#000000"
							height={50}
							width={50}
							timeout={300000} //3 secs
						/>
					)}
				</div>
				<button style={whiteButton} onClick={this.props.toggleModal}>
					Cancel
				</button>
			</div>
		);
	}
}
