import { createReducer } from '@reduxjs/toolkit';
import {
	awardpointsviewinfo,
	pointsbyevent,
	addpointstoevent,
	deletepoints
} from './awardmedalsActions';

const awardmedalsReducer = createReducer(
	{ addPointsRequestStatus: 'fulfilled' },
	builder => {
		builder.addCase(awardpointsviewinfo.pending.type, (state, action) => ({
			...state,
			requestStatus: 'pending'
		}));

		builder.addCase(awardpointsviewinfo.fulfilled.type, (state, action) => {
			return {
				...state,
				requestStatus: 'fulfilled',
				...action.payload
			};
		});

		builder.addCase(awardpointsviewinfo.rejected.type, (state, action) => ({
			...state,
			requestStatus: 'error'
		}));

		builder.addCase(pointsbyevent.pending.type, (state, action) => ({
			...state,
			requestStatus: 'pending'
		}));

		builder.addCase(pointsbyevent.fulfilled.type, (state, action) => {
			let temp = state.points
				? JSON.parse(JSON.stringify(state.points))
				: [];
			temp.push(...action.payload);

			return {
				...state,
				requestStatus: 'fulfilled',
				points: temp
			};
		});

		builder.addCase(pointsbyevent.rejected.type, (state, action) => ({
			...state,
			requestStatus: 'error'
		}));

		builder.addCase(addpointstoevent.pending.type, (state, action) => ({
			...state,
			addPointsRequestStatus: 'pending'
		}));

		builder.addCase(addpointstoevent.fulfilled.type, (state, action) => {
			console.log(action.payload);
			let temp = state.points
				? JSON.parse(JSON.stringify(state.points))
				: [];
			temp = temp.filter(x => x.event_id !== action.payload[0].event_id);
			temp.push(...action.payload);

			return {
				...state,
				addPointsRequestStatus: 'fulfilled',
				points: temp
			};
		});

		builder.addCase(addpointstoevent.rejected.type, (state, action) => ({
			...state,
			addPointsRequestStatus: 'error'
		}));

		builder.addCase(deletepoints.pending.type, (state, action) => ({
			...state,
			deletePointsRequestStatus: 'pending'
		}));

		builder.addCase(deletepoints.fulfilled.type, (state, action) => {
			let temp = state.points
				? JSON.parse(JSON.stringify(state.points))
				: [];
			console.log(action.payload);
			temp = temp.filter(x => x.points_id !== action.payload);

			return {
				...state,
				deletePointsRequestStatus: 'fulfilled',
				points: temp
			};
		});

		builder.addCase(deletepoints.rejected.type, (state, action) => ({
			...state,
			deletePointsRequestStatus: 'error'
		}));
	}
);

export default awardmedalsReducer;
