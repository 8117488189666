import React from 'react';
import { connect } from 'react-redux';
import {
	addMoveChange,
	addSwapChange
} from '../../services/colorchart/colorchartActions';

function isLocationValid(sportsByLoc, sport, loc) {
	return sportsByLoc[loc].map(x => x.sport).includes(sport);
}

/**
 * Displays a gameslot in the color chart
 * @function GameSlot
 * @param {Object} props
 * @param {string} props.date - date of gameslot
 * @param {string} props.time - starting time of gameslot
 * @param {string} props.rowType - "top" or "bottom", indicating which row
 * 									this cell belongs to within a timeslot
 * @returns {React.Component}
 */
function GameSlot(props) {
	let cellStyle = {
		borderTop: '.01em solid black',
		borderLeft: '.01em solid black',
		borderRight: '.01em solid black',
		borderBottom: '.001em dashed grey',
		padding: '0px',
		fontSize: '0.5vw'
		// cursor: 'pointer'
		// background: '#505451'
	};

	if (props.filtered) cellStyle.background = '#505451';

	//Style border so that solid borders distinguish timeslots
	if (props.rowType === 'top') {
		cellStyle.borderTop = '.01em solid black';
		cellStyle.borderBottom = '.001em dashed grey';
	} else {
		cellStyle.borderBottom = '.01em solid black';
		cellStyle.borderTop = '.001em dashed grey';
	}

	const dropHandler = e => {
		e.preventDefault();
		const gameInfo = JSON.parse(e.dataTransfer.getData('gameInfo'));
		if (props.children !== null) {
			if (props.children.props.game_id !== gameInfo.game_id) {
				console.log('Game here already: swap games');

				const game1LocValid = isLocationValid(
					props.sportsByLoc,
					gameInfo.sport,
					props.children.props.location
				);
				const game2LocValid = isLocationValid(
					props.sportsByLoc,
					props.children.props.sport,
					gameInfo.location
				);
				if (game1LocValid && game2LocValid) {
					props.swapGames(props.children.props, gameInfo);
				} else {
					props.updateMessage(
						'One of two games cannot be played in desired location'
					);
				}
			}
		} else {
			console.log('Move game');

			if (
				isLocationValid(
					props.sportsByLoc,
					gameInfo.sport,
					props.location
				)
			) {
				props.moveGame(gameInfo, {
					date: props.date,
					location: props.location,
					time: props.time
				});
			} else {
				props.updateMessage(
					'INVALID LOCATION - The selected game cannot be played on the desired location. Please try again.'
				);
			}
		}
	};

	const preventDefaultDrop = e => e.preventDefault();
	let newGameHandler = null;
	if (props.children === null && !props.filtered && !props.summonMode) {
		newGameHandler = props.createGameHandler.bind(null, {
			date: props.date,
			location: props.location,
			time: props.time
		});
	}

	return (
		<td
			onDrop={dropHandler}
			onDragOver={preventDefaultDrop}
			onDragEnter={preventDefaultDrop}
			onDoubleClick={newGameHandler}
			style={cellStyle}
		>
			{props.children}
		</td>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		moveGame: (game, slot) => dispatch(addMoveChange(game, slot)),
		swapGames: (game1, game2) => dispatch(addSwapChange(game1, game2))
	};
};

export default connect(null, mapDispatchToProps)(GameSlot);
