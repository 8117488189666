import React from 'react';
import { Link } from 'react-router-dom';
import {
	Modal,
	Button,
	Container,
	Row,
	Col,
	Form,
	ListGroup
} from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default class EditFamilyArea extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.changeHandler = this.changeHandler.bind(this);
		this.selectToggle = this.selectToggle.bind(this);
		this.unregisterFamily = this.unregisterFamily.bind(this);
	}
	componentDidMount() {
		console.log('Did Mount', this.props);
		this.setState({
			firstName: this.props.firstName,
			lastName: this.props.lastName,
			phoneNum: this.props.phone,
			email: this.props.email,
			isMember: this.props.isMember,
			memberNumber: this.props.memberNumber,
			hasPaid: this.props.hasPaid,
			registrationID: this.props.registrationId
		});
	}
	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	unregisterFamily() {
		let result = window.confirm(
			'Are you sure you want to unregister this family?'
		);
		if (result) {
			this.props.unregisterFamily(this.state.registrationID);
		}
	}

	selectToggle(key, event) {
		this.setState({
			[key]: !this.state[key]
		});
	}

	render() {
		let fieldStyle = {
			marginBottom: '3px',
			fontWeight: 'bold'
		};

		let grayText = {
			color: 'gray'
		};

		return (
			<Modal show={true} onHide={this.props.cancelHandler}>
				<Modal.Header closeButton>
					<Modal.Title>Family Info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Form>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.firstName}
											onChange={this.changeHandler.bind(
												null,
												'firstName'
											)}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.lastName}
											onChange={this.changeHandler.bind(
												null,
												'lastName'
											)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Form.Group className="mb-3">
									<Form.Label>Phone Number</Form.Label>
									<Form.Control
										type="text"
										placeholder=""
										value={this.state.phoneNum}
										onChange={this.changeHandler.bind(
											null,
											'phoneNum'
										)}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="text"
										placeholder=""
										value={this.state.email}
										onChange={this.changeHandler.bind(
											null,
											'email'
										)}
									/>
								</Form.Group>
								<p style={fieldStyle}>Total Cost</p>
								<p style={grayText}>{this.props.totalCost}</p>
								{/* <p style={fieldStyle}>Has Paid</p> */}
							</Row>
							<Row>
								<Col className="align-self-center">
									<Form.Switch
										id="custom-switch"
										label="Member"
										onChange={this.selectToggle.bind(
											null,
											'isMember'
										)}
										checked={this.state.isMember}
									/>
									<Form.Switch
										id="custom-switch"
										label="Has Paid"
										onChange={this.selectToggle.bind(
											null,
											'hasPaid'
										)}
										checked={this.state.hasPaid}
									/>
								</Col>
								<Col className="align-self-center">
									<Form.Group className="mb-3">
										<Form.Label>Member Number</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={
												this.state.memberNumber
													? this.state.memberNumber
													: ''
											}
											onChange={this.changeHandler.bind(
												null,
												'memberNumber'
											)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<p style={fieldStyle}>Registrants</p>
								<ListGroup>
									{this.props.registrants.map(x => {
										return (
											<ListGroup.Item>
												<Link
													to="/registrantinfo"
													state={{
														openUser: x.registrantId
													}}
												>
													<p
														key={x.name}
														style={grayText}
													>
														{x.name}
													</p>
												</Link>
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							</Row>
						</Form>
					</Container>

					<Modal.Footer>
						<Button
							variant="primary"
							onClick={this.props.saveFamily.bind(
								null,
								this.state
							)}
						>
							Save
						</Button>
						<Button
							variant="secondary"
							onClick={this.props.cancelHandler}
						>
							Cancel
						</Button>
						<Button
							variant="danger"
							onClick={this.unregisterFamily}
						>
							Unregister Family
						</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal>
		);
	}
}
