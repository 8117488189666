import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const registrantchanges = createAsyncThunk(
	'GET_REGISTRANT_CHANGES',
	getRegistrantChanges
);

async function getRegistrantChanges() {
	try {
		let result = await request('user/registrants/changes');
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
