import React from 'react';
import { connect } from 'react-redux';
import { moveGameToTrash } from '../../services/colorchart/colorchartActions.js';

/**
 * Displays a gameslot in the color chart
 * @function Trashbin
 * @param {Object} props
 * @param {string} props.date - date of gameslot
 * @param {string} props.time - starting time of gameslot
 * @param {string} props.rowType - "top" or "bottom", indicating which row
 * 									this cell belongs to within a timeslot
 * @returns {React.Component}
 */
function TrashBin(props) {
	const dropHandler = e => {
		e.preventDefault();
		const gameInfo = JSON.parse(e.dataTransfer.getData('gameInfo'));
		props.moveGameToTrash(gameInfo);
	};

	const preventDefaultDrop = e => e.preventDefault();

	const trashbin = {
		display: 'flex',
		flexGrow: '1'
		// pointerEvents: 'none'
	};

	const icon = {
		alignSelf: 'flex-end',
		marginLeft: '20px',
		width: '40%'
	};

	return (
		<div
			style={trashbin}
			// onDrop={dropHandler}
			// onDragOver={preventDefaultDrop}
			// onDragEnter={preventDefaultDrop}
		>
			{/* <p>Multi-Move Clipboard</p> */}
			<img
				src="/assets/icons/trashcan.png"
				alt="Trash Bin"
				style={icon}
				onDrop={dropHandler}
				onDragOver={preventDefaultDrop}
				onDragEnter={preventDefaultDrop}
			></img>

			{/* <svg  style={icon} viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"  id="Icons_Garbage_M" overflow="hidden"><g id="Icons"><path d="M81 22C80.9934 19.7936 79.2064 18.0066 77 18L59 18 59 15C58.9967 12.2399 56.7601 10.0033 54 10L42 10C39.2399 10.0033 37.0033 12.2399 37 15L37 18 19 18C16.7936 18.0066 15.0066 19.7936 15 22L15 28 19.049 28 21.809 82.254C21.9442 84.9123 24.1383 86.9986 26.8 87L69.2 87C71.8625 86.9997 74.0577 84.9131 74.193 82.254L76.951 28 81 28ZM39 15C39 13.3431 40.3431 12 42 12L54 12C55.6569 12 57 13.3431 57 15L57 18 39 18ZM72.194 82.152C72.1131 83.7469 70.7969 84.9989 69.2 85L26.8 85C25.2007 85.0021 23.881 83.7492 23.8 82.152L21.052 28 74.952 28ZM79 26 17 26 17 22C17 20.8954 17.8954 20 19 20L77 20C78.1046 20 79 20.8954 79 22Z" stroke="#FF0000" stroke-width="2.34737" fill="#FFFFFF"/><path d="M36 78 36.05 78C36.6016 77.9731 37.027 77.5041 37.0001 76.9524 37 76.952 37 76.9515 37 76.951L35 35.951C34.9724 35.3987 34.5023 34.9734 33.95 35.001 33.3977 35.0286 32.9724 35.4987 33 36.051L35 77.051C35.0272 77.5833 35.467 78.0007 36 78Z" stroke="#FF0000" stroke-width="2.34737" fill="#FFFFFF"/><path d="M48 78C48.5523 78 49 77.5523 49 77L49 36C49 35.4477 48.5523 35 48 35 47.4477 35 47 35.4477 47 36L47 77C47 77.5523 47.4477 78 48 78Z" stroke="#FF0000" stroke-width="2.34737" fill="#FFFFFF"/><path d="M59.951 78 60 78C60.5337 78.0006 60.9739 77.5821 61 77.049L63 36.049C63.0276 35.4967 62.6023 35.0266 62.05 34.999 61.4977 34.9714 61.0276 35.3967 61 35.949L59 76.949C58.9712 77.5005 59.395 77.971 59.9465 77.9998 59.948 77.9998 59.9495 77.9999 59.951 78Z" stroke="#FF0000" stroke-width="2.34737" fill="#FFFFFF"/></g></svg> */}
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		moveGameToTrash: game => dispatch(moveGameToTrash(game))
	};
};

export default connect(null, mapDispatchToProps)(TrashBin);
