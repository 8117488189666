import React from 'react';
import ScoreCardTop from './ScoreCardTop.js';
import ScoreCardBottom from './ScoreCardBottom.js';
import ScoreChangeRequest from './ScoreChangeRequest.js';

/**
 * @class ScoreChangeRequestCard
 * @classdesc Displays a scorecard for a game
 * @param {Object} props
 * @param {int} props.gameID - unique ID for the game. Used as key for lists of scorecards
 * @param {string} props.score - score of the game
 * @param {string} [props.setScore] - scores of all sets
 * @param {Object} props.scoreCardTop - props for {@link ScoreCardTop}
 * @param {Object} props.scoreCardBottom
 * @param {Object} props.scoreCardBottom.team1Icon - props for {@link LabeledIcon}
 * @param {Object} props.scoreCardBottom.team2Icon - props for {@link LabeledIcon}
 * @param {boolean} props.scoreCardBottom.hasSet - whether the game has set scores
 * @param {Object[]} props.scoreAlternates - score change requests for the game score.
 * Each element is props for {@link ScoreChangeRequest}, without acceptHandler and rejectHandler
 * @param {Object[]} [props.setScoreAlternates] - score change requests for the set score.
 * Each element is props for {@link ScoreChangeRequest}, without acceptHandler and rejectHandler
 * @param {function(request_id)} props.acceptScoreChange - callback function for when a user
 * chooses to accept the score change request. The scoreChangeID will be the parameter
 * @param {function(request_id)} props.rejectScoreChange - callback function for when a user
 * chooses to reject the score change request. The scoreChangeID will be the parameter
 * @returns {React.Component}
 */
export class ScoreChangeRequestCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = { mode: 'game' };
		this.toggleScore = this.toggleScore.bind(this);
	}

	toggleScore() {
		if (this.state.mode === 'game') {
			this.setState({ mode: 'set' });
		} else {
			this.setState({ mode: 'game' });
		}
	}

	render() {
		let scoreCardBottomProps = this.props.scoreCardBottom;

		scoreCardBottomProps['mode'] = this.state.mode;
		scoreCardBottomProps['clickHandler'] = this.toggleScore;
		scoreCardBottomProps['score'] =
			this.state.mode === 'game' ? this.props.score : this.props.setScore;

		const cardStyle = {
			textAlign: 'center',
			backgroundColor: '#F9F9F9',
			height: '220px',
			width: '350px',
			margin: '3.5% 30px',
			borderRadius: '15px',
			boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
		};

		let scoreAlternates = this.props.alternateScores.map(x => {
			return { ...x, scoretext: x.score };
		});
		let setScoreAlternates = this.props.alternateScores.map(x => {
			return { ...x, scoretext: x.setScore };
		});

		let displayRequests =
			this.state.mode === 'game' ? scoreAlternates : setScoreAlternates;

		return (
			<div style={cardStyle}>
				<ScoreCardTop {...this.props.scoreCardTop} />
				<ScoreCardBottom {...this.props.scoreCardBottom} />
				{displayRequests.map((element, i) => (
					<ScoreChangeRequest
						key={`${this.props.gameID} ${this.state.mode} ${i}`}
						{...element}
						mode={this.state.mode}
						acceptHandler={this.props.acceptScoreChange}
						rejectHandler={this.props.rejectScoreChange}
					/>
				))}
			</div>
		);
	}
}

// export const fakeprops = {
// 	score: '2 - 1',
// 	setScore: '15 - 2 | 13 - 15 15 - 10',
// 	scoreCardTop: {
// 		sport: 'Volleyball',
// 		gameInfo: {
// 			datetime: 'Thursday, 11/28 | 09:40 am',
// 			location: 'Hillel - Volleyball Out',
// 			tournament: 'Ch D Male - Volleyball'
// 		},
// 		showRachmanusFlag: false,
// 		showTimer: false,
// 		timeRemaining: false
// 	},
// 	scoreCardBottom: {
// 		team1Icon: {
// 			label: 'ARG 2',
// 			imageURL: 'assets/countryicons/flag-Argentina.png'
// 		},
// 		team2Icon: {
// 			label: 'VEN 2',
// 			imageURL: 'assets/countryicons/flag-Venezuela.png'
// 		},
// 		hasSet: true
// 	},
// 	scoreAlternates: [
// 		{
// 			numReports: 3,
// 			alternateScore: "7 - 2",
// 			scoreChangeID: 81
// 		},
// 		{
// 			numReports: 1,
// 			alternateScore: "7 - 1",
// 			scoreChangeID: 87
// 		},
// 		{
// 			numReports: 12,
// 			alternateScore: "7 - 3",
// 			scoreChangeID: 83
// 		}
// 	],
// 	setScoreAlternates: [
// 		{
// 			numReports: 2,
// 			alternateScore: "21 - 15 | 14 - 21 | 21 - 6",
// 			scoreChangeID: 76
// 		},
// 		{
// 			numReports: 3,
// 			alternateScore: "21 - 15 | 13 - 21 | 21 - 6",
// 			scoreChangeID: 75
// 		}
// 	],
// 	acceptScoreChange: (id) => console.log(`acceping ${id}`),
// 	rejectScoreChange: (id) => console.log(`rejcting ${id}`)
// };
