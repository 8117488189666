import React from 'react';
import { DynamicTableView } from '../components/general/DynamicTable';
import { connect } from 'react-redux';
import { registrantchanges } from '../services/registrantchanges/registrantchangesActions.js';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { downloadExcel } from '../global/utils.js';

class RegistrantChangesView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchText: ''
		};

		this.changeHandler = this.changeHandler.bind(this);
	}

	componentDidMount() {
		this.props.getRegistrantChanges();
	}

	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	render() {
		//
		console.log(this.props.changes);
		if (this.props.changes) {
			let familiesToShow = this.props.changes;

			if (this.state.searchText !== '') {
				let text = this.state.searchText.toLowerCase();
				familiesToShow = familiesToShow.filter(post => {
					const name = post.name.toLowerCase();
					return `${name}`.includes(text);
				});
			}

			return (
				<div>
					<Container>
						<Row>
							<Col
								style={{
									disply: 'flex',
									justifyContent: 'left'
								}}
							>
								<Form noValidate>
									<Form.Group
										className="mb-1"
										style={{ width: '50%' }}
									>
										<Form.Control
											className="me-sm-2"
											placeholder="Search"
											aria-label=""
											aria-describedby="basic-addon1"
											value={this.state.searchText}
											onChange={this.changeHandler.bind(
												null,
												'searchText'
											)}
										/>
									</Form.Group>
								</Form>
							</Col>
							<Col>
								<Button
									style={{ float: 'right' }}
									variant="outline-primary"
									onClick={() => {
										downloadExcel(
											'registrantchanges',
											'registrantchangelog'
										);
									}}
								>
									Download as Excel
								</Button>
							</Col>
						</Row>

						<DynamicTableView
							data={familiesToShow}
							name="registrantchanges"
							titles={[
								'id',
								'change_made',
								'old_name',
								'name',
								'old_country',
								'country',
								'old_category_gender',
								'category_gender',
								'old_team',
								'team',
								'old_registered',
								'registered',
								'old_gender',
								'gender',
								'old_grade',
								'grade',
								'updated_by'
							]}
						/>
					</Container>
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.registrantchanges;
	if (raw.changes) {
		return {
			changes: raw.changes
		};
	} else return { changes: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getRegistrantChanges: () => dispatch(registrantchanges())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegistrantChangesView);
