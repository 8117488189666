import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const poolsData = createAsyncThunk('GET_POOLS', getPools);

async function getPools() {
	try {
		let data = await request('tournament/pools');
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
