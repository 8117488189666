import { createReducer } from '@reduxjs/toolkit';
import { poolsData } from './poolsActions.js';

const poolsReducer = createReducer({}, builder => {
	builder.addCase(poolsData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(poolsData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			data: action.payload
		};
	});

	builder.addCase(poolsData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default poolsReducer;
