import React from 'react';
import LinkedIcon from './general/LinkedIcon.js';

/**
 * @class HorizontalDeck
 * @classdesc Shows children cards in a scrollable horizontal deck
 * @param {Object} props
 * @param {number} props.cardsToShow - number of cards to show at a time
 * @returns {React.Component}
 */
export default class HorizontalDeck extends React.Component {
	constructor(props) {
		super(props);
		this.state = { startIndex: 0 };
		this.slideLeft = this.slideLeft.bind(this);
		this.slideRight = this.slideRight.bind(this);
	}

	slideRight() {
		this.setState({ startIndex: this.state.startIndex + 1 });
	}

	slideLeft() {
		this.setState({ startIndex: this.state.startIndex - 1 });
	}

	render() {
		let leftIconProps;
		let rightIconProps;
		if (this.state.startIndex > 0) {
			leftIconProps = {
				clickHandler: this.slideLeft,
				// name: 'angle left',
				// disabled: false,
				// link: true
				imageURL: 'assets/icons/leftarrow_blue.png'
			};
		} else {
			leftIconProps = {
				clickHandler: null,
				// name: 'angle left',
				// disabled: true,
				// link: false
				imageURL: 'assets/icons/leftarrow_grey.png'
			};
		}

		if (
			this.state.startIndex <
			this.props.children.length - this.props.cardsToShow
		) {
			rightIconProps = {
				clickHandler: this.slideRight,
				// name: 'angle right',
				// disabled: false,
				// link: true
				imageURL: 'assets/icons/rightarrow_blue.png'
			};
		} else {
			rightIconProps = {
				clickHandler: null,
				// name: 'angle right',
				// disabled: true,
				// link: false
				imageURL: 'assets/icons/rightarrow_grey.png'
			};
		}

		let filteredCards = this.props.children.slice(
			this.state.startIndex,
			this.state.startIndex + this.props.cardsToShow
		);

		const divStyle = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '-15px'
		};

		return (
			<div style={divStyle}>
				<LinkedIcon {...leftIconProps} />
				{filteredCards}
				<LinkedIcon {...rightIconProps} />
			</div>
		);
	}
}
