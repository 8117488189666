import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
// import reactStringReplace from 'react-string-replace';

export default function Query(props) {
	let queryContents = props.query;

	const [open, setOpen] = useState(false);

	// let queryList = reactStringReplace(queryContents, /\$\{con\.escape\(\s?(.+?)\s?\)\}/g, (match, i) => {
	// 	return <span style={ {color: 'red'} }>{match}</span>
	// });

	const titleStyle = {
		color: '#069',
		textDecoration: 'underline',
		cursor: 'pointer'
	};

	return (
		<div>
			<Card.Text
				align="start"
				style={titleStyle}
				onClick={() => setOpen(!open)}
			>
				{props.title}
			</Card.Text>
			<Collapse in={open}>
				<div>
					<Card.Subtitle align="start" className="mb-2 text-muted">
						{props.filename}
					</Card.Subtitle>
					<pre align="start">{queryContents}</pre>
				</div>
			</Collapse>
			{/* <pre>{queryList}</pre> */}
		</div>
	);
}
