import React from 'react';
import SetCategory from './SetCategory.js';
import SetEvent from './SetEvent.js';
import SetCoordinator from './SetCoordinator.js';
import Finalize from './Finalize.js';
import { Form } from 'react-bootstrap';

/**
 * @class ModifyEvent
 * @classdesc Displays the page where you either create or edit the info of an event
 * @param {Object} props
 * @param {integer} props.id - integer containing the id (unique identifier) of the event.
 * If creating a new event this variable will not be used and thereofre fine if not defined.
 * @param {string} props.title - string containing the current title of the event
 * @param {string} props.loc - string containing the current title of the event
 * @param {string} props.date - string containing the current title of the event
 * @param {string} props.startTime - string containing the current title of the event
 * @param {integer} props.duration - integer containing the current duration time in minutes of the event
 * @param {boolean} props.isNew - boolean containing whether the current event is new or already existent
 * @param {function} props.cancel - function to discard changes from editing or creating an event.
 * @param {function} props.save - function to save changes from editing or creating an event. Takes one argument containing all relevant
 * information for an event (event body).
 * @param {Object[]} props.ccKeys - list of all possible ccKeys (or Category/Gender combinations) which can be chosen for an event. Used when modifying an event's taget-groups/categories.
 * @param {Object[]} props.categories - list of all categories that the event has been chosen to be for.
 * @param {Object[]} props.unselectedUsers - list of all currently unselected coordinators.
 * @param {Object[]} props.selectedUsers- list of all currently selected coordinators.
 * @returns {React.Component}
 */

export default class ModifyEvent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: this.props.categories, /// make not reference
			eventTitle: this.props.title,
			eventLocation: this.props.loc,
			eventDate: this.props.date,
			startTime: this.props.startTime,
			duration: this.props.duration,
			unselectedUsers: this.props.unselectedUsers, ///
			selectedUsers: this.props.selectedUsers, ///
			unselectedUserSearchText: '',
			selectedUserSearchText: ''
		};
		this.toggleCategory = this.toggleCategory.bind(this);
		this.editEventTitle = this.editEventTitle.bind(this);
		this.editEventLocation = this.editEventLocation.bind(this);
		this.editEventDate = this.editEventDate.bind(this);
		this.editStartTime = this.editStartTime.bind(this);
		this.editDuration = this.editDuration.bind(this);
		this.makeSelectedUser = this.makeSelectedUser.bind(this);
		this.removeSelectedUser = this.removeSelectedUser.bind(this);
		this.updateUnselectedUserSearch =
			this.updateUnselectedUserSearch.bind(this);
		this.create = this.create.bind(this);
	}

	toggleCategory(category, gender) {
		if (
			this.state.categories
				.map(cat => cat.category.concat(' ', cat.gender))
				.includes(category.concat(' ', gender))
		) {
			let temp = [...this.state.categories];
			temp = temp.filter(
				cat =>
					cat.category.concat(' ', cat.gender) !==
					category.concat(' ', gender)
			);
			this.setState({ categories: temp });
		} else if (
			!this.state.categories
				.map(cat => cat.category.concat(' ', cat.gender))
				.includes(category.concat(' ', gender))
		) {
			let temp = [...this.state.categories];
			temp.push({ category: category, gender: gender });
			this.setState({ categories: temp });
		}
	}

	editEventTitle(event) {
		this.setState({ eventTitle: event.target.value });
	}

	editEventLocation(event) {
		this.setState({ eventLocation: event.target.value });
	}

	editEventDate(event) {
		this.setState({ eventDate: event.target.value });
	}

	editStartTime(event) {
		this.setState({ startTime: event.target.value });
	}

	editDuration(event) {
		this.setState({ duration: event.target.value });
	}

	updateUnselectedUserSearch(event) {
		this.setState({
			unselectedUserSearchText: event.target.value
		});
	}

	makeSelectedUser(id) {
		const chosenUser = this.state.unselectedUsers.find(x => x.id === id);
		if (chosenUser !== undefined) {
			this.setState({
				selectedUsers: this.state.selectedUsers.concat(chosenUser),
				unselectedUsers: this.state.unselectedUsers.filter(
					x => x.id !== id
				),
				selectedUserSearchText: ''
			});
		}
	}

	removeSelectedUser(id) {
		const chosenUser = this.state.selectedUsers.find(x => x.id === id);
		if (chosenUser !== undefined) {
			this.setState({
				unselectedUsers: this.state.unselectedUsers.concat(chosenUser),
				selectedUsers: this.state.selectedUsers.filter(
					x => x.id !== id
				),
				unselectedUserSearchText: ''
			});
		}
	}

	create() {
		let body;
		if (this.props.isNew) {
			body = {
				title: this.state.eventTitle,
				location: this.state.eventLocation,
				date: this.state.eventDate,
				startTime: this.state.startTime.concat(':00'),
				duration: this.state.duration,
				coords: this.state.selectedUsers,
				categories: this.state.categories
			};
		} else if (!this.props.isNew) {
			body = {
				id: this.props.id,
				title: this.state.eventTitle,
				location: this.state.eventLocation,
				date: this.state.eventDate,
				startTime: this.state.startTime.concat(':00'),
				duration: this.state.duration,
				coords: this.state.selectedUsers,
				categories: this.state.categories
			};
		} else {
			body = {};
		}
		this.props.save(body);
	}

	render() {
		let unselectedUsersToDisplay = this.state.unselectedUsers;

		if (this.state.unselectedUserSearchText !== '') {
			unselectedUsersToDisplay = unselectedUsersToDisplay.filter(x => {
				return x.text
					.toLowerCase()
					.includes(
						this.state.unselectedUserSearchText.toLowerCase()
					);
			});
		}

		let setCoordProps = {
			searchText: this.state.unselectedUserSearchText,
			updateSearch: this.updateUnselectedUserSearch,
			removeSelectedUser: this.removeSelectedUser,
			unselectedUsersToDisplay: unselectedUsersToDisplay,
			makeSelectedUser: this.makeSelectedUser,
			selectedUsersToDisplay: this.state.selectedUsers
		};

		let setCatProps = {
			ccKeys: this.props.ccKeys,
			categories: this.state.categories,
			toggleCategory: this.toggleCategory
		};

		let setEvtProps = {
			eventTitle: this.state.eventTitle,
			eventLocation: this.state.eventLocation,
			eventDate: this.state.eventDate,
			startTime: this.state.startTime,
			duration: this.state.duration,
			editEventTitle: this.editEventTitle,
			editEventLocation: this.editEventLocation,
			editEventDate: this.editEventDate,
			editStartTime: this.editStartTime,
			editDuration: this.editDuration
		};

		let setFinProps = {
			create: this.create,
			cancel: this.props.cancel
		};

		return (
			<Form>
				<SetEvent {...setEvtProps} />
				<SetCategory {...setCatProps} />
				<SetCoordinator {...setCoordProps} />
				<Finalize {...setFinProps} />
			</Form>
		);
	}
}
