import React from 'react';
import StandingsCard from './StandingsCard';

export default function PoolStandings(props) {
	const divStyle = {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 1vw',
		padding: '0 1vw'
		// overflowY: 'scroll',
		// maxHeight: '74vh'
	};

	const headingStyle = {
		textAlign: 'center',
		border: 'solid 2px #5CAEFA',
		borderRadius: '15px',
		backgroundColor: '#5CAEFA',
		color: '#fff',
		padding: '3px 0',
		width: '330px',
		alignSelf: 'center'
	};

	if (props.collapsed) {
		return (
			<div style={divStyle}>
				<p style={headingStyle} onClick={props.toggleCollapse}>
					{props.header}
				</p>
			</div>
		);
	} else {
		return (
			<div style={divStyle}>
				<p style={headingStyle} onClick={props.toggleCollapse}>
					{props.header}
				</p>
				{props.teamStandings.map(x => (
					<StandingsCard key={x.teamID} {...x} />
				))}
			</div>
		);
	}
}

// export const fakeprops = {
// 	header: 'Ch A Coed - Kickball',
// 	teamStandings: [
// 		{
// 			ranking: 1,
// 			wins: 1,
// 			losses: 0,
// 			ties: 0,
// 			points: '2',
// 			headToHead: '-',
// 			pointDifferential: '-',
// 			headToHead2: '-',
// 			goalsInFavor: '-',
// 			teamName: 'BRA 1',
// 			teamShirt: 'ven1',
// 			teamID: 25
// 		},
// 		{
// 			ranking: 2,
// 			wins: 0,
// 			losses: 1,
// 			ties: 0,
// 			points: '0',
// 			headToHead: '-',
// 			pointDifferential: '-',
// 			headToHead2: '-',
// 			goalsInFavor: '-',
// 			teamName: 'COL 1',
// 			teamShirt: 'ven1',
// 			teamID: 29
// 		}
// 	]
// };
