import {
	faAddressCard,
	faBook,
	faBullhorn,
	faBullseye,
	faCalendar,
	faCog,
	faCreditCard,
	faDatabase,
	faExchange,
	faExclamationTriangle,
	faFilter,
	faGlobe,
	faHome,
	faPrint,
	faQuestion,
	faRandom,
	faStar,
	faStickyNote,
	faTrophy,
	faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconStyle = {
	alignContent: 'center',
	justifyContent: 'center',
	display: 'flex',
	alignSelf: 'center',
	color: 'white'
};

const iconMapper = {
	home: <FontAwesomeIcon icon={faHome} style={iconStyle} />,
	'address-card': <FontAwesomeIcon icon={faAddressCard} style={iconStyle} />,
	filter: <FontAwesomeIcon icon={faFilter} style={iconStyle} />,
	exchange: <FontAwesomeIcon icon={faExchange} style={iconStyle} />,
	users: <FontAwesomeIcon icon={faUsers} style={iconStyle} />,
	calendar: <FontAwesomeIcon icon={faCalendar} style={iconStyle} />,
	bullhorn: <FontAwesomeIcon icon={faBullhorn} style={iconStyle} />,
	star: <FontAwesomeIcon icon={faStar} style={iconStyle} />,
	trophy: <FontAwesomeIcon icon={faTrophy} style={iconStyle} />,
	print: <FontAwesomeIcon icon={faPrint} style={iconStyle} />,
	book: <FontAwesomeIcon icon={faBook} style={iconStyle} />,
	cog: <FontAwesomeIcon icon={faCog} style={iconStyle} />,
	'exclamation-triangle': (
		<FontAwesomeIcon icon={faExclamationTriangle} style={iconStyle} />
	),
	'sticky-note': <FontAwesomeIcon icon={faStickyNote} style={iconStyle} />,
	calendar: <FontAwesomeIcon icon={faCalendar} style={iconStyle} />,
	question: <FontAwesomeIcon icon={faQuestion} style={iconStyle} />,
	'credit-card': <FontAwesomeIcon icon={faCreditCard} style={iconStyle} />,
	random: <FontAwesomeIcon icon={faRandom} style={iconStyle} />,
	globe: <FontAwesomeIcon icon={faGlobe} style={iconStyle} />,
	bullseye: <FontAwesomeIcon icon={faBullseye} style={iconStyle} />,
	database: <FontAwesomeIcon icon={faDatabase} style={iconStyle} />
};

export default iconMapper;
