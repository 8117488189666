import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
/**
 * Selection screen for which categories an event will be made out to
 * @function EventCards
 * @param {Object} props
 * @param {string} props.title={evtInfo.title}
 * @param {string} props.date={evtInfo.date}
 * @param {string} props.startTime={evtInfo.startTime}
 * @param {string} props.endTime={evtInfo.endTime}
 * @param {string} props.evtID={evtInfo.id}
 * @param {function} props.delete={this.props.deleteEvent}
 * @param {function} props.modify={this.eventModify}
 * @returns {React.Component}
 */

//date and time format

export default function EventCards(props) {
	return (
		<Card style={{ width: '18rem' }} className="mx-2 text-center">
			<Card.Body style={{ height: '150px' }}>
				<Card.Title>{props.title}</Card.Title>
				<Card.Subtitle className="mb-2 text-muted">
					{props.date}
				</Card.Subtitle>
				<Card.Text>
					<Row>
						<p>{props.startTime}</p>
						<p>{props.endTime}</p>
					</Row>
				</Card.Text>
			</Card.Body>
			<Card.Body>
				<Row style={{ margin: 'auto' }}>
					<Col>
						<Button
							variant="outline-secondary"
							onClick={() => props.modify(props.evtID)}
						>
							Edit
						</Button>
					</Col>
					<Col>
						<Button
							variant="outline-danger"
							onClick={() => props.delete(props.evtID)}
						>
							Delete
						</Button>{' '}
						{/*delete event*/}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}
