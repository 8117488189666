import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const getsiteassignments = createAsyncThunk(
	'GET_SITEASSIGNMENTS',
	getSiteAssignments
);
export const getsitedirectors = createAsyncThunk(
	'GET_SITEDIRECTORS',
	getSiteDirectors
);
export const assignsitedirector = createAsyncThunk(
	'ASSIGN_SITEDIRECTOR',
	assignSiteDirector
);

async function getSiteAssignments() {
	try {
		let result = await request('logistics/siteassignments');
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getSiteDirectors() {
	try {
		let result = await request('user/sitedirectors');
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function assignSiteDirector(obj) {
	try {
		let result = await request('logistics/siteassignments', 'post', obj);
		return Promise.resolve(result.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
