import { createReducer } from '@reduxjs/toolkit';
import {
	schedData,
	getTeamSchedule,
	getVersions
} from './teamSchedulesActions.js';

const teamSchedulesReducer = createReducer({}, builder => {
	builder.addCase(schedData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(schedData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			data: action.payload
		};
	});

	builder.addCase(schedData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getTeamSchedule.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending getTeamSchedule'
	}));

	builder.addCase(getTeamSchedule.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled getTeamSchedule',
			games: action.payload
		};
	});

	builder.addCase(getTeamSchedule.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error getTeamSchedule'
	}));

	builder.addCase(getVersions.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending getVersions'
	}));

	builder.addCase(getVersions.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled getVersions',
			versions: action.payload
		};
	});

	builder.addCase(getVersions.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error getVersions'
	}));
});

export default teamSchedulesReducer;
