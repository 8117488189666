import React from 'react';
import {
	Row,
	Button,
	Col,
	Card,
	Container,
	Form,
	Alert
} from 'react-bootstrap';
import { request, backendURL } from '../global/utils';
import { withParams } from '../containers/routerUtils';
// const https = require('https-browserify');

class UploadPhotoPortal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imageInvalid: true,
			filename: '',
			file: null,
			name: ''
		};
		this.fileSelectHandler = this.fileSelectHandler.bind(this);
		this.submitHandler = this.submitHandler.bind(this);
	}

	componentDidMount() {
		fetch(
			`${backendURL}/registration/uploadphoto/${this.props.params.registrant_id}/name`
		)
			.then(result => result.json())
			.then(result => {
				if (result.content) {
					this.setState({ name: result.content });
				}
			});
	}

	fileSelectHandler(event) {
		console.log(event.target);
		let filename = event.target.value.toLowerCase();
		if (
			!(
				filename.includes('.jpg') ||
				filename.includes('.png') ||
				filename.includes('.jpeg') ||
				filename.includes('.heic')
			)
		) {
			this.setState({
				imageInvalid: true,
				filename: '',
				file: null
			});
		} else {
			this.setState({
				imageInvalid: false,
				filename: filename,
				file: event.target.files[0]
			});
		}
	}

	async submitHandler() {
		let formData = new FormData();
		formData.append('file', this.state.file);
		formData.append('Content-Type', this.state.file.type);

		const registrant_id = this.props.params.registrant_id;
		console.log('This state', this.state.file);
		let resp = await request(
			`registration/uploadphotosign?registrant_id=${registrant_id}&file-type=${this.state.file.type}`
		);
		await this.uploadToS3(resp.data, this.state.file, registrant_id);
	}

	async uploadToS3(file, imageFile, registrant_id) {
		const reqOptions = {
			method: 'PUT',
			body: imageFile,
			headers: {
				'Content-Length': imageFile.size
			}
			// httpsAgent: agent
		};

		const url = file.signedRequest;

		let respS3 = await fetch(url, reqOptions);
		if (respS3.status === 200) {
			console.log('Added to s3:', respS3);
			let resp = await request(
				`registration/uploadphoto/${registrant_id}?url=${file.url}`
			);
			console.log(resp);
			if (resp.data.code === 'A1') {
				alert('Thank you for uploading a new photo!');
			} else {
				alert(
					'Image not submitted properly. Internal Server Error - please try again'
				);
			}
		} else {
			console.log(respS3);
			alert(
				'Image not submitted properly. Internal Server Error - please try again'
			);
		}
	}

	render() {
		return (
			<div style={{ backgroundColor: '#F4F4F4' }}>
				<link
					rel="stylesheet"
					href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
					integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
					crossOrigin="anonymous"
				/>

				<Row className="m-3">
					<Col className="col-10 mt-3">
						<Card>
							<Card.Header
								style={{
									fontSize: 30,
									fontWeight: '500',
									color: '#4E73DF'
								}}
							>
								Maccabi Games Registration: Upload Photo
							</Card.Header>
							<Card.Body>
								<Container>
									<Row>
										<h3 className="">
											{this.state.name !== ''
												? `Uploading a Photo for ${this.state.name}`
												: 'Invalid Registrant ID'}
										</h3>
										<Form.Group
											controlId="formFile"
											className="mb-3"
										>
											<Form.Label>
												Select a .jpg, .jpeg, .png, or
												.heic
											</Form.Label>
											{/* Specify file types properly here */}
											<Form.Control
												type="file"
												accept=".jpg, .jpeg, .png, .heic"
												onChange={
													this.fileSelectHandler
												}
												isInvalid={
													this.state.imageInvalid
												}
											/>
										</Form.Group>
										{this.state.imageInvalid ? (
											<Alert variant="danger">
												Please submit a valid photo
											</Alert>
										) : (
											<Button
												onClick={this.submitHandler}
											>
												Submit
											</Button>
										)}
									</Row>
								</Container>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withParams(UploadPhotoPortal);
