import { createReducer } from '@reduxjs/toolkit';
import {
	familyinfo,
	updatefamilyinfo,
	unregisterfamily,
	registrantsinfo,
	updateregistrantinfo,
	unregisterplayer,
	downloadRegistrantRecords,
	createFamilyCard,
	getUsersAdVetSportList,
	putUsersAdVetSportList,
	getUsersAddonList,
	putUsersAddonList,
	updatemergefamily,
	getunapprovedreg
} from './familyinfoActions.js';

const familyinfoReducer = createReducer({}, builder => {
	builder.addCase(familyinfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(familyinfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			families: [...action.payload]
		};
	});

	builder.addCase(familyinfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(updatefamilyinfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(updatefamilyinfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			families: [...action.payload]
		};
	});

	builder.addCase(updatefamilyinfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(unregisterfamily.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(unregisterfamily.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			families: [...action.payload]
		};
	});

	builder.addCase(unregisterfamily.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(
		downloadRegistrantRecords.pending.type,
		(state, action) => ({
			...state,
			requestStatus: 'pending'
		})
	);

	builder.addCase(
		downloadRegistrantRecords.fulfilled.type,
		(state, action) => {
			window.open(action.payload.content.url);
			return {
				...state,
				requestStatus: 'fulfilled',
				downloadRegistrants: 'opened'
			};
		}
	);

	builder.addCase(
		downloadRegistrantRecords.rejected.type,
		(state, action) => ({
			...state,
			requestStatus: 'error'
		})
	);

	builder.addCase(registrantsinfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(registrantsinfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(registrantsinfo.rejected.type, (state, action) => ({
		...state,
		...action.error,
		requestStatus: 'error'
	}));

	builder.addCase(updateregistrantinfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(updateregistrantinfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(updateregistrantinfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(unregisterplayer.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(unregisterplayer.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(unregisterplayer.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(createFamilyCard.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(createFamilyCard.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(createFamilyCard.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getUsersAdVetSportList.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getUsersAdVetSportList.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(getUsersAdVetSportList.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getUsersAddonList.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getUsersAddonList.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(getUsersAddonList.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(putUsersAdVetSportList.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(putUsersAdVetSportList.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(putUsersAdVetSportList.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(putUsersAddonList.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(putUsersAddonList.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(putUsersAddonList.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(updatemergefamily.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(updatemergefamily.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(updatemergefamily.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(getunapprovedreg.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getunapprovedreg.fulfilled.type, (state, action) => {
		return {
			...state,
			unapprovedReg: action.payload,
			requestStatus: 'fulfilled'
		};
	});

	builder.addCase(getunapprovedreg.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default familyinfoReducer;
