import React from 'react';
import Form from 'react-bootstrap/Form';

export default function FamilyInfoRow(props) {
	let rowStyle = {
		cursor: 'pointer',
		borderTop: '1px solid #545454',
		borderBottom: '1px solid #545454'
	};

	let setChecked = e => {
		props.mergeFamily(props.familyId);
		console.log('working propagation');
		e.stopPropagation();
	};

	return (
		<tr onClick={props.editHandler.bind(null, props.registrationId)}>
			{props.switchStatus && (
				<td class="text-center">
					<Form.Check
						checked={props.checked}
						type="checkbox"
						onClick={setChecked}
					/>
				</td>
			)}

			<th class="text-center" scope="row">
				{props.familyId}
			</th>
			{/* <td>{props.registrationId}</td> */}
			<td class="text-center">{props.familyName}</td>
			<td class="text-center">{props.email}</td>
			<td class="text-center">{props.phone}</td>
			<td class="text-center">{props.timeStamp}</td>
		</tr>
	);
}
