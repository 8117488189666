import React from 'react';

export default function RefScheduleRow(props) {
	return (
		<tr>
			<th className="text-center" scope="row">
				{props.sport}
			</th>
			<td className="text-center">{props.date}</td>
			<td className="text-center">{props.time}</td>
			<td className="text-center">{props.location}</td>
			<td className="text-center">{props.type}</td>
			<td className="text-center">{props.cat_gender}</td>
			<td className="text-center">{props.teams ? props.teams : 'TBD'}</td>
		</tr>
	);
}
