import React from 'react';
import './TeamSchedule.css';
import moment from 'moment';

export default class TeamSchedule extends React.Component {
	render() {
		let sortedEvents = this.props.events.sort((a, b) => {
			if (a.start < b.start) return -1;
			else return 1;
		});
		return (
			<div className="printable-schedule">
				<p>{this.props.team}</p>
				<ul>
					{sortedEvents.map(x => {
						const eventDate = x.start.toDateString().slice(0, -4);
						const startTime = x.start.toLocaleTimeString('en-US', {
							hour: '2-digit',
							hour12: true,
							minute: '2-digit'
						});
						const endTime = x.end.toLocaleTimeString('en-US', {
							hour: '2-digit',
							hour12: true,
							minute: '2-digit'
						});
						return (
							<li>
								<p
									style={{
										color: x.textColor,
										backgroundColor: x.background
									}}
								>
									{`${eventDate} from ${startTime} to ${endTime} | ${x.text}`}
								</p>
							</li>
						);
					})}
				</ul>
				<p>Printed on {moment().format('MM-DD h:mm a')}</p>
			</div>
		);
	}
}
