import { createReducer } from '@reduxjs/toolkit';
import { registrantchanges } from './registrantchangesActions';

const registrantChangesReducer = createReducer({}, builder => {
	builder.addCase(registrantchanges.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(registrantchanges.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			changes: action.payload
		};
	});

	builder.addCase(registrantchanges.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default registrantChangesReducer;
