import React from 'react';
import { DynamicTableView } from '../components/general/DynamicTable';
import { connect } from 'react-redux';
import { redyellowcards } from '../services/redyellowcard/redyellowcardActions';
import { Card, Container } from 'react-bootstrap';
class RedYellowCardView extends React.Component {
	componentDidMount() {
		this.props.getCardInfo();
	}

	render() {
		if (this.props.redyellowCards) {
			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Red/Yellow Card
						</Card.Header>
						<Card.Body>
							<Container>
								<DynamicTableView
									data={this.props.redyellowCards}
									name="redYellowCards"
								/>
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		} else return <p>Loading</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.redyellow;
	if (raw.cards) {
		return {
			redyellowCards: raw.cards.map(cards => {
				return {
					...cards,
					timestamp: new Date(cards.timestamp).toLocaleString()
				};
			})
		};
	} else return { redyellowCards: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getCardInfo: () => dispatch(redyellowcards())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RedYellowCardView);
