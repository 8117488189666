import React from 'react';

/**
 * Displays the relevant information about the game
 * @function GameInfo
 * @param {Object} props
 * @param {string} props.datetime - the date and time for the game
 * @param {string} props.location - the location of the game
 * @param {string} props.tournament - the tournament of the game
 * @returns {React.Component}
 */
export default function GameInfo(props) {
	const gameInfoStyle = {
		marginTop: '30px'
	};

	const dtStyle = {
		lineHeight: '100%',
		color: 'rgb(84, 170, 250)',
		fontSize: '118%'
	};

	const pStyle = {
		lineHeight: '25%',
		fontSize: '110%'
	};

	return (
		<div style={gameInfoStyle}>
			<p style={dtStyle}>{props.datetime}</p>
			<p style={pStyle}>{props.location}</p>
			<p style={pStyle}>{props.tournament}</p>
		</div>
	);
}

// const fakeprops = {
//     datetime: 'Thursday, 11/28 | 09:40 am',
//     location: 'Hillel - Volleyball Out',
//     tournament: 'Ch D Male - Volleyball'
// };
