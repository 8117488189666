import React from 'react';
import LabeledIconFlag from '../general/LabeledIconFlag.js';
import './ScoreCardStyle.css';

/**
 * Displays the bottom half of the scorecards, which
 * include the two teams involved and the scores
 * @function ScoreCardBottom
 * @param {Object} props
 * @param {Object} props.team1Icon - props for {@link LabeledIcon}
 * @param {Object} props.team2Icon - props for {@link LabeledIcon}
 * @param {boolean} props.hasSet - does the game have a set score
 * @param {string} props.mode - set / game
 * @param {string} props.score - score of the game / set
 * @param {function} props.clickHandler - for toggling between game / set scores
 * @returns {React.Component}
 */
export default function ScoreCardBottom(props) {
	let buttonText;
	if (props.mode === 'set') {
		buttonText = 'Show Game Score';
	} else if (props.mode === 'game') {
		buttonText = 'Show Set Score';
	} else {
		buttonText = 'Data error';
	}

	const buttonStyle = {
		padding: '0.5% 5%',
		marginTop: props.mode === 'set' ? '5px' : '0px',
		borderRadius: '20px',
		backgroundColor: 'rgb(84, 170, 250)',
		color: '#fff',
		cursor: 'pointer'
	};

	let buttonRender = null;
	if (props.hasSet) {
		buttonRender = (
			<div style={buttonStyle} onClick={props.clickHandler}>
				{buttonText}
			</div>
		);
	}

	const divStyle = {
		margin: '15px 18px',
		display: 'flex',
		alignItems: 'center',
		height: '90px',
		marginBottom: '30px',
		position: 'relative'
		// backgroundColor: 'yellow'
	};

	const middleStyle = {
		display: 'flex',
		flexGrow: '2',
		alignItems: 'center',
		flexDirection: 'column'
	};

	const scoreStyle = {
		color: '#1857C3',
		fontSize: props.mode === 'set' ? '150%' : '250%',
		width: '70%',
		margin: '0'
	};

	// return (
	//   <div style={divStyle}>
	// 	<LabeledIconFlag {...props.team1Icon} floated='left'/>
	// 	<p style={scoreStyle}>{props.score}</p>
	// 	{buttonRender}
	// 	<LabeledIconFlag {...props.team2Icon} floated='right'/>
	//   </div>
	// );

	let scoreRender;
	if (typeof props.score === 'object') {
		if (props.score[1] !== null) {
			scoreRender = [
				<p style={scoreStyle}>{props.score[0]}</p>,
				<p style={scoreStyle}>{props.score[1]}</p>
			];
		} else {
			scoreRender = <p style={scoreStyle}>{props.score[0]}</p>;
		}
	} else scoreRender = <p style={scoreStyle}>{props.score}</p>;

	const toolTip = {
		border: '2px solid black',
		color: 'white',
		backgroundColor: 'black',
		borderRadius: '6px',
		padding: '5px 0',
		position: 'absolute',
		top: '-20%',
		left: '50%',
		transform: 'translate(-50%, 0%)',
		textAlign: 'center',
		zIndex: '3',
		overflow: 'visible',
		fontSize: '76%',
		width: '80%'
	};

	return (
		<div style={divStyle}>
			<LabeledIconFlag {...props.team1Icon} />
			<div style={middleStyle} className="score">
				{scoreRender}
				{buttonRender}
			</div>
			{props.coaches ? (
				<p style={toolTip} className="signers">
					{props.coaches}
				</p>
			) : null}
			<LabeledIconFlag {...props.team2Icon} />
		</div>
	);
}

// const fakeprops = {
// 	team1Icon: {
// 		label: 'ARG 2',
// 		imageURL: 'assets/countryicons/flag-Argentina.png'
// 	},
// 	team2Icon: {
// 		label: 'VEN 2',
// 		imageURL: 'assets/countryicons/flag-Venezuela.png'
// 	},
// 	hasSet: true,
// 	score: '15 - 2 | 13 - 15 15 - 10',
// 	mode: 'set',
// 	clickHandler: () => console.log("clicked")
// };
