import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const captaincatgendercombos = createAsyncThunk(
	'GET_CAT_GENDER_COMBOS_CAPTAIN',
	getCategoryGenderCombinationsForCaptain
);
export const catgendercombos = createAsyncThunk(
	'GET_CAT_GENDER_COMBOS',
	getCategoryGenderCombinations
);
export const getplayers = createAsyncThunk(
	'GET_PLAYERS',
	getPlayersinCatGenderCountry
);
export const assignplayer = createAsyncThunk('ASSIGN_PLAYER', assignPlayer);
export const unassignplayer = createAsyncThunk(
	'UNASSIGN_PLAYER',
	unassignPlayer
);
export const unassignall = createAsyncThunk('UNASSIGN_ALL', unassignAll);
export const assignall = createAsyncThunk('ASSIGN_ALL', assignAll);

async function getCategoryGenderCombinations() {
	try {
		const result = await request(`tournament/teamassignment`);

		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getCategoryGenderCombinationsForCaptain() {
	try {
		const result = await request(`tournament/teamassignment/captain`);

		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getPlayersinCatGenderCountry(obj) {
	try {
		const result = await request(
			`tournament/teamassignment/${obj.catGender}/${obj.country}`
		);
		return Promise.resolve({
			players: result.data.content,
			country: obj.country,
			catGender: obj.catGender
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function assignPlayer(obj) {
	try {
		await request(
			`tournament/teamassignment/assign/${obj.registrantid}`,
			'put',
			{
				teamId: obj.teamid
			}
		);

		return Promise.resolve(await getPlayersinCatGenderCountry(obj));
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function unassignPlayer(obj) {
	try {
		await request(
			`tournament/teamassignment/unassign/${obj.registrantid}`,
			'put'
		);

		return Promise.resolve(await getPlayersinCatGenderCountry(obj));
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function unassignAll(obj) {
	try {
		await request(
			`tournament/teamassignment/unassign/${obj.catGender}/${obj.country}`,
			'put'
		);

		return Promise.resolve(await getPlayersinCatGenderCountry(obj));
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function assignAll(obj) {
	try {
		// console.log("Grade in AssignAll", grade)
		if (obj.grade) {
			await request(
				`tournament/teamassignment/assign/${obj.category}/${obj.gender}/${obj.country}?grade=true`,
				'post',
				{
					numTeams: obj.numTeams
				}
			);
		} else {
			await request(
				`tournament/teamassignment/assign/${obj.category}/${obj.gender}/${obj.country}?grade=false`,
				'post',
				{
					numTeams: obj.numTeams
				}
			);
		}

		return Promise.resolve(
			await getPlayersinCatGenderCountry({
				catGender: `${obj.category} ${obj.gender}`,
				country: obj.country
			})
		);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
