import React from 'react';
import ToggleItem from './ToggleItem.js';
import { Nav } from 'react-bootstrap';
/**
 * Creates toggleable list of options. Intended to be a controlled component
 * @function ToggleSelector
 * @param {Object} props
 * @param {string[]} props.itemsList - list of items to toggle between
 * @param {Object} props.selectionObj
 * @param {string} props.selectionObj.selectedItem - the item that is currently selected
 * @param {function} props.selectionObj.selectItem - callback function for selecting an item
 * @returns {React.Component}
 */
export default function ToggleSelector(props) {
	if (props.textStyle) {
		return (
			<Nav variant="pills" activeKey={props.selectionObj.selectedItem}>
				{props.itemsList.map(x => (
					<ToggleItem
						key={x.text}
						textStyle={x.textStyle}
						text={x.text}
						{...props.selectionObj}
					/>
				))}
			</Nav>
		);
	}
	return (
		<Nav variant="pills" activeKey={props.selectionObj.selectedItem}>
			{props.itemsList.map(x => (
				<ToggleItem
					key={x}
					text={x}
					textStyle={props.textStyle}
					{...props.selectionObj}
				/>
			))}
		</Nav>
	);
}

// export const fakeprops = {
// 	itemsList: ["Ch D Male", "Ch E Female", "Jr A Male", "Jr B Male"],
// 	selectionObj: {
// 		selectedItem: "Ch E Female",
// 		selectItem: event => console.log(event.target.innerHTML)
// 	}
// };
