import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';
import { ActionCreators } from 'redux-undo';
import store from '../../store/store.js';

export const allSchedules = createAsyncThunk(
	'GET_ALL_SCHEDULES',
	getAllSchedules
);
export const defaultSchedule = createAsyncThunk(
	'UPDATE_DEFAULT_SCHEDULE',
	updateDefaultSchedule
);
export const moveGameToClipboard = createAction('MOVE_TO_MULTIMOVE');
export const moveGameToTrash = createAction('MOVE_TO_TRASH');
export const selectScheduleVersion = createAction('SELECT_SCHED_VERS');
export const createGame = createAction('CREATE_GAME');
export const cancelGameCreation = createAction('CANCEL_CREATE_GAME');
export const createGameSetup = createAction('CREATE_GAME_SETUP');
export const colorChartSave = createAsyncThunk(
	'SAVE_COLORCHART',
	saveFromColorChart
);
export const summonplayers = createAsyncThunk('SUMMON_PLAYERS', summonPlayers);
export const publishschedule = createAsyncThunk(
	'PUBLISH_SCHEDULE',
	publishSchedule
);
export const unpublishschedule = createAsyncThunk(
	'UNPUBLISH_SCHEDULE',
	unpublishSchedule
);
export const getaffectedteamsched = createAsyncThunk(
	'GET_AFFECTED_TEAM_SCHED',
	getAffectedTeamSched
);
export const cancelschedchanges = createAsyncThunk(
	'CANCEL_SCHED_CHANGES',
	cancelSchedChanges
);
export const confirmschedchanges = createAsyncThunk(
	'CONFIRM_SCHED_CHANGES',
	confirmSchedChanges
);

export const setGameFilter = createAction('SET_GAME_FILTER', games => {
	return {
		payload: {
			games: games
		}
	};
});

export const unsetGameFilter = createAction('UNSET_GAME_FILTER');

export const addMoveChange = createAction('MOVE_GAME_TEMP', (game, slot) => {
	return {
		payload: {
			game: game,
			slot: slot
		}
	};
});
export const addSwapChange = createAction('SWAP_GAME_TEMP', (game1, game2) => {
	return {
		payload: {
			game1: game1,
			game2: game2
		}
	};
});

async function updateDefaultSchedule(version) {
	try {
		let result = await request('schedule/defaultversion', 'put', {
			version: version
		});
		return Promise.resolve({
			version: version,
			isPublished: result.data.content.isPublished
		});
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getAllSchedules() {
	try {
		let result = await request('schedule/all');
		console.log(result.data.content);
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function saveFromColorChart(obj) {
	try {
		let result = await request('schedule/changes', 'post', obj);
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function summonPlayers(teams) {
	try {
		await request('posts/summonplayers', 'post', { teams: teams });
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function publishSchedule() {
	try {
		await request('schedule/publish');
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function unpublishSchedule() {
	try {
		await request('schedule/unpublish');
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getAffectedTeamSched({ version, teamsAffected }) {
	try {
		let data = await request(
			`schedule/teamschedules/all/${version}?pregame=true`
		);
		console.log(data);
		let games = data.data.content.games;
		let affectedTeamSched = Object.fromEntries(
			teamsAffected.map(x => [x, games.teams[x]])
		);
		console.log(affectedTeamSched);
		let result = {
			eday: games.eday,
			etime: games.etime,
			sday: games.sday,
			stime: games.stime,
			affectedTeamSched: affectedTeamSched
		};
		console.log(result);
		return Promise.resolve(result);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function cancelSchedChanges(version) {
	try {
		await request('schedule/cancelchanges', 'post', { version: version });
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function confirmSchedChanges(body) {
	try {
		await request('schedule/confirmchanges', 'post', body);
		store.dispatch(ActionCreators.clearHistory());
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
