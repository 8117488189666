import { createReducer } from '@reduxjs/toolkit';
import {
	managePeopleInfo,
	addusertogroup,
	removeuserfromgroup,
	manageCoachInfo,
	changesportforref,
	managecountryinfo
} from './managepeopleActions';

const managepeopleReducer = createReducer({ coachInfoByTeam: {} }, builder => {
	builder.addCase(changesportforref.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(changesportforref.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(changesportforref.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(managePeopleInfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(managePeopleInfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(managePeopleInfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(addusertogroup.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(addusertogroup.fulfilled.type, (state, action) => {
		if (action.payload.group === 'COACH') {
			state.requestStatus = 'fulfilled';
			state.teamDataLoading = false;
			state.coachInfoByTeam[action.payload.data.team] =
				action.payload.data.coachinfo;
		} else {
			return {
				...state,
				requestStatus: 'fulfilled',
				...action.payload.data
			};
		}
	});

	builder.addCase(addusertogroup.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(removeuserfromgroup.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(removeuserfromgroup.fulfilled.type, (state, action) => {
		if (action.payload.group === 'COACH') {
			state.requestStatus = 'fulfilled';
			state.teamDataLoading = false;
			state.coachInfoByTeam[action.payload.data.team] =
				action.payload.data.coachinfo;
		} else {
			return {
				...state,
				requestStatus: 'fulfilled',
				...action.payload.data
			};
		}
	});

	builder.addCase(removeuserfromgroup.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(manageCoachInfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending',
		teamDataLoading: true
	}));

	builder.addCase(manageCoachInfo.fulfilled.type, (state, action) => {
		state.requestStatus = 'fulfilled';
		state.teamDataLoading = false;
		state.coachInfoByTeam[action.payload.team] = action.payload.coachinfo;
	});

	builder.addCase(manageCoachInfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(managecountryinfo.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(managecountryinfo.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			captainteams: action.payload
		};
	});

	builder.addCase(managecountryinfo.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default managepeopleReducer;
