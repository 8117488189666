import React from 'react';
import LabeledIcon from '../general/LabeledIcon.js';

/**
 * Displays a card with the tournament, icon, and team name for a team
 * @function TeamInfo
 * @param {Object} props
 * @param {string} props.backgroundColor - background color of the card
 * @param {string} props.borderColor - border color of the card
 * @param {boolean} props.bold - whether the text should be bold
 * @param {string} props.category - category name
 * @param {string} props.team - team name
 * @param {string} props.teamLogoURL - source image for the icon
 * @returns {React.Component}
 */
export default function TeamInfo(props) {
	let boxStyle = {
		backgroundColor: '#F9F9F9',
		borderLeft: `3px solid ${props.bordercolor}`,
		borderRadius: '5%',
		fontWeight: props.bold ? 'bold' : 'normal',
		width: '120px',
		margin: '0 3% 0 1%',
		textAlign: 'center',
		height: '170px',
		color: 'black',
		flexShrink: '0',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden'
	};

	const pStyle = {
		marginTop: '10px'
	};

	const circle = {
		display: 'flex',
		alignSelf: 'center',
		left: '-32px',
		top: '65px',

		position: 'absolute',
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		margin: '0',
		border: `1px solid ${props.bordercolor}`,
		// color: 'white',
		alignText: 'center',
		padding: '3px 1.5px 0 1.5px'
	};

	return (
		<div style={boxStyle}>
			<p style={pStyle}>{props.category}</p>
			<p style={circle}></p>
			<LabeledIcon label={props.team} imageURL={props.teamLogoURL} />
		</div>
	);
}

// const fakeprops = {
//     backgroundColor: '#66FF33',
//     borderColor: '#000000',
//     bold: false,
//     category: 'Ad Female',
//     team: 'VEN 1',
//     teamLogoURL: 'assets/teamshirts/shirt-ven1.png'
// };
