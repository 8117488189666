import React from 'react';
import {
	Modal,
	Button,
	Container,
	Row,
	Col,
	Form,
	ListGroup
} from 'react-bootstrap';

export default class EditRegistrantArea extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.changeHandler = this.changeHandler.bind(this);
		this.selectToggle = this.selectToggle.bind(this);
	}
	componentDidMount() {
		this.setState({
			firstName: this.props.firstname,
			lastName: this.props.lastname,
			phoneNum: this.props.phonenumber,
			dob: this.props.dob,
			gender: this.props.gender,
			grade: this.props.grade,
			country: this.props.country,
			catGender: this.props.category_gender,
			shirtsize: this.props.shirtsize,
			n_challah: this.props.n_challah,
			isMember: this.props.is_member,
			approvedCountryChange: this.props.approved_country_change,
			registrant_id: this.props.registrant_id,
			team: this.props.team,
			email: this.props.email,
			notes: this.props.notes
		});
	}

	changeHandler(key, event) {
		this.setState({
			[key]: event.target.value
		});
	}

	selectToggle(key, event) {
		this.setState({
			[key]: !this.state[key]
		});
	}

	render() {
		console.log('Edit Registrant', this.props);
		return (
			<Modal show={true} onHide={this.props.cancelHandler}>
				<Modal.Header closeButton>
					<Modal.Title>Registrant Info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Form>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.firstName}
											onChange={this.changeHandler.bind(
												null,
												'firstName'
											)}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.lastName}
											onChange={this.changeHandler.bind(
												null,
												'lastName'
											)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Phone Number</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.phoneNum}
											onChange={this.changeHandler.bind(
												null,
												'phoneNum'
											)}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Date of Birth</Form.Label>
										<Form.Control
											type="date"
											placeholder=""
											value={this.state.dob}
											onChange={this.changeHandler.bind(
												null,
												'dob'
											)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Form.Group className="mb-3">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="text"
										placeholder=""
										readOnly
										value={this.state.email}
										onChange={this.changeHandler.bind(
											null,
											'email'
										)}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Gender</Form.Label>
										<Form.Select
											aria-label="gendedr"
											value={this.state.gender}
											onChange={this.changeHandler.bind(
												null,
												'gender'
											)}
										>
											{this.props.allGender.map(x => (
												<option key={x}>{x}</option>
											))}
										</Form.Select>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Grade</Form.Label>
										<Form.Select
											aria-label="grade"
											value={this.state.grade}
											onChange={this.changeHandler.bind(
												null,
												'grade'
											)}
										>
											{this.props.individualGrade.map(
												x => (
													<option key={x}>{x}</option>
												)
											)}
										</Form.Select>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Category/Gender</Form.Label>
										<Form.Select
											aria-label="catgender"
											value={this.state.catGender}
											onChange={this.changeHandler.bind(
												null,
												'catGender'
											)}
										>
											{this.props.allGrade.map(x => (
												<option
													key={x.category}
													label={`${x.category} (${x.grade})`}
													value={x.category}
												>
													{x.category}
												</option>
											))}
										</Form.Select>
									</Form.Group>
								</Col>
								{/* <Col> */}
								<Form.Group className="mb-3">
									<Form.Label>Country</Form.Label>
									<Form.Select
										aria-label="country"
										value={this.state.country}
										onChange={this.changeHandler.bind(
											null,
											'country'
										)}
									>
										{this.props.allCountries.map(x => (
											<option key={x}>{x}</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Team</Form.Label>
									<Form.Control
										type="text"
										placeholder=""
										disabled
										value={this.state.team}
									/>
								</Form.Group>
								{/* </Col> */}
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>Shirt Size</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.shirtsize}
											onChange={this.changeHandler.bind(
												null,
												'shirtsize'
											)}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Label>
											Number of Challah
										</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											value={this.state.n_challah}
											onChange={this.changeHandler.bind(
												null,
												'n_challah'
											)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Form.Group className="mb-3">
									<Form.Label>Is Member</Form.Label>
									<Form.Switch
										id="custom-switch"
										label="Member"
										onChange={this.selectToggle.bind(
											null,
											'isMember'
										)}
										checked={this.state.isMember}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className="mb-3">
									<Form.Label>
										Approve Country Change
									</Form.Label>
									<Form.Switch
										id="custom-switch"
										label="Approve"
										onChange={this.selectToggle.bind(
											null,
											'approvedCountryChange'
										)}
										checked={
											this.state.approvedCountryChange
										}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className="mb-5">
									<Form.Label>Notes</Form.Label>
									<Form.Control
										type="textarea"
										rows={2}
										placeholder=""
										value={this.state.notes}
										onChange={this.changeHandler.bind(
											null,
											'notes'
										)}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Col>
									<Form.Label column>
										Adult/Veteran Sports
									</Form.Label>
									<ListGroup>
										{this.props.adVet.map(x => {
											return (
												<ListGroup.Item>
													<Form.Group>
														<Form.Switch
															id="custom-switch"
															label={
																x.displayName
															}
															onChange={() =>
																this.props.changeAdVetSports(
																	x.rpName
																)
															}
															// checked={this.state.isMember}
															checked={
																x.registered
															}
														/>
													</Form.Group>
												</ListGroup.Item>
											);
										})}
									</ListGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label column>Addons</Form.Label>
									<ListGroup>
										{this.props.addon.map(x => {
											return (
												<ListGroup.Item>
													<Form.Group>
														<Form.Switch
															id="custom-switch"
															label={x.name}
															onChange={() =>
																this.props.changeAddons(
																	x.name
																)
															}
															// checked={this.state.isMember}
															checked={
																x.registered
															}
														/>
													</Form.Group>
												</ListGroup.Item>
											);
										})}
									</ListGroup>
								</Col>
							</Row>
						</Form>
					</Container>
					<Modal.Footer>
						<Button
							variant="primary"
							onClick={this.props.saveRegistrant.bind(
								null,
								this.state
							)}
						>
							Save
						</Button>
						<Button
							variant="secondary"
							onClick={this.props.cancelHandler}
						>
							Cancel
						</Button>
						<Button
							variant="danger"
							onClick={this.props.unregisterPlayer.bind(
								null,
								this.state.registrant_id
							)}
						>
							Unregister Player
						</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal>
		);
	}
}
