import React from 'react';

export default function GenericTableRow(props) {
	let rowStyle = {
		borderBottom: 'solid black 1px'
	};

	return (
		<tr style={rowStyle}>
			{props.headers.map(x => (
				<td key={`${x.value}:${props.data.key}`}>
					{props.data[x.value]}
				</td>
			))}
		</tr>
	);
}
