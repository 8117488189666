import { createReducer } from '@reduxjs/toolkit';
import {
	authStatus,
	loginUser,
	signOutAction,
	signUpAction,
	confirmSignUpAction,
	switchUserProfile
} from './authActions';
let authReducer = createReducer({}, builder => {
	builder.addCase(signUpAction.pending.type, (state, action) => ({
		...state,
		authStatus: 'pending'
	}));

	builder.addCase(signUpAction.rejected.type, signUpReject);
	builder.addCase(signUpAction.fulfilled.type, signUpSuccess);

	builder.addCase(confirmSignUpAction.pending.type, (state, action) => ({
		...state,
		authStatus: 'pending'
	}));

	builder.addCase(confirmSignUpAction.rejected.type, confirmReject);

	builder.addCase(authStatus.pending.type, (state, action) => ({
		...state,
		authStatus: 'pending'
	}));

	builder.addCase(authStatus.fulfilled.type, (state, action) => ({
		...state,
		authStatus: 'logged_in'
	}));

	builder.addCase(authStatus.rejected.type, handleLoginReject);

	builder.addCase(loginUser.pending.type, (state, action) => ({
		...state,
		authStatus: 'pending'
	}));

	builder.addCase(loginUser.fulfilled.type, (state, action) => ({
		...state,
		authStatus: 'logged_in'
	}));

	builder.addCase(loginUser.rejected.type, handleLoginReject);

	builder.addCase(signOutAction.pending.type, (state, action) => ({
		...state,
		authStatus: 'pending'
	}));

	builder.addCase(signOutAction.fulfilled.type, (state, action) => null);

	builder.addCase(signOutAction.rejected.type, (state, action) => ({
		...state
	}));

	builder.addCase(switchUserProfile.type, (state, action) => ({
		...state,
		user: action.payload
	}));
});

function signUpReject(state, action) {
	console.log(action.error.message);
	switch (action.error.message) {
		case 'Username should be an email':
			return { ...state, authStatus: 'invalidemail' };
		case 'An account with the given email already exists.':
			return { ...state, authStatus: 'emailexists' };
		default:
			return { ...state, authStatus: 'internalerror' };
	}
}

function signUpSuccess(state, action) {
	return { ...state, authStatus: 'unconfirmed' };
}

function confirmReject(state, action) {
	switch (action.error.message) {
		case 'Invalid verification code provided, please try again.':
			return { ...state, authStatus: 'invalidcode' };
		case 'Invalid code provided, please request a code again.':
			return { ...state, authStatus: 'expiredcode/wrongemail' };
		default:
			return { ...state, authStatus: 'internalerror' };
	}
}

function handleLoginReject(state, action) {
	switch (action.error.message) {
		case 'Incorrect username or password.':
			return { ...state, authStatus: 'inc_userpass' };
		case 'not authenticated':
			return { ...state, authStatus: 'not_logged_in' };
		default:
			return { ...state, authStatus: 'not_logged_in' };
	}
}

export default authReducer;
