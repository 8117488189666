import store from './../../store/store';
import React from 'react';
import IdleTimer from './IdleTimer.js';

import './App.css';

import MainContent from './MainContent';
import {
	Authenticator,
	View,
	useAuthenticator,
	Button
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { connect } from 'react-redux';

const components = {
	SignIn: {
		Header() {
			return (
				<div
					textAlign="center"
					padding="3em"
					style={{
						backgroundColor: 'white',
						borderRadius: '3px 3px 0px 0px'
					}}
				>
					<img
						style={{
							display: 'block',
							maxHeight: '25vh',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						className="amplify-image"
						alt="Amplify logo"
						src="MG24LOGO_no_bg.png"
					/>
					<div className="amphead"></div>
				</div>
			);
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
					<p style={{ padding: '0px 20px' }}>
						This Webapp is meant for laptops, desktops and iPads. If
						you are on mobile, please use the Official Maccabi Games
						IOS and Android app
					</p>
					<Button
						fontWeight="normal"
						onClick={toForgotPassword}
						size="small"
						variation="link"
					>
						Reset Password
					</Button>
				</View>
			);
		}
	},
	SignUp: {
		Header() {
			return (
				<div
					textAlign="center"
					padding="3em"
					style={{
						backgroundColor: 'white',
						borderRadius: '3px 3px 0px 0px'
					}}
				>
					<img
						style={{
							display: 'block',
							maxHeight: '15vh',
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
						className="amplify-image"
						alt="Amplify logo"
						src="MG24LOGO_no_bg.png"
					/>
					<div className="amphead"></div>
				</div>
			);
		}
	}
};

/**
 * Functional component that renders the
 * Single Page App. Requires authentication
 * @returns {React.Component}
 */
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inactive: false
		};
		this.idleTimer = null;
		this.handleOnIdle = this.handleOnIdle.bind(this);
	}

	handleOnIdle(event) {
		console.log('user is idle', event);
		console.log('last active', this.idleTimer.getLastActiveTime());
		this.setState({ inactive: true });
	}

	signOut() {
		console.log('Signout out app');
		this.props.onStateChange('signedOut', null);
	}
	render() {
		console.debug('Auth is in App', this.props);
		const formFields = {
			signIn: {
				username: {
					placeholder: 'email address',
					isRequired: true
				}
			},
			signUp: {
				username: {
					order: 1,
					placeholder: 'email address'
				},
				name: {
					order: 2,
					placeholder: 'first name'
				},
				family_name: {
					order: 3,
					placeholder: 'last name'
				},
				password: {
					order: 4,
					placeholder: 'password'
				}
			}
		};

		return (
			<div>
				<IdleTimer
					ref={ref => {
						this.idleTimer = ref;
					}}
					timeout={1000 * 60 * 15}
					// onActive={this.handleOnActive}
					onIdle={this.handleOnIdle}
					// onAction={this.handleOnAction}
					debounce={250}
				/>
				<Authenticator
					className="customauth"
					formFields={formFields}
					components={components}
					socialProviders={
						[
							/*'google'*/
						]
					}
				>
					{({ signOut }) => (
						<MainContent
							menuPages={this.props.menuPages}
							auth={this.props.auth}
							inactive={this.state.inactive}
							signOutFunc={signOut}
						/>
					)}
				</Authenticator>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menuPages: state.menu.menuPages,
		loading: state.menu.loading,
		auth: state.auth
	};
};

const connectedApp = connect(mapStateToProps, null)(App);

export default connectedApp;

// export default function(ComposedClass) {
//   class AutoLogout extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         warningTime: 1000 * 60 * 10,
//         signoutTime: 1000 * 60 * 15,
//       };
//     }

//     componentDidMount() {
//       this.events = [
//         'load',
//         'mousemove',
//         'mousedown',
//         'click',
//         'scroll',
//         'keypress'
//       ];

//       for (var i in this.events) {
//         window.addEventListener(this.events[i], this.resetTimeout);
//       }

//       this.setTimeout();
//     }

//     clearTimeoutFunc = () => {
//       if (this.warnTimeout) clearTimeout(this.warnTimeout);

//       if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
//     };

//     setTimeout = () => {
//       this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
//       this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
//     };

//     resetTimeout = () => {
//       this.clearTimeoutFunc();
//       this.setTimeout();
//     };

//     warn = () => {
//       window.alert("You will be logged out automatically in 1 minute")
//       console.log('You will be logged out automatically in 1 minute.');
//     };

//     logout = () => {
//       // Send a logout request to the API
//       console.log('Sending a logout request to the API...');
//       this.destroy();
//     };

//     destroy = () => {
//      //clear the session
//       browserHistory.push('/');
//       window.location.assign('/');
//     };

//     render() {

//       return (
//         <div>
//           <ComposedClass {...this.props} />
//         </div>
//       );
//     }
//   }
// }
