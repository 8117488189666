import React from 'react';
import ToggleSelector from '../components/general/ToggleSelector.js';
import HorizontalDeck from '../components/HorizontalDeck.js';
import DoubleBookedTeamCard from '../components/logistics/DoubleBookedTeamCard.js';
import DoubleBookedLocationCard from '../components/logistics/DoubleBookedLocationCard.js';
import { connect } from 'react-redux';
import {
	controlpanelData,
	changeShabbatMode,
	dismisslocations,
	dismissteams
} from '../services/controlpanel/controlpanelActions.js';
import { setGameFilter } from '../services/colorchart/colorchartActions.js';
import { withNavigate } from '../containers/routerUtils.js';
import { Container, Card, Row } from 'react-bootstrap';

class ControlPanelView extends React.Component {
	constructor(props) {
		super(props);
		this.viewHandler = this.viewHandler.bind(this);
	}

	componentDidMount() {
		this.props.getControlPanelInfo();
	}

	viewHandler(ids) {
		this.props.viewHandler(ids);
		this.props.navigate('/colorchart');
	}

	render() {
		if (this.props.doubleBookedTeams && this.props.doubleBookedLocations) {
			let shabbatModeSelection = {
				selectedItem: this.props.shabbatMode ? 'ON' : 'OFF',
				selectItem: this.props.updateShabbatMode.bind(
					null,
					!this.props.shabbatMode
				)
			};

			const headerStyle = {
				color: 'black',
				fontSize: '120%',
				marginBottom: '10px'
			};

			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Control Panel
						</Card.Header>
						<Card.Body>
							<Container>
								<Row>
									<p style={headerStyle}>Shabbat Mode</p>
									<ToggleSelector
										itemsList={['ON', 'OFF']}
										selectionObj={shabbatModeSelection}
									/>
								</Row>
								<Row>
									<p style={headerStyle}>
										Double Booked Teams
									</p>
									<HorizontalDeck cardsToShow={4}>
										{this.props.doubleBookedTeams.map(x => (
											<DoubleBookedTeamCard
												{...x}
												key={`${x.bookingInfo.date};${x.bookingInfo.time};
														${x.bookingInfo.team_id}`}
												viewHandler={this.viewHandler}
												dismissHandler={
													this.props
														.dismissTeamHandler
												}
											/>
										))}
									</HorizontalDeck>
								</Row>
								<Row>
									<p style={headerStyle}>
										Double Booked Locations
									</p>
									<HorizontalDeck cardsToShow={3}>
										{this.props.doubleBookedLocations.map(
											x => (
												<DoubleBookedLocationCard
													{...x}
													key={`${
														x.bookingInfo.date
													};${x.bookingInfo.time};
														${x.bookingInfo.title};${JSON.stringify(x.gameIDs)}`}
													dismissHandler={
														this.props
															.dismissLocationHandler
													}
												/>
											)
										)}
									</HorizontalDeck>
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		} else return <p>Loading...</p>;
	}
}

const mapStateToProps = state => {
	let raw = state.controlpanel;
	if (raw.doublebookedTeams) {
		const mapTeams = bookings => {
			return {
				bookingInfo: {
					title: bookings.team,
					displaydate: bookings.displaydate,
					displaytime: bookings.displaytime,
					date: bookings.date,
					time: bookings.time,
					team_id: bookings.team_id
				},
				gameIDs: bookings.game_ids.split(',')
			};
		};

		const mapLocations = bookings => {
			return {
				bookingInfo: {
					title: bookings.location,
					displaydate: bookings.displaydate,
					displaytime: bookings.displaytime,
					date: bookings.date,
					time: bookings.time
				},
				gameIDs: bookings.game_ids,
				games: bookings.games.map(game => {
					return {
						backgroundColor: game.background,
						textColor: game.text,
						sport: game.sport,
						gameTitle:
							game.sport === 'dodgeball'
								? 'Dodgeball'
								: `${game.team1}-${game.team2}`,
						gameID: game.game_id
					};
				})
			};
		};

		return {
			shabbatMode: raw.shabbatMode,
			doubleBookedTeams: raw.doublebookedTeams.map(mapTeams),
			doubleBookedLocations: raw.doublebookedLocations.map(mapLocations)
		};
	} else
		return {
			shabbatMode: null,
			doubleBookedTeams: null,
			doubleBookedLocations: null
		};
};

const mapDispatchToProps = dispatch => {
	return {
		getControlPanelInfo: () => dispatch(controlpanelData()),
		updateShabbatMode: newVal =>
			dispatch(changeShabbatMode({ shabbatMode: newVal })),
		viewHandler: ids => dispatch(setGameFilter(ids)),
		dismissTeamHandler: info => dispatch(dismissteams(info)),
		dismissLocationHandler: info => dispatch(dismisslocations(info))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withNavigate(ControlPanelView));
