import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleXmark,
	faSquareCheck
} from '@fortawesome/free-solid-svg-icons';

export default function RegistrantInfoRow(props) {
	let rowStyle = {
		cursor: 'pointer'
		// borderTop: '1px solid #545454',
		// borderBottom: '1px solid #545454',
	};

	function redirectToPhotoUpload(e) {
		e.stopPropagation();
		window.location.href = `/photoupload/${props.registrant_id}`;
	}

	return (
		<tr
			style={rowStyle}
			className={props.is_registered === 0 ? 'alert-danger' : ''}
			onClick={
				props.editHandler
					? props.editHandler.bind(null, props.registrant_id)
					: null
			}
		>
			<th className="text-center" scope="row">
				{props.mg_registrant_id}
			</th>
			<td className="text-center">
				{props.firstname} {props.lastname}
			</td>
			<td className="text-center">
				{props.image_accepted === 1 ? (
					<FontAwesomeIcon
						icon={faSquareCheck}
						style={{ color: 'green' }}
						className="p-auto"
						onClick={redirectToPhotoUpload}
					/>
				) : (
					<FontAwesomeIcon
						icon={faCircleXmark}
						style={{ color: 'red' }}
						className="p-auto"
						onClick={redirectToPhotoUpload}
					/>
				)}
			</td>
			<td className="text-center">{props.grade}</td>
			<td className="text-center">{props.gender}</td>
			<td className="text-center">{props.country}</td>
			<td className="text-center">{props.team}</td>
			<td className="text-center">{props.timestamp}</td>
		</tr>
	);
}
