import React from 'react';

/**
 * Displays two buttons side by side
 * @function ButtonRow
 * @param {Object} props
 * @param {string} props.leftLabel - text for the button on the left
 * @param {string} props.rightLabel - text for the button on the right
 * @param {function} props.leftClickHandler - callback function for left button
 * @param {function} props.rightClickHandler - callback function for right button
 * @returns {React.Component}
 */
export default function ButtonRow(props) {
	const rowStyle = {
		display: 'flex',
		justifyContent: 'space-around'
	};

	const greenButton = {
		backgroundColor: 'green',
		cursor: 'pointer',
		marginRight: '5px',
		borderRadius: '15px',
		border: '0',
		outline: 'none',
		padding: '3px 10px',
		color: 'white'
	};

	const redButton = {
		backgroundColor: 'red',
		cursor: 'pointer',
		borderRadius: '15px',
		border: '0',
		outline: 'none',
		padding: '3px 10px',
		color: 'white'
	};

	return (
		<div style={rowStyle}>
			<button style={greenButton} onClick={props.leftClickHandler}>
				{props.leftLabel}
			</button>
			<button style={redButton} onClick={props.rightClickHandler}>
				{props.rightLabel}
			</button>
		</div>
	);
}

// export const fakeprops ={
// 	leftLabel: 'edit',
// 	leftClickHandler: () => console.log('editing'),
// 	rightLabel: 'delete',
// 	rightClickHandler: () => console.log('deleting')
// };
