import React from 'react';
import ToggleSelector from '../general/ToggleSelector';
import { Form } from 'react-bootstrap';
export default function AddPoints(props) {
	let eventSelectionProps = {
		itemsList: ['Person', 'Country'],
		selectionObj: {
			selectedItem: props.awardeeType,
			selectItem: props.selectAwardeeType,
			selectedItemStyle: {}
		}
	};

	let medalSelectionProps = {
		itemsList: ['Gold', 'Silver'],
		selectionObj: {
			selectedItem: props.medalType,
			selectItem: props.selectMedalType,
			selectedItemStyle: {}
		}
	};

	let nameRender = null;
	if (props.awardeeType === 'Person') {
		nameRender = (
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Name</Form.Label>
				<Form.Control
					type="text"
					onChange={props.changeAwardee}
					value={props.awardee}
				/>
			</Form.Group>
		);
	} else {
		nameRender = (
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Name</Form.Label>
				<Form.Control type="text" readOnly value={props.awardee} />
			</Form.Group>
		);
	}
	const buttonDiv = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: '15px',
		border: '2px solid black',
		alignItems: 'center',
		cursor: 'pointer',
		height: '45px',
		backgroundColor: '#f8f8f8',
		borderRadius: '10px'
	};

	const flagStyle = {
		// width: '50px',
		height: '30px',
		marginLeft: '10px'
	};

	const labelStyle = {
		textAlign: 'center',
		margin: 'auto 10px'
	};

	return (
		<Form>
			<Form.Group className="mb-3">
				<Form.Label>Awardee Type</Form.Label>
				<ToggleSelector {...eventSelectionProps} />
			</Form.Group>
			{nameRender}
			<Form.Group className="mb-3">
				<Form.Label>Medal Description</Form.Label>
				<Form.Control
					type="text"
					onChange={props.changePointsDesc}
					value={props.pointsDesc}
				/>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Medal</Form.Label>
				<ToggleSelector {...medalSelectionProps} />
			</Form.Group>
			{/* <Form.Group className="mb-3">
				<Form.Label>Add Medal</Form.Label> */}

			<div style={buttonDiv} onClick={props.addPoints}>
				<p style={labelStyle}>Add Medal</p>
				<img
					alt="flag icon"
					style={flagStyle}
					src={`assets/countryicons/flag-${props.country.slice(
						0,
						3
					)}.png`}
				></img>
			</div>
		</Form>
	);
}
