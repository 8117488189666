import React from 'react';
import { ListGroup } from 'react-bootstrap';
/**
 * Displays an item that has drag and drop functionality
 * @function DragDropItem
 * @param {Object} props
 * @param {int} props.id - uniquely identifies this item amongst
 * all place where it will be dragged from and dropped to
 * @param {string} props.text - the text to display for this item
 * @returns {React.Component}
 */
export default function DragDropItem(props) {
	const dragStartHandler = e => {
		const target = e.target;
		e.dataTransfer.setData('cardID', props.id);

		setTimeout(() => (target.style.display = 'none'), 0);
	};
	const dragOverHandler = e => e.stopPropagation();
	const dragEndHandler = e => {
		e.target.style.display = 'block';
	};

	const itemStyle = {
		margin: '4px auto',
		// width: '50%',
		alignSelf: 'center',
		cursor: 'all-scroll',
		...props.stylePBox
	};
	let propsItem = [];
	if (props.disabled) {
		return (
			<p draggable="false" className="text-center" style={itemStyle}>
				<ListGroup.Item
					key={props.text}
					style={{ ...props.itemStyle, ...propsItem }}
				>
					<p>{props.text}</p>
				</ListGroup.Item>
			</p>
		);
	}
	return (
		<p
			draggable="true"
			className="text-center"
			style={itemStyle}
			onClick={
				props.clickHandler
					? props.clickHandler.bind(null, props.id)
					: null
			}
			onDragStart={dragStartHandler}
			onDragOver={dragOverHandler}
			onDragEnd={dragEndHandler}
		>
			<ListGroup.Item
				key={props.text}
				style={{ ...props.itemStyle, ...propsItem }}
			>
				<p>{props.text}</p>
			</ListGroup.Item>
		</p>
	);
}
