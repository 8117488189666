import React from 'react';
import PoolBlock from './PoolBlock.js';
import { Row, Col } from 'react-bootstrap';

/**
 * Displays each pool for a sport
 * @function PoolSportRow
 * @param {Object} props
 * @param {string} props.sport - name of the sport
 * @param {Object} props.poolA - props for {@link PoolBlock} for Pool A
 * @param {Object} props.poolB - props for {@link PoolBlock} for Pool B
 * @returns {React.Component}
 */
export default function PoolSportRow(props) {
	return (
		<Row className="align-items-center">
			<Col>
				<img
					alt="sportimage"
					src={`assets/sporticons/ball-${props.sport
						.replace(' ', '-')
						.toLowerCase()}.png`}
				/>
			</Col>
			<Col>
				<PoolBlock poolTeams={props.poolA} />
			</Col>
			<Col>
				<PoolBlock poolTeams={props.poolB} />
			</Col>
		</Row>
	);
}

// export const fakeprops = {
// 	sport: 'Football',
// 	poolA: {
// 		poolTeams: [
// 			'Ch E Female ARG 1',
// 			'Ch E Female COL 2',
// 			'Ch E Female ISR 1',
// 			'Ch E Female VEN 2'
// 		]
// 	},
// 	poolB: {
// 		poolTeams: [
// 			'Ch E Female COL 1',
// 			'Ch E Female ISR 2',
// 			'Ch E Female USA 1',
// 			'Ch E Female VEN 1'
// 		]
// 	}
// };
