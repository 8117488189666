import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { request } from '../global/utils';
import { createSlice } from '@reduxjs/toolkit';

export const getMenu = createAsyncThunk('GET_MENU', async () => {
	try {
		let menu = await request('user/menu/me');
		return Promise.resolve(menu.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
});

const menuSlice = createSlice({
	name: 'menu',
	initialState: {},
	reducers: {
		clearMenu(state, action) {
			return { menuPages: null };
		}
	},
	extraReducers: builder => {
		builder.addCase(getMenu.pending, (state, action) => ({
			...state,
			loading: true
		}));
		builder.addCase(getMenu.fulfilled, (state, action) => ({
			...state,
			loading: false,
			menuPages: action.payload
		}));
		builder.addCase(getMenu.rejected, (state, action) => ({
			...state,
			loading: false,
			menuPages: 'error'
		}));
	}
});

const { actions, reducer } = menuSlice;
export const { clearMenu } = actions;
export const menuReducer = reducer;
