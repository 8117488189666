import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const myCountryScores = createAsyncThunk(
	'GET_COUNTRY_SCORES',
	getMyCountryScores
);

async function getMyCountryScores() {
	try {
		let data = await request('countries/scores/me');
		console.log(data);
		return Promise.resolve(data.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
