import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

/**
 * Selection screen for which categories an event will be made out to
 * @function NewEventCard
 * @param {Object} props
 * @param {function} props.newEvent - function to prompt a new event creation
 * @returns {React.Component}
 */

export default function NewEventCard(props) {
	const iconStyle = {
		alignContent: 'center',
		justifyContent: 'center',
		display: 'flex',
		alignSelf: 'center',
		color: 'green',
		fontSize: '60px'
	};
	return (
		<Card
			onClick={props.newEvent}
			style={{ width: '18rem' }}
			className="mx-2 text-center"
		>
			<Card.Body
				style={{
					height: '150px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer'
				}}
			>
				<FontAwesomeIcon style={iconStyle} icon={faCirclePlus} />
			</Card.Body>
		</Card>
	);
}
