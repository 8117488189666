import React from 'react';
import { connect } from 'react-redux';
import ManagePeopleBoards from '../components/logistics/ManagePeopleBoards.js';
import SingleSelectList from '../components/general/SingleSelectList.js';
import {
	addusertogroup,
	removeuserfromgroup,
	manageCoachInfo,
	managecountryinfo
} from '../services/managepeople/managepeopleActions.js';
import { Container, Card, Col, Row } from 'react-bootstrap';

class CaptainManagePeopleView extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedTeam: ''
		};

		this.selectTeam = this.selectTeam.bind(this);
	}

	componentDidMount() {
		this.props.getTeamOptions().then(() => {
			this.props.getManageCoachInfo(this.props.teams[0].id).then(() => {
				this.setState({
					selectedTeam: this.props.teams[0].id
				});
			});
		});
	}

	selectTeam(team) {
		this.props.getManageCoachInfo(team).then(() => {
			this.setState({
				selectedTeam: team
			});
		});
	}

	render() {
		if (this.props.teams === null || this.state.selectedTeam === '') {
			return <p>Loading</p>;
		} else {
			const selectedTeamStyle = {
				borderRadius: '15px',
				padding: '3px 20px',
				margin: '5px',
				cursor: 'pointer',
				backgroundColor: '#1857C3',
				color: '#fff'
			};

			let currentTeam = this.props.teams.find(
				x => x.id === this.state.selectedTeam
			).text;

			return (
				<div>
					<Card>
						<Card.Header
							style={{
								fontSize: 30,
								fontWeight: '500',
								color: '#4E73DF'
							}}
						>
							Manage People
						</Card.Header>
						<Card.Body>
							<Container>
								<Row>
									<Col className="col-4">
										<SingleSelectList
											itemsList={this.props.teams.map(
												x => x.text
											)}
											selectItem={this.selectTeam}
											selectedItem={currentTeam}
											selectedItemStyle={
												selectedTeamStyle
											}
											searchable={true}
											divStyle={{ width: '17vw' }}
										/>
									</Col>
									<Col className="col-8">
										<ManagePeopleBoards
											key={`captain-managepplboards`}
											unselectedSearchable={true}
											selectedSearchable={false}
											unselectedLabel="Non Coaches"
											selectedLabel="Coaches"
											selectedType="COACH"
											unselectedUsers={this.props.coachInfoByTeam[
												this.state.selectedTeam
											].potentialcoaches.map(x => {
												return {
													id: x.username,
													text: x.name
												};
											})}
											selectedUsers={this.props.coachInfoByTeam[
												this.state.selectedTeam
											].coaches.map(x => {
												return {
													id: x.username,
													text: x.name
												};
											})}
											removeUserFromGroup={this.props.removeCoach.bind(
												null,
												this.state.selectedTeam
											)}
											addUserToGroup={this.props.addCoach.bind(
												null,
												this.state.selectedTeam
											)}
										/>
									</Col>
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	let raw = state.managepeople;
	if (raw.captainteams) {
		let teamsList = raw.captainteams.map(x => {
			return {
				id: x.name,
				text: x.name
			};
		});

		return {
			teams: teamsList,
			coachInfoByTeam: raw.coachInfoByTeam
		};
	} else {
		return {
			teams: null,
			coachInfoByTeam: null
		};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getTeamOptions: () => dispatch(managecountryinfo()),
		addUserToGroup: (group, user) =>
			dispatch(addusertogroup({ group, user })),
		removeUserFromGroup: (group, user) =>
			dispatch(removeuserfromgroup({ group, user })),
		addCaptain: (country, group, user) => {
			user.country = country;
			dispatch(addusertogroup({ group, user }));
		},
		addCoach: (team, group, user) => {
			user.team_id = team;
			dispatch(addusertogroup({ group, user }));
		},
		removeCoach: (team, group, user) => {
			user.team_id = team;
			dispatch(removeuserfromgroup({ group, user }));
		},
		getManageCoachInfo: team => dispatch(manageCoachInfo(team))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CaptainManagePeopleView);
