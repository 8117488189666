import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const scorechangerequests = createAsyncThunk(
	'GET_SCORECHANGEREQUESTS',
	getScoreChangeRequests
);
export const rejectscorechangerequest = createAsyncThunk(
	'REJECT_SCORECHANGEREQUEST',
	rejectScoreChangeRequest
);
export const acceptscorechangerequest = createAsyncThunk(
	'ACCEPT_SCORECHANGEREQUEST',
	acceptScoreChangeRequest
);
export const scorechangelog = createAsyncThunk(
	'GET_MASTER_SCORECHANGES',
	getMasterScoreChanges
);

async function getMasterScoreChanges(body) {
	try {
		const result = await request(`game/scores/revisions/log`);

		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function acceptScoreChangeRequest(body) {
	try {
		await request(`game/scores/revisions/${body.gameId}`, 'put', body);

		return Promise.resolve(await getScoreChangeRequests());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function rejectScoreChangeRequest(body) {
	try {
		await request(`game/scores/revisions/${body.gameId}`, 'delete', body);

		return Promise.resolve(await getScoreChangeRequests());
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getScoreChangeRequests() {
	try {
		let result = await request('game/scores/revisions');
		let scores = result.data.content[1];
		let scorechanges = result.data.content[2];

		return Promise.resolve({ scores, scorechanges });
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
