import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const schedData = createAsyncThunk('GET_SUBSCRIBED', getInit);
export const getTeamSchedule = createAsyncThunk(
	'GET_ALLTEAMSHED',
	getAllTeamSched
);
export const getVersions = createAsyncThunk(
	'GET_TEAMSHED',
	getScheduleVersions
);

async function getInit(teams) {
	try {
		let data;
		if (!teams) data = await request('schedule/teamschedules');
		else
			data = await request(
				`schedule/teamschedules?teams=${teams.join()}`
			);

		return Promise.resolve(data.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getAllTeamSched(versionId) {
	try {
		let data = await request(`schedule/teamschedules/all/${versionId}`);
		return Promise.resolve(data.data.content.games);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getScheduleVersions(versionId) {
	try {
		let data = await request(`schedule/versions`);
		return Promise.resolve(data.data);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
