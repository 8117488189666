import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
export default function AssignedPlayersArea(props) {
	const boardStyle = {
		display: 'flex',
		flexDirection: 'column'
	};
	const dropHandler = e => {
		e.preventDefault();
		const cardID = e.dataTransfer.getData('cardID');

		// Check if ID is already in area
		let existingIDs = props.children.map(x => x.props.id);
		if (!existingIDs.includes(cardID)) {
			props.cardDropHandler({
				registrantid: cardID,
				teamid: props.teamID
			});
		}
	};

	const dragOverHandler = e => e.preventDefault();

	if (props.viewOnly) {
		return (
			<Container>
				<Row
					className="p-2 m-1"
					style={{
						backgroundColor: '#4066D4',
						color: 'white',
						fontSize: 20
					}}
				>
					<Col className="text-nowrap">
						<p>{props.teamName}</p>
					</Col>
					<Col className="text-end text-nowrap">
						<p>{props.children.length}</p>
					</Col>
				</Row>
				<Row>
					<div style={boardStyle}>{props.children}</div>
				</Row>
			</Container>
		);
	} else {
		// console.log("Props in Assigned Area", props)
		return (
			<div>
				<Container className="">
					<Row
						className="p-2 m-1"
						style={{
							backgroundColor: '#4066D4',
							color: 'white',
							fontSize: 20
						}}
					>
						<Col className="text-nowrap">
							<p>{props.teamName}</p>
						</Col>
						<Col className="text-end text-nowrap">
							<p>{props.children.length}</p>
						</Col>
					</Row>
					<Row>
						<div
							style={boardStyle}
							onDragOver={dragOverHandler}
							onDrop={dropHandler}
						>
							{props.children}
						</div>
					</Row>
				</Container>
			</div>
		);
	}
}
