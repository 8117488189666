import { createReducer } from '@reduxjs/toolkit';
import { redyellowcards } from './redyellowcardActions';

const redYellowCardReducer = createReducer({}, builder => {
	builder.addCase(redyellowcards.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(redyellowcards.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			cards: action.payload
		};
	});

	builder.addCase(redyellowcards.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));
});

export default redYellowCardReducer;
