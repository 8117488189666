import React from 'react';

export default function DoubleBookedGame(props) {
	const divStyle = {
		display: 'flex',
		margin: '3px 0'
	};

	let gameStyle = {
		backgroundColor: props.backgroundColor,
		color: props.textColor,
		alignSelf: 'center',
		borderRadius: '5px',
		padding: '3px 10px',
		border: '1px solid #808080'
	};

	let ballStyle = {
		width: '30px',
		marginRight: '5px'
	};

	return (
		<div style={divStyle}>
			<img
				alt="ball sport"
				style={ballStyle}
				src={`assets/sporticons/ball-${props.sport}.png`}
			/>
			<p style={gameStyle}>{props.gameTitle}</p>
		</div>
	);
}

// export const fakeprops = {
// 	backgroundColor: 'yellow',
// 	sport: 'Mini Soccer',
// 	gameTitle: 'ARG1-COL1',
// 	gameID: 6
// };
