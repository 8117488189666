import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const controlpanelData = createAsyncThunk(
	'GET_CONTROLPANEL_INFO',
	getControlPanelInfo
);
export const changeShabbatMode = createAsyncThunk(
	'UPDATE_SHABBATMODE',
	updateShabbatMode
);
export const dismisslocations = createAsyncThunk(
	'DISMISS_DBL_LOCATIONS',
	dismissDoubleBookedLocations
);
export const dismissteams = createAsyncThunk(
	'DISMISS_DBL_TEAMS',
	dismissDoubleBookedTeams
);

async function getControlPanelInfo() {
	try {
		let shabbatmode = await request('logistics/shabbatmode');
		let schedule = await request('schedule/doublebooked');

		let output = {
			...schedule.data.content,
			...shabbatmode.data
		};

		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function updateShabbatMode(newVal) {
	try {
		await request('logistics/shabbatmode', 'put', newVal);

		return Promise.resolve(newVal);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function dismissDoubleBookedLocations(raw) {
	try {
		await request('schedule/doublebooked', 'put', {
			type: 'locations',
			info: raw.bookingInfo,
			gameIDs: raw.gameIDs
		});

		let shabbatmode = await request('logistics/shabbatmode');
		let schedule = await request('schedule/doublebooked');

		let output = {
			...schedule.data.content,
			...shabbatmode.data.content
		};

		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function dismissDoubleBookedTeams(raw) {
	try {
		await request('schedule/doublebooked', 'put', {
			type: 'teams',
			info: raw.bookingInfo,
			gameIDs: raw.gameIDs
		});

		let shabbatmode = await request('logistics/shabbatmode');
		let schedule = await request('schedule/doublebooked');

		let output = {
			...schedule.data.content,
			...shabbatmode.data.content
		};

		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
