import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const redyellowcards = createAsyncThunk('GET_CARD_INFO', getCardInfo);

async function getCardInfo() {
	try {
		let output = await request('discipline/cards');
		return Promise.resolve(output.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
