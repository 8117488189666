import { createReducer } from '@reduxjs/toolkit';
import {
	getsiteassignments,
	getsitedirectors,
	assignsitedirector
} from './logisticsActions.js';

const logisticsReducer = createReducer({}, builder => {
	builder.addCase(getsiteassignments.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getsiteassignments.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(getsitedirectors.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(getsitedirectors.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});

	builder.addCase(assignsitedirector.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(assignsitedirector.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			...action.payload
		};
	});
});

export default logisticsReducer;
