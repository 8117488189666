import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const broadcastpermissions = createAsyncThunk(
	'GET_BROADCAST_PERMISSIONS',
	getBroadcastPermissions
);
export const getrecipients = createAsyncThunk('GET_RECIPIENTS', getRecipients);
export const broadcast = createAsyncThunk('BROADCAST', sendBroadcast);

async function getBroadcastPermissions() {
	try {
		let permissions = await request('posts/permissions/me');
		let availabledates = await request('posts/availabledates');
		let output = {
			...permissions.data.content,
			...availabledates.data.content
		};
		return Promise.resolve(output);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getRecipients(obj) {
	try {
		let result = await request(
			`posts/recipients/${obj.audience}/${obj.option}`
		);
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function sendBroadcast(obj) {
	try {
		let title = null;
		if (obj.selectedAudience === 'COUNTRY') title = obj.selectedOption;
		else if (obj.selectedAudience === 'TEAM') title = obj.selectedOption;

		console.log(obj);

		const reqBody = {
			datetime:
				obj.selectedTimeframe === 'Immediately'
					? 'Immediately'
					: `${obj.selectedDate} ${obj.selectedTime}`,
			allowComments: obj.commentStatus !== 'Disabled',
			audience: obj.selectedAudience,
			groupname: obj.selectedOption,
			message: obj.message,
			title: title
		};
		await request(`posts/`, 'post', reqBody);
		return Promise.resolve();
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
