import React from 'react';
import GenericTableRow from './GenericTableRow.js';

/**
 * Displays a table of data
 * @function GenericTable
 * @param {Object} props
 * @param {Object[]} props.data - list of objects, where each object represents a row.
 * Each key in the object corresponds to a potential column. Each object must have a
 * field called 'key' with a unique identifier.
 * @param {Object[]} props.headers - list of objects, where each object is for a column.
 * The object has two properties: value (the string corresponding to the key for this column),
 * and text (the text to display as the column header)
 * @param {Object[]} props.colsToHide - list of keys corresponding to keys in props.data
 * that should not be shown
 * @returns {React.Component}
 */
export default function GenericTable(props) {
	let displayCols;
	if (props.colsToHide) {
		displayCols = props.headers.filter(
			x => !props.colsToHide.includes(x.value)
		);
	} else {
		displayCols = props.headers;
	}

	let rowStyle = {
		borderBottom: 'solid black 2px'
	};

	return (
		<table style={props.style}>
			<thead>
				<tr style={rowStyle}>
					{displayCols.map(x => (
						<th key={x.value}>{x.text}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.data.map(x => (
					<GenericTableRow
						key={x.key}
						headers={displayCols}
						data={x}
					/>
				))}
			</tbody>
		</table>
	);
}
