import { Hub } from 'aws-amplify/utils';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { switchUserProfile } from './authActions.js';
import { getMenu, clearMenu } from './../menu.js';

/*
High Level Goal:
- Update the redux store with user info from cognito every time a user auth event happens
*/
export default class AmplifyBridge {
	constructor(store) {
		this.store = store;
		this.checkUser = this.checkUser.bind(this);
		Hub.listen('auth', this.checkUser); // Add this component as a listener of auth events.
		this.checkUser();
	}
	componentDidMount() {
		this.checkUser(); // first check
		Hub.listen('auth', function (authData) {
			if (authData.payload.event === 'signOut') {
				// rerender or do something here in the hamburger menu
			}
		});
	}

	// On auth event, call this
	async checkUser() {
		try {
			const { tokens: session } = await fetchAuthSession();

			const user = {
				name: session.idToken.payload.name,
				token: session.idToken.toString(),
				groups: session.idToken.payload['cognito:groups']
			};
			this.store.dispatch(switchUserProfile(user));
			this.store.dispatch(getMenu());
		} catch (err) {
			console.error('No user signed in');
			this.store.dispatch(clearMenu());
			this.store.dispatch(switchUserProfile(null));
		}
	}
}
