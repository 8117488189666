import React from 'react';
import DragDropItem from '../general/DragDropItem';
import DragDropList from '../general/DragDropList';
import { Row, Col } from 'react-bootstrap';

/**
 * @class ManageSiteDirectors
 * @classdesc Page to add/remove site directors
 * @param {Object} props
 * @param {Object[]} props.unselectedUsers - A list of people who
 * can become selected users. Each element is props for {@link DragDropItem}
 * @param {Object[]} props.selectedUsers - A list of people who are
 * selected users. Each element is props for {@link DragDropItem}
 * @param {function} props.saveChanges - callback function for saving
 * the changes made to the user types. Takes one argument (an object with
 * two keys: selectedUsers and unselectedUsers). The format of selectedUsers
 * and unselectedUsers is the same as what is passed in through the props
 * @param {boolean} props.unselectedSearchable - whether the unselected users
 * should be searchable or not
 * @param {boolean} props.selectedSearchable - whether the selected users should
 * be searchable or not
 * @returns {React.Component}
 */
export default class ManagePeopleBoards extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			unselectedUserSearchText: '',
			selectedUserSearchText: ''
		};

		this.makeSelectedUser = this.makeSelectedUser.bind(this);
		this.removeSelectedUser = this.removeSelectedUser.bind(this);
		this.updateUnselectedUserSearch =
			this.updateUnselectedUserSearch.bind(this);
		this.updateSelectedUserSearch =
			this.updateSelectedUserSearch.bind(this);
	}

	updateUnselectedUserSearch(event) {
		this.setState({
			unselectedUserSearchText: event.target.value
		});
	}

	updateSelectedUserSearch(event) {
		this.setState({
			selectedUserSearchText: event.target.value
		});
	}

	makeSelectedUser(id) {
		console.log(id);
		const chosenUser = this.props.unselectedUsers.find(x => x.id === id);
		console.log(chosenUser);
		if (chosenUser !== undefined) {
			this.props.addUserToGroup(this.props.selectedType, chosenUser);
		}
	}

	removeSelectedUser(id) {
		const chosenUser = this.props.selectedUsers.find(x => x.id === id);
		if (chosenUser !== undefined) {
			this.props.removeUserFromGroup(this.props.selectedType, chosenUser);
		}
	}

	render() {
		let unselectedUsersToDisplay = this.props.unselectedUsers;
		let selectedUsersToDisplay = this.props.selectedUsers;

		if (this.state.updateUnselectedUserSearch !== '') {
			unselectedUsersToDisplay = unselectedUsersToDisplay.filter(x => {
				return x.text
					.toLowerCase()
					.includes(
						this.state.unselectedUserSearchText.toLowerCase()
					);
			});
		}

		if (this.state.updateSelectedUserSearch !== '') {
			selectedUsersToDisplay = selectedUsersToDisplay.filter(x => {
				return x.text
					.toLowerCase()
					.includes(this.state.selectedUserSearchText.toLowerCase());
			});
		}

		let unselectedSearchRender = null;

		if (this.props.unselectedSearchable) {
			unselectedSearchRender = (
				<input
					onChange={this.updateUnselectedUserSearch}
					value={this.state.unselectedUserSearchText}
				></input>
			);
		}

		let selectedSearchRender = null;

		if (this.props.selectedSearchable) {
			selectedSearchRender = (
				<input
					onChange={this.updateSelectedUserSearch}
					value={this.state.selectedUserSearchText}
				></input>
			);
		}

		return (
			<Row>
				<Col>
					<Row>
						<Col>
							<p>{this.props.unselectedLabel}</p>
						</Col>
						<Col>{unselectedSearchRender}</Col>
					</Row>
					<DragDropList cardDropHandler={this.removeSelectedUser}>
						{unselectedUsersToDisplay.map(element => (
							<DragDropItem
								key={element.id}
								{...element}
								clickHandler={this.props.unselectedClickHandler}
							/>
						))}
					</DragDropList>
				</Col>
				<Col>
					<p>{this.props.selectedLabel}</p>
					{selectedSearchRender}
					<DragDropList
						className=""
						cardDropHandler={this.makeSelectedUser}
					>
						{selectedUsersToDisplay.map(element => (
							<DragDropItem
								key={element.id}
								{...element}
								clickHandler={this.props.selectedClickHandler}
							/>
						))}
					</DragDropList>
				</Col>
			</Row>
		);
	}
}

// export const fakeprops = {
// 	saveChanges: (stateobj) => console.log(stateobj),
// 	unselectedLabel: 'Non Site Directors',
// 	selectedLabel: 'Site Directors',
// 	unselectedSearchable: true,
// 	selectedSearchable: false,
// 	unselectedUsers: [
// 		{ text: "Jenny Katz", id: 14 },
// 		{ text: "Silvia Galante", id: 15 },
// 		{ text: "Tom Kirkman", id: 16 }
// 	],
// 	selectedUsers: [
// 		{ text: "Joseph Levy", id: 7 },
// 		{ text: "Eyal Abadi", id: 12 },
// 		{ text: "Joseph Bensabat", id: 13 },
// 		{ text: "Marleny Rosenberg", id: 71 }
// 	]
// }
