import React from 'react';
import {
	Modal,
	Button,
	Container,
	Row,
	Col,
	Form,
	ListGroup
} from 'react-bootstrap';
import TeamSchedule from './TeamSchedule.js';

export default function AffectedTeamSchedules(props) {
	let stime = new Date();
	stime.setHours(...props.stime.split(':').map(x => parseInt(x)));

	let etime = new Date();
	etime.setHours(...props.etime.split(':').map(x => parseInt(x)));

	let sday = new Date(props.sday);
	let eday = new Date(props.eday);

	let sortedTeamSchedules = Object.values(props.affectedTeamSched).sort(
		(a, b) => (a.title > b.title ? 1 : -1)
	);

	return (
		<Modal show={true} size="xl" onHide={props.cancelHandler}>
			<Modal.Header closeButton>
				<Modal.Title>Affected Team Schedules</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{sortedTeamSchedules.map(x => {
					return (
						<TeamSchedule
							key={x.title}
							team={x.title}
							events={x.games.map(y => {
								return {
									...y,
									start: new Date(y.start),
									end: new Date(y.end)
								};
							})}
							startTime={stime}
							endTime={etime}
							startDate={sday}
							endDate={eday}
						></TeamSchedule>
					);
				})}
				<Modal.Footer>
					<Button variant="primary" onClick={props.confirmHandler}>
						Confirm
					</Button>
					<Button variant="secondary" onClick={props.cancelHandler}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal.Body>
		</Modal>
	);
}
