import { createReducer } from '@reduxjs/toolkit';
import {
	eventsData,
	createNewEvt,
	deleteEvt,
	editOldEvt
} from './eventsActions.js';

const eventsReducer = createReducer({}, builder => {
	builder.addCase(eventsData.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending'
	}));

	builder.addCase(eventsData.fulfilled.type, (state, action) => {
		return {
			...state,
			requestStatus: 'fulfilled',
			data: action.payload
		};
	});

	builder.addCase(eventsData.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error'
	}));

	builder.addCase(createNewEvt.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending newEvt'
	}));

	builder.addCase(createNewEvt.fulfilled.type, (state, action) => {
		let temp = JSON.parse(JSON.stringify(state.data));
		temp.events.push(action.payload);
		return {
			...state,
			requestStatus: 'fulfilled newEvt',
			data: temp
		};
	});

	builder.addCase(createNewEvt.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error newEvt'
	}));

	builder.addCase(deleteEvt.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending deleteEvt'
	}));

	builder.addCase(deleteEvt.fulfilled.type, (state, action) => {
		let temp = JSON.parse(JSON.stringify(state.data));
		temp.events = temp.events.filter(
			obj => obj.event_id !== parseInt(action.payload)
		);
		return {
			...state,
			requestStatus: 'fulfilled deleteEvt',
			data: temp
		};
	});

	builder.addCase(deleteEvt.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error deleteEvt'
	}));

	builder.addCase(editOldEvt.pending.type, (state, action) => ({
		...state,
		requestStatus: 'pending oldEvt'
	}));

	builder.addCase(editOldEvt.fulfilled.type, (state, action) => {
		const isIndex = element => element.event_id === action.payload.event_id;
		let temp = JSON.parse(JSON.stringify(state.data));
		temp.events.splice(temp.events.findIndex(isIndex), 1, action.payload);
		return {
			...state,
			requestStatus: 'fulfilled oldEvt',
			data: temp
		};
	});

	builder.addCase(editOldEvt.rejected.type, (state, action) => ({
		...state,
		requestStatus: 'error oldEvt'
	}));
});

export default eventsReducer;
