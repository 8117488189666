import React, { useState, useEffect } from 'react';
import { request } from '../global/utils.js';
import { cloudResources } from '../global/cloudresources.js';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default class DatabaseInfoView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			apispec: null
		};
	}

	async componentDidMount() {
		let apispec_data = await request('logistics/cloudapispec');
		this.setState({ apispec: apispec_data.data });
	}

	render() {
		if (this.state.apispec) {
			return (
				<div style={{ width: '100%' }}>
					<SwaggerUI spec={this.state.apispec} />
				</div>
			);
		} else return <p>Loading...</p>;
	}
}
