import React from 'react';
import './styles.css';
import ToggleSelector from '../general/ToggleSelector.js';

//date and time format

export default function CategoryPanel(props) {
	// console.log("Categories in panel ", props)
	let headingsShown = ['All'];
	headingsShown.push(...props.categories);
	return (
		<div className="nav">
			<ToggleSelector
				itemsList={headingsShown}
				selectionObj={props.selectionObj}
			/>
			{/* <Headings headings={headingsShown} showFilteredItems={props.showFilteredItems} /> */}
		</div>
	);
}

// const Headings = ({ headings, showFilteredItems }) => (
//     <ul className="heading-class">
//       {headings.map((heading) => (
//         <li className="heading-li" key={heading}>
//           <a className="heading-item" href={`#${heading}`} onClick={() => showFilteredItems(heading)}>{heading}</a>
//         </li>
//       ))}
//     </ul>
//   );
