import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../global/utils.js';

export const masterschedulechangelog = createAsyncThunk(
	'GET_MASTER_SCHEDULECHANGES',
	getMasterScheduleChanges
);
export const refschedulechangelog = createAsyncThunk(
	'GET_REF_SCHEDULECHANGES',
	getRefScheduleChanges
);

async function getMasterScheduleChanges() {
	try {
		const result = await request(`game/schedulechanges`);
		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}

async function getRefScheduleChanges() {
	try {
		const result = await request(`game/schedulechanges/ref`);

		return Promise.resolve(result.data.content);
	} catch (err) {
		console.log(err);
		return Promise.reject(err);
	}
}
