import React from 'react';
import { connect } from 'react-redux';
import { refschedule } from '../services/schedule/masterscheduleActions.js';
import { Container, Card, Button } from 'react-bootstrap';
import RefScheduleRow from '../components/schedule/RefScheduleRow.js';
import { downloadExcel } from '../global/utils.js';

class RefScheduleView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			refSchedule: []
		};
	}
	componentDidMount() {
		this.props.getRefSchedule().then(() => {
			this.setState({ refSchedule: this.props.refschedule });
		});
	}

	render() {
		let theaderStyle = {
			textAlign: 'left',
			paddingBottom: '15px',
			fontSize: '15px'
		};

		return (
			<Card>
				<Card.Header
					style={{
						fontSize: 30,
						fontWeight: '500',
						color: '#4E73DF'
					}}
				>
					My Schedule
				</Card.Header>
				<Card.Body>
					<Container>
						<Button
							variant="outline-primary"
							onClick={() => {
								downloadExcel('refscheduletable', 'schedule');
							}}
						>
							Download as Excel
						</Button>
						<table
							className="table table-striped"
							id="refscheduletable"
						>
							<thead style={theaderStyle}>
								<tr>
									<th className="text-center" scope="col">
										Sport
									</th>
									<th className="text-center" scope="col">
										Date
									</th>
									<th className="text-center" scope="col">
										Time
									</th>
									<th className="text-center" scope="col">
										Location
									</th>
									<th className="text-center" scope="col">
										Type
									</th>
									<th className="text-center" scope="col">
										Category/Gender
									</th>
									<th className="text-center" scope="col">
										Teams
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.refSchedule.map(x => {
									return (
										<RefScheduleRow
											key={`${x.date}-${x.time}-${x.location}`}
											{...x}
										/>
									);
								})}
							</tbody>
						</table>
					</Container>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	let raw = state.masterschedule;
	if (raw.refschedule) {
		return {
			refschedule: raw.refschedule
		};
	} else return { refschedule: null };
};

const mapDispatchToProps = dispatch => {
	return {
		getRefSchedule: () => dispatch(refschedule())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RefScheduleView);
