import React from 'react';

export default function Arrow(props) {
	return (
		<line
			x1={props.pt1.x}
			y1={props.pt1.y}
			x2={props.pt2.x}
			y2={props.pt2.y}
			stroke={props.color}
			strokeWidth="3"
			markerEnd={`url(#endarrow${props.color})`}
			markerStart={`url(#startarrow${props.color})`}
		></line>
	);
}
