import React from 'react';
import ColorChartRow from './ColorChartRow.js';
import './multigame.css';
/**
 * Displays the schedule of games for a single day
 * @function ColorChart
 * @param {Object} props
 * @param {string[]} props.firstRowLocs - each element is a field location (first row of timeslot)
 * @param {string[]} props.secondRowLocs - each element is a field location (second row of timeslot)
 * @param {Object[]} props.schedule - each element represents a timeslot and is props for {@link StaticScheduleRow}
 * @returns {React.Component}
 */
export default function ColorChart(props) {
	//Default style for table
	const tableStyle = {
		borderSpacing: '0px',
		width: '100%',
		height: '100%'
	};

	/* Table element with a split row in the header for game locations
	Then a split row for each timeslot
	*/
	return (
		<table style={tableStyle} className="colorchart">
			<thead>
				<tr>
					<th></th>
					{props.firstRowLocs.map(x => {
						return (
							<th
								style={{
									whiteSpace: 'nowrap',
									fontSize: '12px'
								}}
								key={x.nickname}
							>
								{x.nickname}
							</th>
						);
					})}
				</tr>
				<tr>
					<th></th>
					{props.secondRowLocs.map(x => {
						return (
							<th
								style={{
									whiteSpace: 'nowrap',
									fontSize: '12px'
								}}
								key={x.nickname}
							>
								{x.nickname}
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{props.schedule.map(x => (
					<ColorChartRow
						key={`${props.version}${props.date};${x.time}`}
						date={props.date}
						version={props.version}
						firstRowLocs={props.firstRowLocs}
						secondRowLocs={props.secondRowLocs}
						sportsByLoc={props.sportsByLoc}
						updateMessage={props.updateMessage}
						createGameHandler={props.createGameHandler}
						showScores={props.showScores}
						hideScores={props.hideScores}
						filter={props.filter}
						selectDate={props.selectDate}
						summonMode={props.summonMode}
						addToSummonList={props.addToSummonList}
						hideScoredGames={props.hideScoredGames}
						{...x}
					/>
				))}
			</tbody>
		</table>
	);
}
