import React from 'react';
import StaticScheduleRow from './StaticScheduleRow.js';

/**
 * Displays the schedule of games for a single day
 * @function StaticSchedule
 * @param {Object} props
 * @param {string[]} props.firstRow - each element is a field location (first row of timeslot)
 * @param {string[]} props.secondRow - each element is a field location (second row of timeslot)
 * @param {Object[]} props.schedule - each element represents a timeslot and is props for {@link StaticScheduleRow}
 * @returns {React.Component}
 */
export default function StaticSchedule(props) {
	//Default style for table
	const tableStyle = {
		borderSpacing: '0px'
	};

	/* Table element with a split row in the header for game locations
	Then a split row for each timeslot
	*/
	return (
		<table style={tableStyle}>
			<thead>
				<tr>
					<th></th>
					{props.firstRow.map(x => {
						return <th key={x.nickname}>{x.nickname}</th>;
					})}
				</tr>
				<tr>
					<th></th>
					{props.secondRow.map(x => {
						return <th key={x.nickname}>{x.nickname}</th>;
					})}
				</tr>
			</thead>
			<tbody>
				{props.schedule.map(x => (
					<StaticScheduleRow
						key={`${props.date};${x.time}`}
						date={props.date}
						{...x}
					/>
				))}
			</tbody>
		</table>
	);
}
