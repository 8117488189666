import React, { useEffect } from 'react';
import {gapi} from 'gapi-script'

export function GoogleLoginButton({ onSuccess }) {
  useEffect(() => {
    const handleClientLoad = () => {
      gapi.load('client:auth2', initClient);
    };

    const initClient = async () => {
      await gapi.client.init({
        clientId: '269247350564-9u31ddvc2er43t3ph9e5fmnnloat0fu8.apps.googleusercontent.com',
        scope: "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/script.external_request https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/spreadsheets"
      });
    };

    gapi.load('client:auth2', handleClientLoad);
  }, []); // Run on mounting

  const handleSignIn = async () => {
    try {
      await gapi.auth2.getAuthInstance().signIn();
      const user = gapi.auth2.getAuthInstance().currentUser.get();
      const token = user.getAuthResponse().access_token;
      console.log("Access token:", token);
      onSuccess(token);
    } catch (error) {
      console.error('Google Sign-in Error:', error);
    }
  };

  return (
    <button onClick={handleSignIn}>
      Generate Family Cards
    </button>
  );
}

export default GoogleLoginButton;