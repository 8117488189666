import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

/**
 * Shows the standings for a team in a specific tournament
 * @function StandingsCard
 * @param {Object} props
 * @param {int} props.ranking - team ranking in pool
 * @param {int} props.wins - number of wins for the team
 * @param {int} props.losses - number of losses for the team
 * @param {int} props.ties - number of ties for the team
 * @param {string} props.points
 * @param {string} props.headToHead
 * @param {string} props.pointDifferential
 * @param {string} props.headToHead2
 * @param {string} props.goalsInFavor
 * @param {string} props.teamName - name of the team
 * @param {string} props.teamShirt - name of the file for the team's shirt
 * @returns {React.Component}
 */
export default function StandingsCard(props) {
	const cardStyle = {
		backgroundColor: '#FBFBFB',
		margin: '5px 1px',
		width: '300px',
		display: 'flex',
		margin: '3.5% 30px',
		borderRadius: '15px',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'space-around'
	};

	const colStyle = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	};

	let rankStyle = {
		color: '#1857C3',
		fontSize: '250%',
		margin: '-10px 0'
	};

	if (props.qualify === 'Yes') {
		rankStyle.color = 'green';
	} else if (props.qualify === 'No') {
		rankStyle.color = 'red';
	}

	const strStyle = {
		margin: '0',
		color: '#1857C3',
		fontSize: '90%'
	};

	const strStyleB = {
		margin: '0',
		fontWeight: '500',
		color: '#000',
		fontSize: '90%'
	};

	const negMarg = {
		paddingTop: '-10px',
		textAlign: 'center'
	};

	const headerStyle = {
		margin: '0',
		color: '#000',
		fontSize: '90%'
	};

	const shirtStyle = {
		width: '35px'
	};

	const unbold = {
		fontWeight: '500',
		padding: '0 3px'
	};

	let medalRender = null;
	if (props.medal) {
		if (props.medal == 1) {
			medalRender = (
				<FontAwesomeIcon
					icon={faMedal}
					style={{ color: 'silver' }}
					size="3x"
				/>
			);
		} else {
			medalRender = (
				<FontAwesomeIcon
					icon={faMedal}
					style={{ color: 'gold' }}
					size="3x"
				/>
			);
		}
	}

	return (
		<div style={cardStyle}>
			<div style={colStyle}>{medalRender}</div>
			<div style={colStyle}>
				<p style={rankStyle}>0{props.ranking}</p>
				<p>Ranking</p>
			</div>
			<div style={colStyle}>
				<p style={strStyleB}>W-L-T</p>
				<p
					style={strStyle}
				>{`${props.wins}-${props.losses}-${props.ties}`}</p>
				<table style={negMarg}>
					<thead style={headerStyle}>
						<tr>
							{props.tiebreaker_points.map(x => {
								return (
									<th
										style={unbold}
										key={`header-${x.tiebreaker}`}
									>
										{x.tiebreaker.toUpperCase()}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody style={strStyle}>
						<tr>
							{props.tiebreaker_points.map(x => {
								return (
									<td
										style={negMarg}
										key={`points-${x.tiebreaker}`}
									>
										{x.points !== null ? x.points : '-'}
									</td>
								);
							})}
						</tr>
					</tbody>
				</table>
			</div>
			<div style={colStyle}>
				<img
					alt="team shirt"
					style={shirtStyle}
					src={`assets/teamshirts/shirt-${props.teamShirt.toLowerCase()}.png`}
				/>
				<p>{props.teamName}</p>
			</div>
		</div>
	);
}

export const fakeprops = {
	ranking: 1,
	wins: 1,
	losses: 0,
	ties: 0,
	points: '2',
	headToHead: '-',
	pointDifferential: '-',
	headToHead2: '-',
	goalsInFavor: '-',
	teamName: 'BRA 1',
	teamShirt: 'ven1'
};
