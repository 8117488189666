import React from 'react';
import { connect } from 'react-redux';
import {
	backtobackgames,
	backtobackdates
} from '../services/schedule/backtobackActions.js';
import {
	Card,
	Container,
	Col,
	Row,
	Dropdown,
	DropdownButton
} from 'react-bootstrap';

class BackToBackView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: 'Date'
		};

		this.selectDate = this.selectDate.bind(this);
	}

	componentDidMount() {
		this.props.getDates();
	}

	async selectDate(date) {
		this.setState({
			selectedDate: date
		});

		this.props.getB2B(date);
	}

	render() {
		let backToBackRender = null;
		if (this.props.backtoback.hasOwnProperty(this.state.selectedDate)) {
			let selectedB2Bs = this.props.backtoback[this.state.selectedDate];
			backToBackRender = Object.keys(selectedB2Bs).map(x => {
				return (
					<Row>
						<Col className="d-flex flex-column">
							<p>{x}</p>
							<ul className="ms-3em">
								{selectedB2Bs[x].map(y => {
									return (
										<li>
											{y.location} | {y.team}{' '}
											<span
												style={
													{
														/*backgroundColor: '#39FF14'*/
													}
												}
											>
												(coming from {y.past_location})
											</span>
										</li>
									);
								})}
							</ul>
						</Col>
					</Row>
				);
			});
		}

		let selectedDateFormatted;
		if (this.state.selectedDate !== 'Date') {
			selectedDateFormatted = this.props.dates.find(
				y => y.rawDate === this.state.selectedDate
			).formatDate;
		} else {
			selectedDateFormatted = 'Date';
		}

		return (
			<Card>
				<Card.Header
					style={{
						fontSize: 30,
						fontWeight: '500',
						color: '#4E73DF'
					}}
				>
					Back-to-Back Games
				</Card.Header>
				<Card.Body>
					<Container>
						<Row>
							<DropdownButton
								id="dropdown-basic-button"
								title={selectedDateFormatted}
							>
								{this.props.dates.map(x => {
									return (
										<Dropdown.Item
											onClick={this.selectDate.bind(
												null,
												x.rawDate
											)}
										>
											{x.formatDate}
										</Dropdown.Item>
									);
								})}
							</DropdownButton>
							<Col className="d-flex">
								<p className="mx-auto">
									{selectedDateFormatted}
								</p>
							</Col>
						</Row>
						{backToBackRender}
						{/* <Row>
						<Col className="d-flex flex-column">
							<p>11:20 AM</p>
							<ul className="ms-3em">
								<li>Hillel Gym Basketball | JR Female VEN 3 <span style={{backgroundColor: '#39FF14'}}>(coming from Hillel Gym Basketball)</span>
								</li>
								<li>Hillel Gym Basketball | JR Female VEN 3 <span style={{backgroundColor: '#FFFF00'}}>(coming from Hillel Gym Basketball)</span>
								</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex flex-column">
							<p>11:20 AM</p>
							<ul className="ms-3em">
								<li>Hillel Gym Basketball | JR Female VEN 3 <span style={{backgroundColor: '#39FF14'}}>(coming from Hillel Gym Basketball)</span>
								</li>
								<li>Hillel Gym Basketball | JR Female VEN 3 <span style={{backgroundColor: '#FFFF00'}}>(coming from Hillel Gym Basketball)</span>
								</li>
							</ul>
						</Col>
					</Row> */}
					</Container>
				</Card.Body>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	let outObj = {};
	if (Object.keys(state.backtoback).length > 1) {
		outObj.backtoback = state.backtoback;
	} else outObj.backtoback = {};

	if (state.backtoback.dates) {
		outObj.dates = state.backtoback.dates;
	} else outObj.dates = [];

	return outObj;
};

const mapDispatchToProps = dispatch => {
	return {
		getDates: () => dispatch(backtobackdates()),
		getB2B: date => dispatch(backtobackgames(date))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BackToBackView);
